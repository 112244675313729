import React from "react"
import HomepageHeader from "./HomepageHeader"
import { graphql } from "gatsby"

const HomepageHeaderDrupal = ({ node }) => {
  const {
    // deskAlt = {},
    sectionHeading,
    sectionBody,
    dropdown,
    dropdownLabel,
  } = node || {}

  return (
    <HomepageHeader
      sectionHeading={sectionHeading}
      sectionBody={sectionBody}
      dropdown={dropdown}
      dropdownLabel={dropdownLabel}
    />
  )
}

export default HomepageHeaderDrupal

export const fragment = graphql`
  fragment HomepageHeader on paragraph__homepage_header {
    id
    sectionHeading: field_section_heading
    sectionBody: field_section_body
    dropdownLabel: field_dropdown_label
    mobAlt: field_image_mobile {
      alt
    }
    deskAlt: field_image_desktop {
      alt
    }
    dropdown: field_dropdown_links {
      title
      uri
    }
    relationships {
      dImg: field_image_desktop {
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1920, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      mImg: field_image_mobile {
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 767, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
