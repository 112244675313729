import React, { useState } from "react"
import styled from "styled-components"
import color from "../../style/color"
import StandardSection from "../../helper/StandardSection"
import arrowGrey from "../../white-co-images/largeArrowGrey.svg"
import arrowGreen from "../../white-co-images/largeArrowGreen.svg"
import Img from "gatsby-image"

interface Slide {
  type: string
  heading: string
  body: string
  author: string
  imageDesktop: string
  imageMobile: string
  altDesktop: string
  altMobile: string
  ctaText: string
  ctaUrl: string
  slideDirection: number
}
interface CarouselProps {
  slides: Array<Slide>
  id: string
  heading: string
}
const Carousel = ({ slides, heading, id }: CarouselProps) => {
  const [clientScroll, setClientScroll] = useState(0)

  const [activeSlide, changeSlide] = useState(0)

  const nextSlide = () => {
    if (activeSlide < slides.length - 1) {
      changeSlide(activeSlide + 1)
    }
  }

  const prevSlide = () => {
    if (activeSlide > 0) {
      changeSlide(activeSlide - 1)
    }
  }

  const setSlide = i => {
    changeSlide(i)
  }

  const setClientX = e => {
    const clientX = e.touches[0].clientX
    setClientScroll(clientX)
  }

  const handleTouch = e => {
    if (e) {
      const touchStart = e.changedTouches[0].clientX
      const touchEnd = clientScroll

      //get end touch coordinates
      if (touchStart > touchEnd) {
        prevSlide(e)
      } else {
        nextSlide(e)
      }
    }
  }
  return (
    <Outer>
      <CarouselContainer data-testid="carousel-hp">
        <SlideContainer
          id={id}
          onTouchStart={setClientX}
          onTouchEnd={handleTouch}
        >
          <SlideAnimator
            activeSlide={activeSlide}
            data-testid="carousel-hp-slide"
          >
            {slides.map((slide, i) => {
              return (
                <SlideMask key={i}>
                  <Slide
                    activeSlide={activeSlide === i}
                    data-testid="hp-slides"
                    slideDirection={i > activeSlide ? -50 : -50}
                  >
                    <Mask>
                      {slide.sources && (
                        <Image
                          fluid={slide.sources}
                          alt={slide.altDesktop}
                          data-testid="carousel-hp-image"
                          activeSlide={i === activeSlide}
                          placeholderStyle={{ opacity: 0 }}
                        />
                      )}
                    </Mask>
                    <Text>
                      {heading && (
                        <HeadingContainer data-testid="carousel-hp-heading">
                          {/* <Heading>{heading}</Heading> */}
                          <Heading>
                            The <span>Wytes</span> experience
                          </Heading>
                        </HeadingContainer>
                      )}
                      <SlideTitle data-testid="carousel-hp-subheading">
                        {slide.heading}
                      </SlideTitle>
                      <BodyText>{slide.body}</BodyText>
                      {slide.author && <Author>{slide.author}</Author>}
                      {slide.ctaText && (
                        <LinkWrapper>
                          <a href={slide.ctaUrl} data-testid="carousel-hp-link">
                            {slide.ctaText}
                          </a>
                        </LinkWrapper>
                      )}
                    </Text>
                  </Slide>
                </SlideMask>
              )
            })}
          </SlideAnimator>
        </SlideContainer>

        <ProgressContainer>
          <DotContainer>
            {slides.map((dot, i) => {
              return (
                <Dot
                  key={i}
                  isActive={i === activeSlide}
                  onClick={() => setSlide(i)}
                />
              )
            })}
          </DotContainer>

          <ArrowsContainer>
            <ChevronLeft
              src={activeSlide !== 0 ? arrowGreen : arrowGrey}
              first={activeSlide === 0}
              onClick={prevSlide}
              alt="chevron"
            />
            <ChevronRight
              src={activeSlide !== slides.length - 1 ? arrowGreen : arrowGrey}
              last={activeSlide === slides.length - 1}
              onClick={nextSlide}
              alt="chevron"
            />
          </ArrowsContainer>
        </ProgressContainer>
      </CarouselContainer>
    </Outer>
  )
}

interface ISlideAnimator {
  carouselWidth: number
  activeSlide: number
  tabletCarouselWidth: number
}

const Outer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`

const SlideAnimator = styled.div<ISlideAnimator>`
  display: flex;
  transition: all 0.5s ease;
  width: 100%;

  backface-visibility: hidden;
  transform: translateX(-${props => 100 * props.activeSlide}%)
    matrix(1, 0, 0, 1, 0, 0);
  transition: transform 2.3s cubic-bezier(0.25, 1, 0.5, 1);
  @media (max-width: 767px) {
    transition: all 1.5s cubic-bezier(0.25, 1, 0.5, 1);
  }
`
interface ISlideContainer {
  activeSlide: number
}
const SlideContainer = styled.div<ISlideContainer>`
  display: flex;
  width: 90%;
  overflow: hidden;
  position: relative;
  backface-visibility: hidden;
  scroll-snap-type: x mandatory;
  transform-style: preserve-3d;
  perspective: 1000px;
  will-change: transform;
  margin-left: auto;

  @media (max-width: 1199px) {
    overflow-x: none;
    width: 100%;
  }
`
interface SlideProps {
  activeSlide?: boolean
  slideDirection: number
}
const Slide = styled.div<SlideProps>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
  transform: perspective(1000px);
  background: white;
  margin-left: auto;
  position: relative;
  perspective: 1000px;
  backface-visibility: hidden;
  will-change: transform;
  transition: transform 2.5s cubic-bezier(0.25, 1, 0.5, 1) 0.5s;
  ${props =>
    props.activeSlide
      ? "transform: matrix(1, 0, 0, 1, 0, 0);"
      : "transform: matrix(1.1, 0, 0, 1.1, 0, 0)"};
  z-index: ${props => (props.activeSlide ? "2" : "1")};
  @media (max-width: 1199px) {
    ${props =>
      props.activeSlide
        ? "transform: matrix(1, 0, 0, 1, 0, 0);"
        : "transform: matrix(1.05, 0, 0, 1.05, 0, 0)"};
  }
  @media (max-width: 1023px) {
    flex-direction: column;
    justify-content: flex-end;
    transition: transform 2.5s cubic-bezier(0.25, 1, 0.5, 1) 0.5s;
    ${props =>
      props.activeSlide
        ? "transform: matrix(1, 0, 0, 1, 0, 0);"
        : "transform: matrix(1.02, 0, 0, 1.02, 0, 0)"};
  }
`
const SlideMask = styled.div`
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
  background: white;
  @media (max-width: 1199px) {
    width: 100%;
  }
`

const SlideTitle = styled.h4`
  margin: 6.7rem 0 1.8rem;

  @media (max-width: 1023px) {
    margin-bottom: 10px;
    margin-top: 30px;
  }
`

const BodyText = styled.p`
  color: ${color.brand.blue};
  display: block;
  width: 60%;
  @media (max-width: 1199px) {
    width: 80%;
  }
  @media (max-width: 1023px) {
    width: 100%;
    font-size: 20px;
    line-height: 30px;
  }
`
const Author = styled.span`
  display: inline;
  font-style: italic;
  margin-left: 5px;
  font-size: 1.6rem;
  line-height: 2.6rem;
  font-weight: 300;
  white-space: nowrap;
`
const CarouselContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-left: auto;
  position: relative;
  z-index: 5;
  display: block;
  margin-top: 4rem;
  @media (max-width: 1023px) {
    margin-top: 0;
  }
`
const Text = styled.div`
  width: 44%;
  padding: 16.7rem 6rem 20rem;
  position: relative;
  backface-visibility: hidden;

  & a {
    display: block;
    margin: 1.7rem 0 3.3rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 50px 25px 38px;
    flex: 1;
  }
`
const HeadingContainer = styled.div`
  width: 85%;
  @media (max-width: 1199px) {
    width: 80%;
  }
  @media (max-width: 1023px) {
    width: 100%;
  }
`

const Heading = styled.h2`
  & span {
    font-weight: 400;
  }
`
const Mask = styled.div`
  overflow: hidden;
  width: 72.5rem;
  width: 56%;
  perspective: 1000px;
  @media (max-width: 1023px) {
    width: 100%;
  }
`

const Image = styled(Img)<SlideProps>`
  width: 100%;
  max-height: 700px;
  object-fit: cover;
  height: 76rem;
  margin: auto 0;
  display: block;
  transform-origin: top center;
  will-change: transform;
  @media (max-width: 767px) {
    max-height: 300px;
    min-height: 300px;
  }
`

const ProgressContainer = styled.div`
  width: 36rem;
  position: absolute;
  bottom: 15rem;
  left: calc(56% + 6rem);
  left: 65%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1199px) {
    left: 61%;
    bottom: 11rem;
  }
  @media (max-width: 1023px) {
    position: initial;
    width: 100%;
    padding: 0 25px 25px;
  }
`
const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  @media (max-width: 767px) {
    justify-content: center;
  }
`

const DotContainer = styled.div`
  display: flex;
`
interface IDot {
  isActive?: boolean
}
const Dot = styled.div<IDot>`
  height: 11px;
  width: 11px;
  background-color: ${props =>
    props.isActive ? color.brand.blue : "transparent"};
  border: 1px solid
    ${props => (props.isActive ? color.brand.blue : color.brand.blue)};
  border-radius: 50%;
  margin-right: 4.34px;
  transition: all 0.5s ease;
  cursor: pointer;
  &:last-of-type {
    margin-right: 0;
  }
  @media (max-width: 1023px) {
    height: 7px;
    width: 7px;
    margin-right: 2px;
  }
`
const ArrowsContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-end;
`

interface IChevron {
  first?: boolean
  last?: boolean
}
const ChevronRight = styled.img<IChevron>`
  height: 40px;
  width: 40px;
  top: 46%;
  right: 123px;
  cursor: pointer;
  z-index: 10;
  @media (max-width: 1199px) {
    right: 230px;
    top: 47%;
  }
  @media (max-width: 1023px) {
    height: 20px;
    top: 30px;
    right: 40px;
  }
`

const ChevronLeft = styled(ChevronRight)`
  right: auto;
  left: 55px;
  transform: rotate(180deg);
  margin-right: 25px;
  @media (max-width: 1199px) {
    left: 65px;
    top: 47%;
  }
  @media (max-width: 1023px) {
    left: auto;
    right: 72px;
    top: 30px;
  }
`

export default Carousel
