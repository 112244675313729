import React from "react"
import styled from "styled-components"
import color from "../../style/color"
import pic from "../../white-co-images/smileAssessment_D.jpg"
import picMob from "../../white-co-images/smileAssesment_M.jpg"

interface FormHeadingGreyProps {
  heading?: string
  text?: string
}

interface Step {
  heading?: string
  text?: string
}

const steps: Array<Step> = [
  {
    heading: "01. Put your teeth together and smile",
    text: "Now take your first photo.",
  },
  {
    heading: "02. Raise your head",
    text: "Now photograph your top teeth.",
  },
  {
    heading: "03. Put your head down",
    text: "Take a photo of your bottom teeth.",
  },
  {
    heading: "04. Send us your photos",
    text:
      "Fill out the form below to send us your photos. Our dentists will email you back with their written consultation. We’ll advise which treatments can help you to enjoy a more attractive, confidence-boosting smile.",
  },
]

const SmileSteps = () => {
  return (
    <ImageAndText>
      <Image src={pic} alt="Smile form. How it works" />
      <ImageMob src={picMob} alt="Smile form. How it works" />
      <TextContainer>
        <h2>What to do</h2>
        <Light>
          Quickly work your way through the following four steps. Our dentists
          will use their expertise to let you know your options for a beautiful
          new smile.
        </Light>
        {steps.map((step, key) => {
          return (
            <Step key={key}>
              <StepHeading>{step.heading}</StepHeading>
              <StepText>{step.text}</StepText>
            </Step>
          )
        })}
      </TextContainer>
    </ImageAndText>
  )
}

export default SmileSteps

const ImageAndText = styled.div`
  display: flex;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  background: ${color.background.lemon};
  & h2 {
    margin-bottom: 2rem;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const Light = styled.p`
  font-weight: 300;
  margin-bottom: 1.5rem;
`

const Image = styled.img`
  width: 50%;
  object-fit: cover;
  @media (max-width: 767px) {
    display: none;
  }
`
const ImageMob = styled.img`
  display: none;
  @media (max-width: 767px) {
    display: block;
    object-fit: cover;
    width: 100%;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7.3rem 4.4rem 9rem 6rem;
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
    padding: 50px 25px;
  }

  & h2 {
  }
`
const Step = styled.div`
  padding-top: 2rem;
`

const StepHeading = styled.p`
  font-size: 2rem;
  line-height: 3rem;
  margin-bottom: 4px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 26px;
  }
`
const StepText = styled.p`
  padding-left: 30px;
  font-weight: 300;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 24px;
  }
`
