import React, { useEffect, useState } from "react"
import styled from "styled-components"
import color from "../../style/color"
import { formatLink, scrollTo, setItem } from "../../util/functions"
import RegularSection from "../../helper/RegularSection"
import VerticalPadding from "../../helper/VerticalPadding"

interface Link {
  title: string
  uri: string
}
interface PriceListLinksProps {
  array: Array<Link>
  heading: string
  smallText?: string
  href?: string
  treatment?: string
  treatmentId: string
}

const PriceListLinks = ({
  heading,
  treatment,
  smallText,
  href,
  array,
  treatmentId,
}: PriceListLinksProps) => {
  const [showAllPractices, togglePractices] = useState(false)
  const practicesLimit = 4

  const id: string = "id" + Math.random()
  let formatTreatment = treatmentId?.replace(/[^A-Z0-9]+/gi, "_")

  const treatId: string = formatTreatment?.toLowerCase()

  return (
    <Container data-testid="price-component-container">
      <RegularSection>
        {array && (
          <VerticalPadding>
            <Text>
              {heading && <h4>{heading}</h4>}

              <PracticesGrid>
                {array.map((link, key) => {
                  return (
                    <PracticePriceLink
                      data-testid="practice-link"
                      onClick={() => setItem("treatId", treatId)}
                      href={formatLink(link.uri)}
                      key={key}
                    >
                      {link.title}
                    </PracticePriceLink>
                  )
                })}
              </PracticesGrid>
              <Scroll id={id} />
              <PracticesGridMobile>
                {array.map((link, key) => {
                  if (key < practicesLimit || showAllPractices) {
                    return (
                      <PracticePriceLink
                        onClick={() => setItem("treatId", treatId)}
                        href={formatLink(link.uri)}
                        key={key}
                      >
                        {link.title}
                      </PracticePriceLink>
                    )
                  }
                })}
              </PracticesGridMobile>
              {array.length > 4 && (
                <ViewAllContainer>
                  <p
                    onClick={() => {
                      togglePractices(!showAllPractices)
                      if (showAllPractices) {
                        scrollTo(id)
                      }
                    }}
                  >
                    {!showAllPractices ? "View all practices" : "Show less"}
                  </p>
                </ViewAllContainer>
              )}
              {smallText && (
                <SmallText
                  data-testid="small-text-price"
                  href={formatLink(href)}
                >
                  {smallText}
                </SmallText>
              )}
            </Text>
          </VerticalPadding>
        )}
      </RegularSection>
    </Container>
  )
}

export default PriceListLinks

interface IContainer {
  background?: string
}

const Container = styled.div<IContainer>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: ${color.background.lightGrey};
  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
`
const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
  & h4 {
    width: 100%;
    max-width: 40%;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`

const PracticesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10rem;
  grid-row-gap: 22px;
  justify-items: start;
  width: 100%;
  max-width: 90%;
  margin-top: 45px;
  flex-wrap: wrap;
  @media (max-width: 1023px) {
    max-width: 100%;
  }
  @media (max-width: 767px) {
    display: none;
  }
`
const PracticesGridMobile = styled(PracticesGrid)`
  display: none;
  @media (max-width: 767px) {
    max-width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
  }
`

const PracticePriceLink = styled.a`
  &:link,
  &:visited {
    display: inline-block;
    font-size: 2.2rem !important;
    line-height: 2.8rem !important;
    @media (max-width: 767px) {
      font-size: 16px !important;
      line-height: 26px !important;

      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }
  }
  &:hover,
  &:focus {
    text-decoration: none;
  }
`

const SmallText = styled.a`
  margin-top: 7rem;

  @media (max-width: 767px) {
    margin-top: 40px;
  }
  &,
  &:link,
  &:visited {
    &:hover {
      text-decoration: none;
    }
  }
`
const Scroll = styled.div`
  margin-top: -20px;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -70px;
`
const ViewAllContainer = styled.div`
  margin-top: 26px;
  display: none;

  & p {
    text-decoration: underline;
    @media (max-width: 767px) {
      font-size: 16px !important;
      line-height: 26px !important;
    }
  }
  @media (max-width: 767px) {
    display: block;
  }
`
