import React from "react"
import styled from "styled-components"
import color from "../../style/color"

const Pill = styled.div`
  background-color: ${color.brand.blue};
  font-size: 12px;
  padding: 1px 15px;
  height: 25px;
  font-weight: 300;
  color: white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Pill
