import React, { useContext } from "react"
import styled from "styled-components"
import color from "../../style/color"
import ColourScheme from "../../helper/UserContext"
import {
  yellowBackground,
  lilacBackground,
  blueBackground,
  pinkBackground,
} from "../BackgroundGradients/BackgroundGradient"
import { Breadcrumb, Link, Icon } from "../../shared/Breadcrumb/Breadcrumb"
import { get, formatLink } from "../../util/functions"

interface Link {
  uri: string
  title: string
}
interface FormHeadingGreyProps {
  heading?: string
  text?: string
  breadcrumb?: Array<Link>
  greyBackgroundBreadcrumb?: boolean
}

const WHeader = ({
  heading,
  text,
  breadcrumb,
  greyBackgroundBreadcrumb,
}: FormHeadingGreyProps) => {
  const colourScheme = useContext(ColourScheme)

  let background = ""
  const getBackgroundColour = () => {
    if (colourScheme) {
      switch (colourScheme.toLowerCase()) {
        case "yellow":
          background = yellowBackground
          break
        case "blue":
          background = blueBackground
          break
        case "lilac":
          background = lilacBackground
          break
        case "pink":
          background = pinkBackground
          break
        default:
          background = pinkBackground
      }
      return background
    } else {
      background = color.background.lightGrey
      return background
    }
  }
  getBackgroundColour()

  return (
    <>
      <Text background={background}>
        <TextInner>
          <h1>{heading}</h1>
          <Text dangerouslySetInnerHTML={{ __html: text }} />
        </TextInner>
      </Text>
      <Background greyBackgroundBreadcrumb={greyBackgroundBreadcrumb}>
        <BreadcrumbContainer>
          <Breadcrumb noPadding>
            {breadcrumb &&
              breadcrumb.map((item, key) => {
                return (
                  <React.Fragment key={key}>
                    <Link
                      href={
                        key !== breadcrumb.length - 1
                          ? formatLink(item.uri)
                          : null
                      }
                    >
                      {item.title}
                    </Link>
                    <span>|</span>
                  </React.Fragment>
                )
              })}
          </Breadcrumb>
        </BreadcrumbContainer>
      </Background>
    </>
  )
}

export default WHeader

interface IText {
  background?: string
}
const Text = styled.div<IText>`
  padding: 0 0 4.5rem;
  width: 100%;
  background: ${props => props.background};
  background-size: 200%;
  animation: animateGradient 10s infinite alternate-reverse forwards;
  & h1 {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  & p {
    margin-top: 2rem;
    font-weight: 300;
    font-size: 2rem;
    line-height: 3rem;
  }

  & a {
    font-size: 2rem;
    line-height: 3rem;
  }
`
const TextInner = styled.div`
  width: 92%;
  max-width: 995px;
  margin: 0 auto;
  padding-top: 4.2rem;
  @media (max-width: 767px) {
    padding: 4.2rem 25px 0;
    width: 100%;
  }
`
const BreadcrumbContainer = styled.div`
  width: 92%;
  max-width: 995px;
  margin: 0 auto;
  @media (max-width: 767px) {
    display: none;
  }
`
const Background = styled.div`
  background: ${props =>
    props.greyBackgroundBreadcrumb && color.background.lightGrey};
`
