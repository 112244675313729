import React from "react"
import HomepageMeetTheTeam from "./HomepageMeetTheTeam"
import { graphql } from "gatsby"
import { formatLink } from "../../util/functions"

const HomepageMeetTheTeamDrupal = ({ node }) => {
  const { heading, relationships, field_cta } = node || {}
  const { field_team_card } = relationships || {}
  const cards = field_team_card.map(card => {
    return {
      body: card.field_section_body,
      ctaText: card.field_cta.title,
      ctaUrl: formatLink(card.field_cta.uri),
      practiceName: card.field_practice_name,
      role: card.field_role,
      name: card.field_name,
      image: card.relationships.field_image_desktop.localFile.publicURL,
      imageAlt: card.field_image_desktop.alt,
    }
  })

  return <HomepageMeetTheTeam heading={heading} cards={cards} cta={field_cta} />
}

export const fragment = graphql`
  fragment HomepageMeetTheTeamDrupal on paragraph__homepage_meet_the_team {
    id
    heading: field_section_heading
    relationships {
      field_team_card {
        field_practice_name
        field_section_body
        field_name
        field_role
        field_image_desktop {
          alt
        }
        field_cta {
          title
          uri
        }
        relationships {
          field_image_desktop {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    field_cta {
      title
      uri
    }
  }
`

export default HomepageMeetTheTeamDrupal
