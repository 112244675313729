import React, { useState, useEffect } from "react"
import Input from "./Input"
import TextArea from "./TextArea"
import Button from "../../shared/Button/Button"
import styled from "styled-components"
import color from "../../style/color"
import {
  validateOnBlur,
  validatePhoneNumber,
  validateEmail,
  scrollPage,
} from "../../util/functions"
import { setItem, getItem } from "../../util/functions"
import { updateGoogleSheet, getAuthToken } from "../../googleapis/api-hooks"
import Dropdown from "./CustomDropdown"
import emailjs from "emailjs-com"
import Loading from "./Loading"

interface Option {
  label: string
  value: string
}

const PracticesTreatmentForm = ({ preferedClinics }) => {
  const [firstName, setFirstName] = useState(getItem("firstName"))
  const [lastName, setLastName] = useState(getItem("lastName"))
  const [firstNameError, togglefirstNameError] = useState(false)
  const [lastNameError, togglelastNameError] = useState(false)
  const [phone, setPhone] = useState(getItem("phone"))
  const [phoneError, togglePhoneError] = useState(false)
  const [email, setEmail] = useState(getItem("email"))
  const [emailError, toggleEmailError] = useState(false)
  const [anythingElse, setAnythingElse] = useState("")
  const [treatmentError, toggleTreatmentError] = useState(false)
  const [practiceError, togglePracticeError] = useState(false)

  const [isLoading, toggleLoading] = useState(false)

  const likeToOptions: Option[] = [
    { label: "Have a general check up", value: "checkup" },
    { label: "Straighten my teeth", value: "Straighten teeth" },
    { label: "Replace missing teeth", value: "replace" },
    { label: "Fix my worn teeth", value: "wornteeth" },
    { label: "Fix gaps between my teeth", value: "gapteeth" },
    { label: "Fix chipped teeth", value: "chippedteeth" },
    { label: "Whiten my teeth", value: "whiten" },
    { label: "Have a perfect smile", value: "smile" },
    { label: "Replace my veneers", value: "veneers" },
    { label: "Visit a hygienist", value: "hygienist" },
    { label: "Rejuvenate my skin", value: "skin" },
    { label: "Other", value: "other" },
  ]

  const confirmationText: any = {
    ["Have a general check up"]: "Having a general check up",
    ["Straighten my teeth"]: "Straightening teeth",
    ["Replace missing teeth"]: "Replacing missing teeth",
    ["Fix my worn teeth"]: "Fixing worth teeth",
    ["Fix gaps between my teeth"]: "Fixing gaps between teeth",
    ["Fix chipped teeth"]: "Fixing chipped teeth",
    ["Whiten my teeth"]: "Whitening teeth",
    ["Have a perfect smile"]: "Having a perfect smile",
    ["Replace my veneers"]: "Replacing veneers",
    ["Visit a hygienist"]: "Visiting a hygienist",
    ["Rejuvenate my skin"]: "Rejuvenating skin",
    Other: "Other",
  }

  const [token, setToken] = useState("")

  useEffect(() => {
    if (token) {
      toggleLoading(true)
      document.querySelector("body").style.overflow = "hidden"

      if (typeof window !== "undefined" && typeof document !== "undefined") {
        let treatment = document.querySelector("#general-form-dropdown1")
          .innerText
        let practice = document.querySelector("#general-form-dropdown2")
          .innerText

        setItem("firstName", firstName)
        setItem("lastName", lastName)
        setItem("practice", practice)
        setItem("phone", phone)
        setItem("treatment", confirmationText[treatment])
        setItem("email", email)

        const params = {
          message: `
          <p>Form: General Enquiry</p>
          <p>Name: ${firstName} ${lastName}</p>
          <p>Preferred practice: ${practice}</p>
          <p>Phone: ${phone}</p>
          <p>Treatment: ${confirmationText[treatment]}</p>
          <p>Email: ${email}</p>
          <p>Message: ${anythingElse}</p>
          `,
        }

        emailjs
          .send(
            "gmail",
            process.env.EMAILJS_TEMPLATE,
            params,
            process.env.EMAILJS_ID
          )
          .then(
            result => {
              console.log(result.text)
            },
            error => {
              console.log(error.text)
            }
          )
          .then(() => {
            updateGoogleSheet(
              token,
              "GeneralEnquiry",
              [
                firstName + " " + lastName,
                phone,
                email,
                practice,
                treatment,
                anythingElse,
                window.location.href,
                new Date(),
              ],
              "general-form-confirmation"
            )
          })
      }
    }
  }, [token])

  useEffect(() => {
    const practice = getItem("practice")
    if (practice) {
      const dropdown = document.querySelector("#general-form-dropdown2")
      if (dropdown) {
        dropdown.innerText = practice
      }
    }
  }, [])

  const submitForm = () => {
    const goToNextScreen = () => {
      getAuthToken(setToken)
    }
    let treatment = document.querySelector("#general-form-dropdown1").innerText
    let practice = document.querySelector("#general-form-dropdown2").innerText
    let idToScroll: string = ""

    let firstNameError = false
    let lastNameError = false
    let phoneError = false
    let practiceError = false
    let treatmentError = false
    let emailError: boolean = false
    if (firstName.length < 1) {
      firstNameError = true
      togglefirstNameError(true)
      if (!idToScroll) {
        idToScroll = "firstName"
      }
    }

    if (lastName.length < 1) {
      lastNameError = true
      togglelastNameError(true)
      if (!idToScroll) {
        idToScroll = "lastName"
      }
    }

    if (!validatePhoneNumber(phone)) {
      phoneError = true
      togglePhoneError(true)
      if (!idToScroll) {
        idToScroll = "phone"
      }
    }
    if (!validateEmail(email)) {
      emailError = true
      toggleEmailError(true)
      if (!idToScroll) {
        idToScroll = "email"
      }
    }
    if (treatment === "I would like to…") {
      treatmentError = true
      toggleTreatmentError(true)
      if (!idToScroll) {
        idToScroll = "general-form-dropdown1"
      }
    }
    if (practice === "Please select") {
      practiceError = true
      togglePracticeError(true)
      if (!idToScroll) {
        idToScroll = "general-form-dropdown2"
      }
    }

    if (
      !firstNameError &&
      !lastNameError &&
      !phoneError &&
      !emailError &&
      !practiceError &&
      !treatmentError
    ) {
      goToNextScreen()
    } else {
      if (idToScroll) {
        scrollPage(idToScroll)
      }
    }
  }

  return isLoading ? (
    <Loading />
  ) : (
    <Wrapper>
      <Container>
        <Text>
          <Heading>Find out more</Heading>
          <Subheading>
            Please fill in the form to book your appointment or to find out
            more. One of our helpful team members will get back to you as soon
            as possible.
          </Subheading>
        </Text>
        <Form>
          <Dropdown
            id="general-form-dropdown1"
            heading="Select a treatment*"
            placeholder="I would like to…"
            options={likeToOptions}
            hasError={treatmentError}
            errorTitle="Please select a treatment"
            toggleDropdownError={toggleTreatmentError}
          />
          <Flex>
            <Input
              value={firstName}
              onChange={setFirstName}
              label={"Your first name*"}
              isValidated={firstName.length > 0}
              hasError={firstNameError}
              errorTitle="Please enter first name"
              onBlur={() =>
                validateOnBlur(firstName.length > 0, togglefirstNameError)
              }
              id="firstName"
            />
            <Gap />
            <Input
              value={lastName}
              onChange={setLastName}
              label={"Your last name*"}
              isValidated={lastName.length > 0}
              hasError={lastNameError}
              errorTitle="Please enter your last name"
              onBlur={() =>
                validateOnBlur(lastName.length > 0, togglelastNameError)
              }
              id="lastName"
            />
          </Flex>
          <Flex>
            <Input
              value={phone}
              onChange={phone => phone.length < 20 && setPhone(phone)}
              label={"Contact number*"}
              isValidated={validatePhoneNumber(phone)}
              hasError={phoneError}
              errorTitle="Please enter a valid telephone number"
              onBlur={() =>
                validateOnBlur(validatePhoneNumber(phone), togglePhoneError)
              }
              id="phone"
            />
            <Gap />
            <Input
              value={email}
              onChange={setEmail}
              label={"Email address*"}
              isValidated={validateEmail(email)}
              hasError={emailError}
              errorTitle="Please enter a valid email address"
              onBlur={() => {
                validateOnBlur(validateEmail(email), toggleEmailError)
              }}
              id="email"
            />
          </Flex>
          <Dropdown
            heading="Preferred clinic*"
            placeholder="Please select"
            options={preferedClinics}
            id="general-form-dropdown2"
            hasError={practiceError}
            errorTitle="Please select preferred clinic"
            toggleDropdownError={togglePracticeError}
          />
          <TextArea
            heading={"Your message"}
            value={anythingElse}
            onChange={setAnythingElse}
          />
          <BtnContainer>
            <Button text="Submit" onClick={submitForm} tertiary />
          </BtnContainer>
        </Form>
      </Container>
    </Wrapper>
  )
}

export default PracticesTreatmentForm

const Wrapper = styled.div`
  padding: 12.5rem 0;
  background: ${color.background.blueLight};
  @media (max-width: 767px) {
    padding: 50px 0 77px;
  }
`
const Container = styled.div`
  display: flex;
  width: 92%;
  max-width: 120rem;
  margin: 0 auto;
  position: relative;
  @media (max-width: 1023px) {
    flex-direction: column;
    width: 100%;
    padding: 0 3%;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 25px;
  }
`
const Text = styled.div`
  width: 38rem;
  margin-right: 17rem;
  @media (max-width: 1199px) {
    margin-right: 10rem;
  }
  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 10rem;
    margin-right: 0;
  }
  @media (max-width: 767px) {
    margin-bottom: 38px;
  }
`

const Heading = styled.h2``
const Subheading = styled.p`
  margin-top: 28px;
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 300;
  @media (max-width: 767px) {
    margin-top: 0;
  }
`

const Form = styled.div`
  width: 66rem;
  @media (max-width: 1023px) {
    width: 100%;
  }
`

const Flex = styled.div`
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
const Gap = styled.div`
  width: 2rem;
  @media (max-width: 767px) {
    display: none;
  }
`
const BtnContainer = styled.div`
  width: 50%;
  margin-top: 4rem;
  display: flex;
  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
  }
`
