import React from "react"
import TeamSection from "./TeamSection"
import { graphql } from "gatsby"
import { formatLink } from "../../util/functions"

const TeamSectionDrupal = ({ node }) => {
  const { sectionHeading, sixRows, relationships } = node || {}
  const { field_team_member } = relationships || {}

  if (!field_team_member) {
    return <></>
  }

  const cards = field_team_member.map(card => {
    return {
      heading: card.field_name,
      body: card.field_section_body,
      ctaText: card.field_cta.title,
      ctaUrl: formatLink(card.field_cta.uri),
      category: card.field_role,
      image: card.relationships.field_image_desktop.localFile.publicURL,
      imageAlt: card.field_image_desktop.alt,
      practice: card.field_practice_name,
    }
  })

  return (
    <TeamSection heading={sectionHeading} sixRows={sixRows} cards={cards} />
  )
}

export default TeamSectionDrupal

export const fragment = graphql`
  fragment TeamSection on paragraph__team_section {
    id
    sectionHeading: field_section_heading
    sixRows: field_show_6_rows
    relationships {
      field_team_member {
        field_role
        field_name
        field_section_body
        field_practice_name
        field_cta {
          title
          uri
        }
        field_image_desktop {
          alt
        }
        relationships {
          field_image_desktop {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
