import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { get } from "../../util/functions"
import VideoConsultationNew from "./VideoConsultationNew"

const VideoConsultationDrupal = ({ node }: any) => {
  const { links } = node || {}

  return <VideoConsultationNew data={links} />
}

export default VideoConsultationDrupal

export const fragment = graphql`
  fragment VideoConsultationDrupal on paragraph__video_consultation_container {
    id
    links: field_iframe_link {
      title
      uri
    }
  }
`
