import React from "react"
import styled from "styled-components"
import color from "../../../style/color"
import SmallSection from "../../../helper/SmallSection"

interface Price {
  title: string
  value: string
}
interface PriceRowProps {
  array: Array<Price>
  heading: string
  noMarginBottom?: boolean
  priceComponent?: boolean
  treatment?: string
}

const PriceRow = ({
  heading,
  array,
  noMarginBottom,
  priceComponent,
  treatment,
}: PriceRowProps) => {
  const id: string = heading?.toLowerCase()
  let formatId = id?.replace(/[^A-Z0-9]+/gi, "_")
  return (
    <>
      {priceComponent ? (
        <SmallSection>
          <Container noMarginBottom={noMarginBottom}>
            {heading && (
              <Heading data-testid="price-heading">{heading}</Heading>
            )}
            {array.map((row, key) => {
              return (
                <Row key={key}>
                  <Title data-testid="price-title">{row.title}</Title>
                  <Value data-testid="price-value">{row.value}</Value>
                </Row>
              )
            })}
          </Container>
        </SmallSection>
      ) : (
        <PricePage subcategory={!heading}>
          <AnchorId id={formatId} />
          <Container noMarginBottom={noMarginBottom} id={formatId}>
            {heading && (
              <Heading data-testid="price-heading">{heading}</Heading>
            )}
            {array.map((row, key) => {
              return (
                <Row key={key}>
                  <Title data-testid="price-title">{row.title}</Title>
                  <Value data-testid="price-value">{row.value}</Value>
                </Row>
              )
            })}
          </Container>
        </PricePage>
      )}
    </>
  )
}

export default PriceRow

const AnchorId = styled.div`
  position: absolute;
  top: -20px;
  left: 0;
`

interface IPricePage {
  subcategory?: boolean
}
const PricePage = styled.div<IPricePage>`
  width: 92%;
  max-width: 995px;
  margin: 75px auto 0;
  position: relative;
  ${props => props.subcategory && `margin-top: -40px;`}

  @media (max-width: 767px) {
    padding: 0 25px;
    width: 100%;
    margin-top: 50px;
  }
`

interface IContainer {
  noMarginBottom?: boolean
}
const Container = styled.div<IContainer>`
  margin-bottom: ${props => (props.noMarginBottom ? "0" : "10rem")};
  width: 100%;
  @media (max-width: 767px) {
    margin-bottom: ${props => (props.noMarginBottom ? "0" : "50px")};
  }
`

const Heading = styled.h3`
  padding-bottom: 2rem;
  // font-size: 3.8rem;
  @media (max-width: 767px) {
    text-align: center;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 18px;
  margin-top: 2rem;
  border-bottom: 1px solid ${color.keyline.grey};
`
const Title = styled.p`
  font-weight: 300;
  font-size: 2rem;
  @media (max-width: 767px) {
    width: 67%;
  }
`

const Value = styled.p`
  margin-left: 5rem;
  font-size: 16px;
  line-height: 26px;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    margin-left: 0px;
    width: 33%;
  }
`
