import React from "react"
import Carousel from "./CarouselHomepage"
import { graphql } from "gatsby"
import { get, formatLink } from "../../util/functions"

const CarouselHomepage = ({ node }) => {
  const { id, heading, relationships, field_cta } = node || {}
  const { field_patient_story } = relationships || {}

  const slides = field_patient_story.map(story => {
    return {
      type: story.field_category,
      subTitle: story.field_section_heading,
      body: story.field_section_body,
      imageDesktop: get(
        ["relationships", "field_image_desktop", "localFile", "publicURL"],
        story,
        ""
      ),
      imageMobile: get(
        ["relationships", "field_image_mobile", "localFile", "publicURL"],
        story,
        ""
      ),
      altDesktop: story.field_image_desktop.alt,
      altMobile: story.field_image_mobile.alt,
      ctaText: field_cta.title,
      ctaUrl: formatLink(field_cta.uri),
      sources: [
        {
          ...story.relationships.field_image_desktop.localFile.childImageSharp
            .fluid,
          media: `(min-width: 767px)`,
        },
        {
          ...story.relationships.field_image_mobile.localFile.childImageSharp
            .fluid,
          media: `(max-width: 767px)`,
        },
      ],
    }
  })
  return <Carousel id={id} slides={slides} heading={heading} />
}

export const fragment = graphql`
  fragment PatientStories on paragraph__patient_stories {
    id
    heading: field_section_heading
    field_cta {
      title
      uri
    }
    relationships {
      field_patient_story {
        field_category
        field_section_heading
        field_section_body
        field_image_desktop {
          alt
        }
        field_image_mobile {
          alt
        }
        relationships {
          field_image_desktop {
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 717, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          field_image_mobile {
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 767, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default CarouselHomepage
