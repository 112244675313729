import React from "react"
import TreatmentHeader from "../components/Header/TreatmentHeaderDrupal"
import EmergencyHeader from "../components/Header/EmergencyHeaderDrupal"
import Benefits from "../components/Benefits/BenefitsDrupal"
import StepsToPerfectSmile from "../components/StepsToPerfectSmile/StepsToPerfectSmileDrupal"
import InvisalignAnimation from "../components/InvisalignAnimationNew/InvisalignDrupal"
import DentalImplantAnimation from "../components/DentalImplantAnimation/DentalImplantDrupal"
import PromotionBanner from "../components/PromotionBanner/PromotionBannerDrupal"
import FinanceBanner from "../components/FinanceBanner/FinanceBannerDrupal"
import BeforeAfterCarousel from "../components/CarouselBeforeAndAfter/CarouselDrupal"
import CategoryHeader from "../components/Header/CategoryHeaderDrupal"
import TreatmentExplanation from "../components/TreatmentExplanation/TreatmentExplanationDrupal"
import TreatmentPriceTableDrupal from "../components/Price/TreatmentPriceTableDrupal"
import PricePageTable from "../components/Price/PricePageTable/PricePageTableDrupal"
import GreyHeader from "../components/Form/GreyHeaderDrupal"
import HomepageHeader from "../components/Header/HomepageHeaderDrupal"
import TreatmentContainer from "../components/Treatment/TreatmentContainerDrupal"
import HalfAndHalf from "../components/HalfAndHalf/HalfAndHalfDrupal"
import CarouselDrupal from "../components/CarouselHomepage/CarouselHomepageDrupal"
import PracticeContainer from "../components/OurPractices/OurPracticesDrupal"
import ExperienceCarousel from "../components/ExperienceCarousel/ExperienceCarouselDrupal"
import TreatmentListDrupal from "../components/CategoryTreatmentList/TreatmentListDrupal"
import Faq from "../components/Faq/FaqDrupal"
import InvisalignTableDrupal from "../components/InvisalignTable/InvisalignTableDrupal"
import SmileHeader from "../components/Header/SmileHeaderDrupal"
import RangeOfTreatmentsDrupal from "../components/RangeOfTreatments/RangeOfTreatmentsDrupal"
import DentalImplantsTable from "../components/DentalImplantsTable/DentalImplantsDrupal"
import ToothSplitDrupal from "../components/ToothSplit/ToothSplitDrupal"
import BlogPosts from "../components/BlogPosts/BlogPosts"
import TeamSection from "../components/TeamSection/TeamSectionDrupal"
import AboutUsHeader from "../components/AboutUsHeader/AboutUsHeadeDrupal"
import AboutUsSection from "../components/AboutUsSection/AboutUsSectionDrupal"
import FinanceTable from "../components/FinanceTable/FinanceTableDrupal"
import PracticeCards from "../components/PracticesCards/PracticesCardsDrupal"
import PracticeDetails from "../components/PracticeDetails/PracticeDetailsDrupal"
import TextContent from "../components/TextContent/TextContentDrupal"
import Breadcrumb from "../shared/Breadcrumb/BreadcrumbDrupal"
import PatientReferrals from "../components/PatientReferrals/PatientReferralsDrupal"
import AllPatientStories from "../components/AllPatientStories/AllPatientStoriesDrupal"
import Sitemap from "../components/Sitemap/SitemapDrupal"
import PriceListLinks from "../components/PriceListLinks/PriceListLinksDrupal"
import ReferralSection from "../components/ReferralTabs/ReferralDrupal"
import Press from "../components/Press/Press"
import FinanceCalculator from "../components/FinanceCalculator/FinanceCalculatorDrupal"
import WHeader from "../components/Form/WHeaderDrupal"
import VideoConsultation from "../components/VideoConsultation/VideoConsultation"
import HomepageMeetTheTeam from "../components/HomepageMeetTheTeam/HomepageMeetTheTeamDrupal"
import MeetTheTeamFilter from "../components/MeetTheTeamFilter/MeetTheTeamFilterDrupal"
import GallerySection from "../components/GallerySection/GallerySectionDrupal"
import VideoConsultationDrupal from "../components/VideoConsultation/VideoConsultationDrupal"
import InvisalignVideoDrupal from "../components/InvisalignVideo/InvisalignVideoDrupal"

const components = {
  paragraph__sitemap: Sitemap,
  paragraph__all_patient_stories_: AllPatientStories,
  paragraph__patient_referral_section: PatientReferrals,
  paragraph__breadcrumb: Breadcrumb,
  paragraph__treatment_header: TreatmentHeader,
  paragraph__benefits: Benefits,
  paragraph__steps_to_perfect_smile: StepsToPerfectSmile,
  paragraph__invisalign_animation: InvisalignAnimation,
  paragraph__dental_implant_animation: DentalImplantAnimation,
  paragraph__promotion_banner: PromotionBanner,
  paragraph__before_after_carousel: BeforeAfterCarousel,
  paragraph__category_header: CategoryHeader,
  paragraph__treatment_explanation: TreatmentExplanation,
  paragraph__treatment_price_table: TreatmentPriceTableDrupal,
  paragraph__price_page_table: PricePageTable,
  paragraph__grey_header: GreyHeader,
  paragraph__homepage_header: HomepageHeader,
  paragraph__treatment_container: TreatmentContainer,
  paragraph__half_and_half_section: HalfAndHalf,
  paragraph__patient_stories: CarouselDrupal,
  paragraph__practices_container: PracticeContainer,
  paragraph__experience_carousel: ExperienceCarousel,
  paragraph__finance_banner: FinanceBanner,
  paragraph__category_treatment_list: TreatmentListDrupal,
  paragraph__faqs: Faq,
  paragraph__invisalign_table: InvisalignTableDrupal,
  paragraph__smile_header: SmileHeader,
  paragraph__range_of_treatments: RangeOfTreatmentsDrupal,
  paragraph__dental_implants_table: DentalImplantsTable,
  paragraph__dental_implant_tooth_diagram: ToothSplitDrupal,
  paragraph__display_blog_posts: BlogPosts,
  paragraph__team_section: TeamSection,
  paragraph__about_us_header: AboutUsHeader,
  paragraph__about_us_section: AboutUsSection,
  paragraph__finance_table: FinanceTable,
  paragraph__practices_cards_section: PracticeCards,
  paragraph__practice_details: PracticeDetails,
  paragraph__text_content: TextContent,
  paragraph__price_list_links: PriceListLinks,
  paragraph__referral_section: ReferralSection,
  paragraph__press_section: Press,
  paragraph__finance_calculator: FinanceCalculator,
  paragraph__emergency_header: EmergencyHeader,
  paragraph__wysiwyg_header: WHeader,
  paragraph__homepage_meet_the_team: HomepageMeetTheTeam,
  paragraph__gallery_section_container: GallerySection,
  paragraph__meet_the_team_filter: MeetTheTeamFilter,
  paragraph__video_consultation: VideoConsultation,
  paragraph__video_consultation_container: VideoConsultationDrupal,
  paragraph__invisalign_video: InvisalignVideoDrupal,
}

export const getParagraph = node => {
  if (components.hasOwnProperty(node.type)) {
    const ParagraphComponent = components[node.type]
    return <ParagraphComponent key={node.id + Math.random()} node={node} />
  }
  return <p key={node.id}>Unknown type {node.__typename}</p>
}
