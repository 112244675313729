import React from "react"
import styled from "styled-components"
import color from "../../style/color"
import VerticalPadding from "../../helper/VerticalPadding"
import StandardSection from "../../helper/StandardSection"
import Button from "../../shared/Button/Button"

import {
  yellowBackground,
  lilacBackground,
  blueBackground,
  pinkBackground,
} from "../BackgroundGradients/BackgroundGradient"

interface ICard {
  sectionHeading?: string
  body?: string
  marginRight?: boolean
  marginBottom?: boolean
  increasedWidth?: boolean
  image?: string
  imageMob?: string
  url: string
  link: string
  altDesktop?: string
  altMobile?: string
  background?: string
}
interface IGrid {
  centerColumn?: boolean
  marginBottom?: boolean
}
interface ITreatmentContainer {
  sectionHeading?: string
  sectionBody?: string
  data: Array<ICard>
}

const TreatmentContainer = ({ sectionHeading, data }: ITreatmentContainer) => {


  return (
    <MainContainer data-testid="treatmentContainerTest">
      <StandardSection>
        <VerticalPadding>
          <Inner>
            {sectionHeading && <SmallHeading>{sectionHeading}</SmallHeading>}

            <Grid
              centerColumn={data.length <= 3}
              marginBottom={data.length >= 4}
            >
              {data &&
                data.map((column, key) => {
                  const getBackground = column => {
                    let background = ""
                    switch (column) {
                      case "general dentistry":
                        background = blueBackground
                        break
                      case "cosmetic dentistry":
                        background = lilacBackground
                        break
                      case "teeth straightening":
                        background = yellowBackground
                        break
                      case "facial aesthetics":
                        background = pinkBackground
                        break
                      default:
                        background =  lilacBackground
                    }
                    return background
                  }
                  return (
                    <Card
                      key={key}
                      marginBottom={data.length >= 4}
                      marginRight={(key + 1) % 4 !== 0}
                      increasedWidth={data.length <= 3}
                      background={ getBackground(column.sectionHeading?.toLowerCase())}
                    >
                      <Heading>{column.sectionHeading}</Heading>
                      <Body>{column.body}</Body>
                      <ButtonContainer>
                        <Button
                          size="small"
                          href={column.url}
                          text={column.link}
                        />
                      </ButtonContainer>
                    </Card>
                  )
                })}
            </Grid>
          </Inner>
        </VerticalPadding>
      </StandardSection>
    </MainContainer>
  )
}

const SmallHeading = styled.h3`
  font-size: 6rem;
  line-height: 7rem;
  margin-left: 16vw;
  margin-left: 23%;
  @media (max-width: 1023px) {
    margin-left: 0;
    font-size: 38px;
    line-height: 48px;
  }
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 767px) {
    background: ${color.background.lightGrey};
  }
`
const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Grid = styled.div<IGrid>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: ${props => props.centerColumn && "center"};
  margin-top: 3.5rem;

  @media (max-width: 1023px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 27px;
  }
`
const Card = styled.div<ICard>`
  width: 100%;
  max-width: ${props => (props.increasedWidth ? "32.6%" : "24.5%")};
  margin-top: ${props => (props.marginBottom ? "100px" : 0)};
  position: relative;
  margin-right: ${props => (props.marginRight ? "0.6%" : 0)};
  background: white;
  transition: background 0.6s ease;
  padding: 6rem 4.3rem;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #f2f1f1;
  display: flex;
  flex-direction: column;
  &:hover {
    background: ${props => props.background};
    background-size: 200%;
    animation: animateGradient 10s infinite alternate-reverse forwards;
  }
  &:last-of-type {
    margin-right: 0;
  }
  &:nth-child(-n + 4) {
    margin-top: 0;
  }
  @media (max-width: 1280px) {
    padding: 5rem 3rem 6rem;
  }

  @media (max-width: 1023px) {
    max-width: 49%;
    margin-bottom: 0.6%;
  }
  @media (max-width: 767px) {
    margin-right: 0;
    max-width: 100%;
    margin-top: 0;
    padding: 36px 33px 38px;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
`

const Heading = styled.h3`
  color: ${color.text.primary};
  text-align: left;
  margin-bottom: 21px;
`

const Body = styled.p`
  margin-bottom: 21px;
  @media (max-width: 767px) {
    margin-bottom: 11px;
  }
`
const ButtonContainer = styled.div`
  width: 100%;
  max-width: 150px;
  margin-top: auto;
`
export default TreatmentContainer
