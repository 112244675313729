import React from "react"
import InvisalignVideo from "./InvisalignVideo"
import { graphql } from "gatsby"

const InvisalignVideoDrupal = ({ node }: any) => {
  return <InvisalignVideo link={node.field_youtube_link} />
}

export default InvisalignVideoDrupal

export const fragment = graphql`
  fragment InvisalignVideo on paragraph__invisalign_video {
    id
    field_youtube_link
  }
`
