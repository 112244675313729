import React from "react"
import styled from "styled-components"
import color from "../../../style/color"
import StepNumber from "./StepNumber"
import Pill from "../../../shared/Pill/Pill"

interface StepProp {
  heading?: string
  text?: string
  period?: string
  stepNumber?: number
  week?: string
  middleTime: string
}
const Step = ({
  heading,
  text,
  period,
  stepNumber,
  week,
  middleTime,
}: StepProp) => {
  return (
    <Container data-testid="step">
      <StepDetails>
        <PillContainer>
          <SlidePill>{period}</SlidePill>
        </PillContainer>
        {stepNumber && <TopDetails>{week && <Week>{week}</Week>}</TopDetails>}

        {heading && <StepHeading>{heading}</StepHeading>}
        {text && <Text>{text}</Text>}
      </StepDetails>

      {middleTime && (
        <MiddlePillContainer>
          <SlidePill>{middleTime}</SlidePill>
        </MiddlePillContainer>
      )}
    </Container>
  )
}

export default Step

const MiddlePillContainer = styled.div`
  display: flex;
  align-self: flex-start;
  position: absolute;
  left: 0;
  bottom: 0;

  @media (max-width: 1199px) {
    bottom: 11%;
  }
`

const DesktopImage = styled.div`
  position: absolute;
  left: 0;
  top: 32%;
  margin-left: -550px;

  & img {
    width: 440px;
  }
  @media (max-width: 1199px) {
    display: none;
  }
`

const MobImage = styled.img`
  display: none;
  @media (max-width: 1199px) {
    display: block;
    width: 100%;
  }
`

const SlidePill = styled(Pill)`
  padding: 0 20px;
  background: ${color.brand.green};
  color: ${color.brand.blue};
`

const PillContainer = styled.div`
  display: flex;
  align-self: flex-start;
  margin-bottom: 25px;
`

const Container = styled.div`
  height: 50vh;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0;
  z-index: 7;
  position: relative;
  @media (max-width: 1199px) {
    height: auto;
    padding: 0;
    min-height: 400px;
  }
`

const StepDetails = styled.div`
  padding: 20px 0;
  width: 100%;
  background: white;
`
const TopDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0 20px;
`

const Week = styled.div`
  background: rgba(8, 37, 72, 0.6);
  color: #fff;
  border-radius: 50px;
  padding: 0 10px;
  font-weight: 300;
  font-style: italic;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
`

export const StepHeading = styled.h5``

export const Text = styled.p`
  margin: 12px 0 20px;

  @media (max-width: 1199px) {
    margin-bottom: 0;
  }
`
