import React, { useState } from "react"
import styled from "styled-components"
import arrow from "../../white-co-images/arrow.svg"
import color from "../../style/color"
import { formatLink } from "../../util/functions"

interface IOption {
  title: string
  uri: string
}

interface IHeaderDropdown {
  dropdown: Array<IOption>
  id: string
  placeholder: string
  color?: string
}

const Dropdown = ({ dropdown, id, placeholder, color }: IHeaderDropdown) => {
  const [displayDropDown, toggleDisplayDropdown] = useState(false)
  const [label, setLabel] = useState(placeholder)

  const toggleDropdown = () => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const dropdown = document.getElementById(id)
      const headerArrow = document.getElementById(`${id}-arrow`)
      toggleDisplayDropdown(!displayDropDown)
      const element = document.getElementById(`${id}-links`)
      if (element && headerArrow) {
        if (dropdown) {
          if (!displayDropDown) {
            element.style.opacity = "1"
            element.style.visibility = "visible"

            headerArrow.style.transform = "rotate(180deg)"
          } else {
            element.style.opacity = "0"
            element.style.visibility = "hidden"

            headerArrow.style.transform = "rotate(0)"
          }
        }
      }
    }
  }

  const handleClickOutsideMobile = () => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const headerArrow = document.getElementById(`${id}-arrow`)
      toggleDisplayDropdown(false)
      const element = document.getElementById(`${id}-links`)
      if (element && headerArrow) {
        element.style.opacity = "0"
        element.style.visibility = "hidden"

        headerArrow.style.transform = "rotate(0)"
      }
    }
  }

  const handleSelect = el => {
    setLabel(el)
    toggleDisplayDropdown(true)
    toggleDropdown()
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const element = document.getElementById(`${id}-links`)
      const headerArrow = document.getElementById(`${id}-arrow`)
      if (element && headerArrow) {
        element.style.opacity = "0"
        element.style.visibility = "hidden"

        headerArrow.style.transform = "rotate(0)"
      }
    }
  }

  const handleMouseOverDesktop = () => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const headerArrow = document.getElementById(`${id}-arrow`)
      const element = document.getElementById(`${id}-links`)
      if (element && headerArrow) {
        element.style.opacity = "1"
        element.style.visibility = "visible"
        headerArrow.style.transform = "rotate(180deg)"
      }
    }
  }
  return (
    <Wrapper color={color}>
      {/* <label>{heading}</label> */}
      <Container
        onMouseOut={handleClickOutsideMobile}
        onMouseOver={handleMouseOverDesktop}
        onFocus={handleMouseOverDesktop}
        onBlur={handleClickOutsideMobile}
        id={`${id}-container`}
        data-testid="header-dropdown"
        tabIndex={0}
        onClick={() => toggleDropdown()}
      >
        <List>
          <li>
            <span id={id}>{label}</span>
            <InnerLinks id={`${id}-links`} displayDropDown={displayDropDown}>
              {dropdown.map((option: IOption, key: number) => {
           
                return (
                  <a key={key} href={formatLink(option.uri)}>
                    {option.title}
                  </a>
                )
              })}
            </InnerLinks>
          </li>
          <ArrowContainer>
            <Triangle id={`${id}-arrow`} data-testid="header-dropdown-arrow" />
          </ArrowContainer>
        </List>
      </Container>
    </Wrapper>
  )
}

export default Dropdown

interface IWrapper {
  color?: string
}

const Container = styled.div`
  position: relative;
  width: 100%;
  z-index: 3;
  cursor: pointer;
  color: ${color.text.primary} !important;
  display: flex;
  background: white;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  height: 4vw;
  box-shadow: inset 0 1.5px 2px 0 rgba(0, 0, 0, 0.25);

  box-sizing: border-box;
  z-index: 3;
  &:hover {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  @media (max-width: 1023px) {
    height: 6rem;
  }
  &:focus {
    outline: none;
  }
`
const Wrapper = styled.div<IWrapper>`
  margin-bottom: 2rem;
  width: 100%;
  max-width: 25vw;
  min-width: 30rem;

  @media (max-width: 1023px) {
    max-width: 325px;
  }
  & label {
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-weight: 300;
    color: ${props => (props.color ? props.color : "white")};
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
`

const List = styled.ul`
  list-style-type: none !important;
  margin: 0;
  padding: 0 1.3vw;
  height: 6rem;
  height: 4vw;
  font-size: 1.1vw;
  line-height: 2vw;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 3;
  border: 1px solid transparent;
  & li {
    margin-bottom: 0 !important;
  }
  & li:before {
    content: none !important;
  }

  ${Container}:hover & {
    border: 1px solid ${color.brand.green};
  }
  ${Container}:focus & {
    border: 1px solid ${color.brand.green};
  }

  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 22px;
    height: 6rem;
    padding: 0 2rem;
  }
`

interface IInnerLink {
  displayDropDown?: boolean
}
const InnerLinks = styled.div<IInnerLink>`
  position: absolute; /* it doesn't cause the page to jump when visible */
  top: 5.8rem;
  top: 3.9vw;
  left: 0;
  right: 0;
  background: white;
  transition: all 0.15s;
  opacity: 0;
  visibility: hidden;
  max-height: 24vh;
  overflow-y: scroll;
  width: 100%;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  z-index: 1;
  outline-left: 1px solid ${color.keyline.grey};
  border-top: 1px solid ${color.brand.green};
  outline: 1px solid ${color.keyline.grey};
  & a {
    &:focus {
      outline: none;
    }
    display: block;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    padding: 1rem 1.3vw 1.3rem;
    background: white;
    font-size: 14px;
    line-height: 19px;
    font-size: 1.1vw;
    line-height: 2vw;
    font-weight: 400;
    opacity: 1;
    color: ${color.text.primary} !important;
    @media (max-width: 1023px) {
      font-size: 14px;
      line-height: 19px;
      max-height: 180px;
      padding: 1rem 2rem 1.3rem;
    }
    &:hover,
    &:focus {
      background-color: rgba(196, 237, 255, 0.3);
    }
    &:active {
      background-color: ${color.support.green};
      color: white !important;
    }
  }
  /* Chrome and Safari scroll bar */
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${color.brand.green};
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 10px;
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0.22, ${color.brand.green}),
      color-stop(0.44, ${color.brand.green}),
      color-stop(0.8, #37a1ce)
    );
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
  }

  /* Firefox scroll bar */
  scrollbar-width: thin;
  scrollbar-color: ${color.brand.green} #fff;

  ${Container}:hover & {
    opacity: 1;
  }
  &:focus {
    border: none;
  }

  @media (max-width: 1023px) {
    top: 5.8rem;
  }
`

const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Arrow = styled.img`
  height: 1rem;
  width: 1rem;
  transition: all 0.5s;

  ${Container}:hover & {
    transform: rotate(180deg);
  }
`
const Triangle = styled.div`
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #0d0802;
  border-left: 0.3vw solid transparent;
  border-right: 0.3vw solid transparent;
  border-top: 0.3vw solid #0d0802;
  @media (max-width: 1023px) {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #0d0802;
  }
`
