interface OpenTimesObj {
  isOpen: boolean
  text: string
}

const saturdayClosingTime: number = 14
const saturdayOpeningTime: number = 9

const weekdayClosingTime: number = 20
const weekdayOpeningTime: number = 8

const opensMonday: string = "Opens 8am on Monday"

const openNow = "Closes at 8pm"
const opensTomorrow: string = "Opens tomorrow at 8am"
const willOpen: string = "Opens today at 8am"

const fridayClosed: string = "Opens tomorrow at 9am"

const saturdayOpen: string = "Closes at 2pm"
const saturdayWillOpen: string = "Opens today at 9am"

const getOpeningTimes = (dateTime: any): OpenTimesObj => {
  const date: Array<string> = dateTime.format("dddd HH").split(" ")
  const day: string = date[0]
  const hour: number = Number(date[1])

  if (day === "Sunday") {
    return {
      isOpen: false,
      text: opensMonday,
    }
  }

  if (day === "Saturday") {
    if (hour >= saturdayClosingTime) {
      return {
        isOpen: false,
        text: opensMonday,
      }
    } else if (hour < saturdayOpeningTime) {
      return {
        isOpen: false,
        text: saturdayWillOpen,
      }
    } else {
      return {
        isOpen: true,
        text: saturdayOpen,
      }
    }
  }

  if (day === "Friday") {
    if (hour >= weekdayClosingTime) {
      return {
        isOpen: false,
        text: fridayClosed,
      }
    }
  }

  if (hour < weekdayOpeningTime) {
    return {
      isOpen: false,
      text: willOpen,
    }
  } else if (hour >= weekdayClosingTime) {
    return {
      isOpen: false,
      text: opensTomorrow,
    }
  } else {
    return {
      isOpen: true,
      text: openNow,
    }
  }
}

export default getOpeningTimes
