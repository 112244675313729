import React from "react"
import PatientReferrals from "./PatientReferrals"
import { graphql } from "gatsby"
import { formatLink } from "../../util/functions"

const PatientReferralsDrupal = ({ node }) => {
  const { heading, relationships } = node || {}
  const { field_patient_referral_card } = relationships || {}
  const cards = field_patient_referral_card.map(card => {
    return {
      heading: card.field_section_heading,
      body: card.field_section_body,
      ctaText: card.field_cta.title,
      ctaUrl: formatLink(card.field_cta.uri),
      image: card.relationships.field_image_desktop.localFile.publicURL,
      imageAlt: card.field_image_desktop.alt,
    }
  })

  return <PatientReferrals heading={heading} cards={cards} />
}

export const fragment = graphql`
  fragment PatientReferrals on paragraph__patient_referral_section {
    id
    heading: field_section_heading
    relationships {
      field_patient_referral_card {
        field_section_body
        field_section_heading
        field_image_desktop {
          alt
        }
        field_cta {
          title
          uri
        }
        relationships {
          field_image_desktop {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default PatientReferralsDrupal
