import React from "react"
import styled from "styled-components"
import SectionHeading from "../../shared/SectionHeading/SectionHeading"
import VerticalPadding from "../../helper/VerticalPadding"
import SmallHeading from "../../shared/SmallHeading/SmallHeading"
import SmallSection from "../../helper/SmallSection"
import color from "../../style/color"
import { formatLink } from "../../util/functions"

interface Col {
  heading: string
  body: string
  list: Array<string>
}

interface RoTProps {
  topText: string
  sectionHeading: string
  columns: Array<Col>
}

const RangeOfTreatments = ({ topText, sectionHeading, columns }: RoTProps) => {
  return (
    <Outer>
      <VerticalPadding>
        <Container>
          <HeadingContainer>
            <SmallHeading heading={topText} marginBottom />
            <SectionHeading heading={sectionHeading} />
          </HeadingContainer>

          <SmallSection>
            <ColumnContainer>
              {columns.map((column, key) => {
                return (
                  <Column data-testid="column" key={key}>
                    <div>{column.heading}</div>
                    <div>
                      <p>{column.body}</p>
                      <ul>
                        {column.list.map((li, i) => {
                          return (
                            <a href={formatLink(li.uri)} key={i}>
                              {li.title}
                            </a>
                          )
                        })}
                      </ul>
                    </div>
                  </Column>
                )
              })}
            </ColumnContainer>
          </SmallSection>
        </Container>
      </VerticalPadding>
    </Outer>
  )
}

const Outer = styled.div`
  border-bottom: 1px solid ${color.keyline.grey};
`

const HeadingContainer = styled.div`
  text-align: center;
  padding: 0 25px;
`

const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 5.5rem;

  @media (max-width: 767px) {
    flex-direction: column;
    padding-top: 47px;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 767px) {
    border-left: 3px solid ${color.brand.green};
    border-bottom: 1px solid ${color.brand.green};
    padding: 30px;
    border-top: 1px solid ${color.brand.green};
  }
  & ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  & a {
    display: block;
    margin-bottom: 7px;
  }
  & p {
    margin-bottom: 8px;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 26px;
    }
  }

  & > div:nth-of-type(1) {
    border-bottom: 6px solid ${color.keyline.rangeTreatmentBorder};
    padding-left: 1.8rem;
    padding-top: 7.2rem;
    padding-bottom: 2.2rem;
    padding-right: 1.8rem;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 1px;
    background: ${color.background.green10};
    border-left: 1px solid ${color.brand.green};

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 26px;
      border-left: none;
      border-right: none;
      border-bottom: none;
      padding: 0;
    }
  }

  &:last-of-type > div:nth-of-type(1) {
    border-right: 1px solid ${color.brand.green};
    @media (max-width: 767px) {
      border-right: 0;
    }
  }
  &:nth-of-type(2) > div:nth-of-type(1) {
    background: ${color.background.green20};
  }

  &:nth-of-type(3) > div:nth-of-type(1) {
    background: ${color.background.green60};
    background-image: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 2px,
      ${color.brand.green} 2px,
      ${color.brand.green} 3px
    );
  }

  & > div:nth-of-type(2) {
    padding-top: 20px;
    padding-left: 18px;
    padding: 20px 18px 0;
    @media (max-width: 767px) {
      padding: 20px 0 0;
    }
  }

  @media (max-width: 767px) {
    &:nth-of-type(1) {
      background: ${color.background.green10};
      & > div {
        background: transparent;
      }
    }
    &:nth-of-type(2) {
      background: ${color.background.green20};
      & > div {
        background: transparent !important;
      }
    }
    &:nth-of-type(3) {
      background: ${color.background.green60};
      background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 2px,
        ${color.background.green10} 2px,
        ${color.background.green10} 3px
      );
      & > div {
        background: transparent !important;
      }
    }
  }
`

const Container = styled.div`
  flex: 1;
  width: 100%;
`

export default RangeOfTreatments
