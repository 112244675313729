import React from "react"
import { Breadcrumb, Link } from "./Breadcrumb"
import StandardSection from "../../helper/StandardSection"
import { formatLink } from "../../util/functions"

const BreadcrumbDrupal = ({ node }) => {
  const { breadcrumb } = node || {}
  return (
    <StandardSection>
      <Breadcrumb noPadding>
        {breadcrumb.map((item: any, key: number) => {
          return (
            <React.Fragment key={key}>
              <Link
                href={
                  key !== breadcrumb.length - 1 ? formatLink(item.uri) : null
                }
              >
                {item.title}
              </Link>
              <span>|</span>
            </React.Fragment>
          )
        })}
      </Breadcrumb>
    </StandardSection>
  )
}

export default BreadcrumbDrupal

export const fragment = graphql`
  fragment Breadcrumb on paragraph__breadcrumb {
    id
    breadcrumb: field_breadcrumb {
      title
      uri
    }
  }
`
