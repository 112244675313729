import React from "react"
import styled from "styled-components"
import HeaderDropdown from "../HeaderDropdown/HeaderDropdown"
import StandardSection from "../../helper/StandardSection"

import {
  yellowBackground,
} from "../BackgroundGradients/BackgroundGradient"

interface IHomepageHeader {
  sectionHeading?: string
  sectionBody?: string
  dropdown: any
  dropdownLabel: string
}

const HomepageHeader = ({
  sectionHeading,
  sectionBody,
  dropdown,
  dropdownLabel,
}: IHomepageHeader) => {
  // let background = ""
  // const colourScheme = useContext(ColourScheme)
  // const getBackgroundColour = () => {
  //   switch (colourScheme.toLowerCase()) {
  //     case "yellow":
  //       background = yellowBackground
  //       break
  //     case "blue":
  //       background = blueBackground
  //       break
  //     case "lilac":
  //       background = lilacBackground
  //       break
  //     case "pink":
  //       background = pinkBackground
  //       break
  //     default:
  //       background = pinkBackground
  //   }
  //   return background
  // }
  // getBackgroundColour()
  return (
    <ColourBackgroundContainer background={yellowBackground}>
      <MainContainer data-testid="main-container">
        <StandardSection>
          <Mask>
            <InnerContainer>
              <TextContainer data-testid="text-container">
                {sectionHeading && (
                  <SectionHeading data-testid="hp-heading">
                    {sectionHeading}
                  </SectionHeading>
                )}
                {sectionBody && (
                  <Body data-testid="hp-body">{sectionBody}</Body>
                )}
              </TextContainer>
              <DropdownContainer>
                <HeaderDropdown
                  placeholder={dropdownLabel}
                  dropdown={dropdown}
                  id="hp-dropdown"
                />
              </DropdownContainer>
            </InnerContainer>
          </Mask>
        </StandardSection>
      </MainContainer>
    </ColourBackgroundContainer>
  )
}

const MainContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 15vh 0 15vh;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 1200px) {
    background-position: center;
  }
  @media (max-width: 767px) {
    height: auto;
    padding: 110px 0 140px;
  }
`

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  transition: all 0.7s;
`

export const Overlay = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
`
const Mask = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-left: 17%;
  z-index: 2;
  @media (max-width: 1023px) {
    width: 100%;
    padding-left: 0;
  }
`

const InnerContainer = styled.div`
  max-width: 36vw;
  z-index: 2;
  position: relative;
  @media (max-width: 1023px) {
    max-width: 100%;
  }
`

const TextContainer = styled.div`
  @media (max-width: 1023px) {
    width: 100%;
    max-width: 56%;
  }
`

const SectionHeading = styled.h1`
  line-height: 9rem;
  font-size: 6vw;
  line-height: 7vw;
  margin-bottom: 1.3rem;
  @media (max-width: 1023px) {
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 20px;
  }
`

const Body = styled.h2`
  width: 100%;
  max-width: 60vw;
  margin: 0 auto;
  margin-bottom: 1.3rem;
  font-size: 2vw;
  line-height: 3vw;
  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 26px;
    max-width: 100%;
    margin-bottom: 25px;
  }
`
const ColourBackgroundContainer = styled.div`
  background: ${props => props.background};
  background-size: 200%;
  /* animation: animateGradient 10s infinite alternate-reverse forwards; */
  @media (max-width: 1200px) {
    background-position: center;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    height: auto;
    margin-bottom: 0;
    padding-top: 0;
  }
`
const DropdownContainer = styled.div`
  z-index: 100;
`
export default HomepageHeader
