import React from "react"
import AboutUsSection from "./AboutUsSection"
import { graphql } from "gatsby"

const AboutUsSectionDrupal = ({ node }: any) => {
  const { name, position, branch, gdc, relationships } = node || {}
  const { textSection } = relationships || {}

  return (
    <AboutUsSection
      name={name}
      position={position}
      branch={branch}
      gdc={gdc}
      textSection={textSection}
    />
  )
}

export default AboutUsSectionDrupal

export const fragment = graphql`
  fragment AboutUsSection on paragraph__about_us_section {
    id
    name: field_section_heading
    position: field_position
    branch: field_branch
    gdc: field_gdc
    relationships {
      textSection: field_text_content {
        heading: field_section_heading
        body: field_section_body
      }
    }
  }
`
