import React from "react"
import PriceListLinks from "./PriceListLinks"
import { graphql, useStaticQuery } from "gatsby"

interface Price {
  title: string
  value: string
}

const PriceListLinksDrupal = ({ node }: any) => {
  // const table = data.nodeProductPrices
  const { heading, treatment, array, field_finance_link, treatmentId } =
    node || {}
  const { smallText, href } = field_finance_link || null

  return (
    <PriceListLinks
      heading={heading}
      treatment={treatment}
      array={array}
      smallText={smallText}
      href={href}
      treatmentId={treatmentId}
    />
  )
}

export default PriceListLinksDrupal

export const fragment = graphql`
  fragment PriceListLinks on paragraph__price_list_links {
    id
    treatment: field_treatment_type
    heading: field_section_heading
    treatmentId: field_treatment_id
    array: field_practice_price {
      uri
      title
    }
    field_finance_link {
      href: uri
      smallText: title
    }
  }
`
