import React from "react"
import { graphql } from "gatsby"
import Faq from "./Faq"

const FaqDrupal = ({ node }) => {
  const { sectionHeading, relationships } = node
  const { questions } = relationships
  const faqs = questions.map(q => {
    return {
      question: q.field_question,
      answer: q.field_answer.value,
    }
  })

  return <Faq questions={faqs} sectionHeading={sectionHeading} />
}

export default FaqDrupal

export const fragment = graphql`
  fragment Faq on paragraph__faqs {
    id
    sectionHeading: field_section_heading
    relationships {
      questions: field_question_answer {
        field_question
        field_answer {
          value
        }
      }
    }
  }
`
