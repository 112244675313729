import React from "react"
import EmergencyHeader from "./EmergencyHeader"
import { graphql } from "gatsby"
import { get } from "../../util/functions"

const EmergencyHeaderDrupal = ({ node }: any) => {
  const {
    price,
    sectionHeading,
    field_section_body_formatted,
    pillText,
    relationships,
    field_cta,
    mobAlt,
    breadcrumb,
    financeAvailable,
  } = node || {}
  const { details, textColumns } = relationships
  const logoAlt = textColumns.field_icon && textColumns.field_icon.alt
  const logo = get(
    ["relationships", "field_icon", "localFile", "publicURL"],
    textColumns,
    ""
  )

  return (
    <EmergencyHeader
      sectionHeading={sectionHeading}
      sectionBody={field_section_body_formatted.value}
      pillText={pillText}
      price={price}
      ctaText1={field_cta.title}
      ctaHref1={field_cta.uri}
      details={details}
      breadcrumb={breadcrumb}
      textColumnsSmall={textColumns.field_suitable_for}
      textColumnHeading={textColumns.field_section_heading}
      columnsCta={textColumns.field_cta}
      logo={logo}
      logoAlt={logoAlt}
      financeAvailable={financeAvailable}
    />
  )
}

export default EmergencyHeaderDrupal

export const fragment = graphql`
  fragment EmergencyHeader on paragraph__emergency_header {
    id
    financeAvailable: field_0_finance_available
    price: field_price
    sectionHeading: field_section_heading
    field_section_body_formatted {
      value
    }
    pillText: field_category
    field_cta {
      uri
      title
    }
    breadcrumb: field_breadcrumb {
      title
      uri
    }
    relationships {
      textColumns: field_text_column_emergency {
        field_section_heading
        field_icon {
          alt
        }
        field_cta {
          title
          uri
        }
        field_suitable_for
        relationships {
          field_icon {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
