import React, { useContext } from "react"
import styled from "styled-components"
import { formatLink } from "../../util/functions"
import StandardSection from "../../helper/StandardSection"
import { Breadcrumb, Link } from "../../shared/Breadcrumb/Breadcrumb"
import color from "../../style/color"
import TwoColumns from "../TextTwoColumnsSmall/TextTwoColumnsSmallSmile"
import { Overlay } from "./HomepageHeader"
import Btn from "../../shared/Button/Button"
import ColourScheme from "../../helper/UserContext"
import {
  yellowBackground,
  lilacBackground,
  blueBackground,
  pinkBackground,
} from "../BackgroundGradients/BackgroundGradient"

interface ICategoryHeader {
  sectionHeading?: string
  sectionBody?: string
  imageMobile?: string
  imageDesktop?: string
  ctaText?: string
  ctaUrl?: string
  breadcrumb: any
  heading: string
  text: string
  cta?: any
  introCta?: any
}

const SmileHeader = ({
  sectionHeading,
  sectionBody,
  imageMobile,
  imageDesktop,
  breadcrumb,
  heading,
  text,
  cta,
  introCta,
}: ICategoryHeader) => {
  const colourScheme = useContext(ColourScheme)

  let background = ""
  const getBackgroundColour = () => {
    if (colourScheme) {
      switch (colourScheme.toLowerCase()) {
        case "yellow":
          background = yellowBackground
          break
        case "blue":
          background = blueBackground
          break
        case "lilac":
          background = lilacBackground
          break
        case "pink":
          background = pinkBackground
          break
        default:
          background = pinkBackground
      }
      return background
    }
  }
  getBackgroundColour()
  return (
    <BackgroundColor>
      <MainContainer
        background={background}
        imageMobile={imageMobile}
        imageDesktop={imageDesktop}
      >
        {imageDesktop && <Overlay />}
        <StandardSection>
          {sectionHeading && (
            <TextContainer>
              {sectionHeading && (
                <SectionHeading data-testid="category-header">
                  {sectionHeading}
                </SectionHeading>
              )}
              {sectionBody && (
                <Body data-testid="category-body">{sectionBody}</Body>
              )}
              {cta && (
                <BtnContainer>
                  <Btn href={formatLink(cta.uri)} text={cta.title} />
                </BtnContainer>
              )}
            </TextContainer>
          )}
        </StandardSection>
      </MainContainer>
      {breadcrumb && (
        <StandardSection>
          <Breadcrumb noPadding>
            {breadcrumb.map((item, key) => {
              return (
                <React.Fragment key={key}>
                  <Link
                    href={
                      key !== breadcrumb.length - 1
                        ? formatLink(item.uri)
                        : null
                    }
                  >
                    {item.title}
                  </Link>
                  <span>|</span>
                </React.Fragment>
              )
            })}
          </Breadcrumb>
        </StandardSection>
      )}
      <TwoColumns sectionHeading={heading} text={text} cta={introCta} />
    </BackgroundColor>
  )
}

const BtnContainer = styled.div`
  @media (max-width: 1023px) {
  }
`

const BackgroundColor = styled.div`
  background: ${color.background.lightGrey};
`
interface IMainContainer {
  background?: string
  imageDesktop?: string
  imageMobile?: string
}
const MainContainer = styled.div<IMainContainer>`
  margin: 0 auto;
  width: 100%;

  ${props =>
    props.background
      ? `background: ${props.background};
background-size: 200%;
animation: animateGradient 10s infinite alternate-reverse forwards;`
      : `background-image: url(${props.imageDesktop});
background-repeat: no-repeat;
background-size: cover;`}
  padding: 100px 0 155px;
  display: flex;
  align-items: center;
  min-height: 400px;
  position: relative;

  @media (max-width: 1200px) {
    background-position: center;
  }
  @media (max-width: 1023px) {
    padding: 200px 0 30px;
    align-items: flex-end;
  }
  @media (max-width: 767px) {
    ${props =>
      props.background
        ? `background: ${props.background};`
        : `background-image: url(${props.imageMobile});`}
    min-height: 350px;
  }
`
const TextContainer = styled.div`
  width: 100%;
  position: relative;
  @media (max-width: 1023px) {
  }
`

const SectionHeading = styled.h1`
  margin-bottom: 2rem;
  width: 100%;
  max-width: 53rem;
  @media (max-width: 1023px) {
    max-width: 100%;
    margin-bottom: 34px;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 17px;
  }
`

const Body = styled.p`
  width: 100%;
  max-width: 38rem;
  margin-bottom: 3rem;
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 300;
  @media (max-width: 1023px) {
    max-width: 100%;
    margin-bottom: 20px;
  }
`

export default SmileHeader
