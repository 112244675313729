import React from "react"
import OurPractices from "./OurPractices"
import { graphql } from "gatsby"
import { get, formatLink } from "../../util/functions"

const OurPracticesDrupal = ({ node }: any) => {
  const { heading, relationships } = node || {}
  const { field_practice } = relationships || {}

  const practices = field_practice.map(practice => {
    return {
      heading: practice.field_section_heading,
      title: practice.field_practice_name,
      phone: practice.field_phone_number,
      comingSoon: practice.field_pill_text,
      body: practice.field_practice_details.value,
      ctaUrl: practice.field_cta.uri,
      ctaText: practice.field_cta.title,
    }
  })

  return <OurPractices heading={heading} practices={practices} />
}

export const fragment = graphql`
  fragment PracticesContainer on paragraph__practices_container {
    id
    heading: field_section_heading
    relationships {
      field_practice {
        field_section_heading
        field_practice_name
        field_phone_number
        field_pill_text
        field_practice_details {
          value
        }
        field_cta {
          title
          uri
        }
      }
    }
  }
`

export default OurPracticesDrupal
