import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Step from "./Step"
import color from "../../../style/color"
import { throttled } from "../../../util/functions"

interface IStep {
  heading?: string
  text?: string
  period?: string
  week?: string
  middleStep?: string
}

interface StepsProps {
  id: string
  steps: any
}
const StepsContainer = ({ id, steps = defSteps }: StepsProps) => {
  const [greenHeight, setGreenHeight] = useState(0)

  useEffect(() => {
    const calcPercent = (): void => {
      if (typeof window !== "undefined" && typeof document !== "undefined") {
        const container: HTMLElement | null = document.getElementById(id)

        if (container) {
          let offsetValue: number = 0
          let top: number = 0
          if (window.innerWidth <= 767) {
            offsetValue = 400
          } else if (window.innerWidth >= 768 && window.innerWidth <= 1199) {
            offsetValue = 550
          } else {
            offsetValue = 700
          }
          top = container.getBoundingClientRect().top - offsetValue
          const height: number = container.getBoundingClientRect().height

          if (top > 0) {
            setGreenHeight(0)
          } else if (top < -height) {
            setGreenHeight(90)
          } else {
            setGreenHeight(Math.round((Math.abs(top) / height) * 90))
          }
        }
      }
    }

    const eventListener = throttled(100, calcPercent)

    window.addEventListener("scroll", eventListener)

    return () => {
      window.removeEventListener("scroll", eventListener)
    }
  }, [])
  return (
    <Container>
      <InnerContainer id={id} data-testid="inner-container">
        <Green height={greenHeight} data-testid="green-line" />
        {steps.map((step: IStep, i: number) => {
          return (
            <Step
              heading={step.heading}
              text={step.text}
              period={step.period}
              key={i}
              stepNumber={i + 1}
              week={step.week}
            />
          )
        })}
        <Line />
      </InnerContainer>
    </Container>
  )
}

export default StepsContainer

const Container = styled.div`
  width: 50%;
  @media (max-width: 1199px) {
    width: 90%;
    margin: 0 auto;
  }
`
const InnerContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
`

interface IGreen {
  height: number
}

const Green = styled.div<IGreen>`
  position: absolute;
  top: 0;
  left: 29px;
  width: 3px;
  height: ${props => props.height + "%"};
  background-color: ${color.brand.green};
  z-index: 2;
  transition: all 0.2s ease;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

const Line = styled.div`
  width: 1px;
  height: 90%;
  position: absolute;
  left: 30px;
  top: 0;
  background-color: ${color.keyline.grey};
`
