import React from "react"
import styled from "styled-components"
import color from "../../style/color"
import Cta from "../../shared/Button/Button"
import StandardSection from "../../helper/StandardSection"
import VerticalPadding from "../../helper/VerticalPadding"
import getOpeningTimes from "./OpeningTimesFunction"
import moment from "moment"
import SectionHeading from "../../shared/SectionHeading/SectionHeading"
import Pill from "../../shared/Pill/Pill"
import { trackButtonClick } from "../../util/functions"

interface Practice {
  heading: string
  title: string
  phone?: string
  comingSoon: boolean
  body: string
  ctaUrl: string
  ctaText: string
}
interface PracticesCardsProps {
  practices: Array<Practice>
  heading?: string
}

const PracticesCards = ({ heading, practices }: PracticesCardsProps) => {
  const renderOpeningTimes = () => {
    const { isOpen, text } = getOpeningTimes(moment())

    return (
      <OpeningTimes>
        <Pill>{isOpen ? "OPEN NOW" : "CLOSED"}</Pill> <p>{text}</p>
      </OpeningTimes>
    )
  }

  return (
    <MainContainer>
      <StandardSection>
        <VerticalPadding>
          <Container>
            {heading && <SectionHeading heading={heading} />}

            <ColContainer>
              {practices.map((practice, key) => {
                return (
                  <Col key={key} marginRight={(key + 1) % 3 !== 0}>
                    <TitleContainer>
                      <Title data-testid="practice-heading">
                        {practice.heading}
                      </Title>
                      <Name data-testid="practice-title">{practice.title}</Name>
                    </TitleContainer>
                    {practice.phone && (
                      <Phone
                        href={`tel:${practice.phone}`}
                        data-testid="practice-phone"
                        onClick={() =>
                          trackButtonClick("Phone Number Click: Practice Cards")
                        }
                      >
                        {practice.phone}
                      </Phone>
                    )}
                    {practice.comingSoon ? (
                      <OpeningTimes data-testid="pill-text-coming-soon">
                        <Pill>COMING SOON</Pill>
                      </OpeningTimes>
                    ) : (
                      renderOpeningTimes()
                    )}
                    <Value
                      data-testid="practice-body"
                      dangerouslySetInnerHTML={{
                        __html: `${practice.body}`,
                      }}
                    />
                    <BtnContainer>
                      <Cta
                        size="small"
                        href={practice.ctaUrl}
                        text={practice.ctaText}
                      />
                    </BtnContainer>
                  </Col>
                )
              })}
            </ColContainer>
          </Container>
        </VerticalPadding>
      </StandardSection>
    </MainContainer>
  )
}

const MainContainer = styled.section`
  width: 100%;
  position: relative;
  overflow: hidden;
`

const Phone = styled.a`
  &,
  &:visited,
  &:link {
    display: block;
    font-weight: 300;
    font-size: 3.6rem;
    line-height: 50px;
    cursor: pointer;
    margin-bottom: 10px;
    display: inline-block;

    @media (max-width: 767px) {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 6px;
    }
  }
`

const OpeningTimes = styled.div`
  display: flex;
  align-items: flex-end;

  & p {
    margin-left: 7px;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
  }
`

export default PracticesCards

const Container = styled.div`
  @media (max-width: 1023px) {
  }
`

const ColContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 4.5rem;
  grid-row-gap: 7rem;
  margin: 5rem auto 0;
  width: 100%;
  max-width: 1200px;
  @media (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }
`
interface ICol {
  marginRight?: boolean
}
const Col = styled.div<ICol>`
  box-shadow: 0 0 4px 0 #f1f1f1;
  border: 1px solid #ececec;
  background: white;
  padding: 5rem 3.7rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 767px) {
    padding: 42px 23px 40px;
  }
`
const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`
const Title = styled.h5`
  display: inline-block;
`

const Name = styled(Title)`
  font-family: Raleway;
  font-weight: 400;
  display: block;
`

const Value = styled.div`
  margin-top: 25px;
  margin-bottom: 20px;
  text-align: center;
  @media (max-width: 767px) {
    margin: 20px 0 20px;
  }
  *,
  & * {
    font-weight: 300;

    @media (max-width: 767px) {
      text-align: center;
      font-size: 16px;
      line-height: 26px;
    }
  }
  & a {
    font-size: inherit;
  }
`
const BtnContainer = styled.div`
  width: 100%;
  max-width: 147px;
  margin-top: auto;
`
