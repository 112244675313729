import React from "react"
import Sitemap from "./Sitemap"
import { graphql } from "gatsby"

const SitemapDrupal = ({ node }: any) => {
  const { links } = node || {}
  return <Sitemap links={links} />
}

export default SitemapDrupal

export const fragment = graphql`
  fragment Sitemap on paragraph__sitemap {
    id
    links: field_sitemap_column {
      value
    }
  }
`
