import React from "react"
import BtnWithImage from "../BtnWithImage"
import Btn from "../../../shared/Button/Button"
import styled from "styled-components"
import color from "../../../style/color"
import RadioBtn from "../RadioButton"
import Label from "../Label"
import Heading from "../Heading"
import { ErrorAnchor } from "../DentistReferralForm"

interface StepProps {
  changeStep: any
  reasons: Array<string>
  setReasons: any
  reasonsError?: boolean
  toggleReasonsError?: any
  handleClick: any
  reasonsList: Array<string>
}

interface Reason {
  value: string
  image: string
}

const InvisalignStep1 = ({
  changeStep,
  setReasons,
  reasons,
  reasonsError,
  handleClick,
  reasonsList,
  toggleReasonsError,
}: StepProps) => {
  return (
    <Form>
      <Inner>
        <Heading heading="Tell us about your smile goal?" />

        <Label label="Why are you thinking about straightening your teeth?*" />
        <ErrorAnchor top={"20px"} id="reasons-container" />

        <BtnsContainer hasError={reasonsError} id="reasons-container">
          {reasonsList.map((r: Reason, key) => {
            return (
              <RadioBtn
                onClick={() => {
                  handleClick(r.value)
                }}
                isActive={reasons[r.value]}
                key={key}
              >
                {r.value}
              </RadioBtn>
            )
          })}
        </BtnsContainer>

        {/* {anyReasonTrue() > 0 && <Btn text="Next" onClick={() => changeStep(2)} />} */}
      </Inner>
    </Form>
  )
}

interface IBtnsContainer {
  hasError?: boolean
}
const BtnsContainer = styled.div<IBtnsContainer>`
  display: flex;
  flex-wrap: wrap;
  & div {
    ${props =>
      props.hasError &&
      `
        border-color: ${color.support.red};
        color: ${color.support.red};
        `}
  }
`

export default InvisalignStep1

const Form = styled.div`
  padding-top: 7.7rem;
`

const Inner = styled.div`
  width: 92%;
  max-width: 995px;
  margin: 0 auto;
  padding-bottom: 18px;
  position: relative;
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 25px 18px;
  }
`
