import React, { useState, useEffect } from "react"
import styled from "styled-components"
import SectionHeading from "../../shared/SectionHeading/SectionHeading"
import Button from "../../shared/Button/Button"
import StandardSection from "../../helper/StandardSection"
import color from "../../style/color"
import VerticalPadding from "../../helper/VerticalPadding"
import {
  scrollTo,
  isInViewport,
  formatLink,
  setItem,
} from "../../util/functions"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"
import Modal from "../../components/Modal/Modal"
import RadioBtn from "../../components/Form/RadioButton"
import { graphql, useStaticQuery } from "gatsby"

interface Card {
  heading: string
  body: string
  ctaText: string
  ctaUrl: string
  image: string
  imageAlt: string
  category: string
  practice?: string
  videoConsultation?: boolean
}

interface TreatmentListProps {
  heading?: string
  cards: Array<Card>
  subheading?: string
  meetTheTeam?: boolean
  sixRows?: boolean
}

const TreatmentList = ({
  heading,
  cards,
  subheading,
  meetTheTeam,
  sixRows,
}: TreatmentListProps) => {
  const [showAllCards, toggleCards] = useState(false)
  const [showModal, toggleModal] = useState(false)
  const [practice, setPractice] = useState("")

  const data: any = useStaticQuery(graphql`
    query practicesDropdown5 {
      nodeWhitesPractices {
        field_practice
      }
    }
  `)
  const practices: Option[] = data.nodeWhitesPractices.field_practice.map(
    practice => {
      return { label: practice, value: practice }
    }
  )
  const cardsLimit = sixRows ? 6 : 3
  const charLimit = 120
  const formatText = text => {
    if (text.length < charLimit) {
      return text
    } else {
      return text.substr(0, charLimit) + "..."
    }
  }

  const id: string = "id" + Math.random()

  return (
    cards.length > 0 && (
      <Outter>
        <VerticalPadding>
          <Container>
            <HeadingContainer>
              <Scroll id={id} />
              {heading && (
                <HContainer>
                  <SectionHeading heading={heading} leftAlignMobile />
                </HContainer>
              )}
              {subheading && cards.length > 0 && (
                <StandardSection>
                  <Subheading>{subheading}</Subheading>
                </StandardSection>
              )}
            </HeadingContainer>
            <StandardSection>
              <CardContainer>
                {cards.map((card: Card, i: number) => {
                  if (!meetTheTeam || i < cardsLimit || showAllCards) {
                    return (
                      <Card data-testid="treatment-card" key={i}>
                        <Controller>
                          <Scene duration="100%">
                            <Timeline wrapper={<div className="parallax" />}>
                              <Tween
                                position="0"
                                from={{
                                  scale: 1.05,
                                }}
                                to={{
                                  scale: 1,
                                }}
                              >
                                <img
                                  data-testid="treatment-card-img"
                                  src={card.image}
                                  alt={card.imageAlt}
                                />
                              </Tween>
                            </Timeline>
                          </Scene>
                        </Controller>
                        {card.category && (
                          <Section noMarginBottom={card.practice}>
                            {card.category}
                          </Section>
                        )}
                        {meetTheTeam && <p>{card.practice}</p>}
                        {meetTheTeam ? (
                          <h5>{card.heading}</h5>
                        ) : (
                          <h4 data-testid="treatment-card-heading">
                            {card.heading}
                          </h4>
                        )}

                        {meetTheTeam ? (
                          <p data-testid="treatment-card-body">
                            {formatText(card.body)}
                          </p>
                        ) : (
                          <p data-testid="treatment-card-body">{card.body}</p>
                        )}

                        {card.videoConsultation ? (
                          <BtnContainer data-testid="treatment-card-cta">
                            <Button
                              onClick={() => {
                                toggleModal(true)
                                setPractice("")
                              }}
                              text={card.ctaText}
                            />
                          </BtnContainer>
                        ) : (
                          <BtnContainer data-testid="treatment-card-cta">
                            <Button href={card.ctaUrl} text={card.ctaText} />
                          </BtnContainer>
                        )}
                      </Card>
                    )
                  }
                })}
              </CardContainer>
            </StandardSection>
            {meetTheTeam && cards.length > cardsLimit && (
              <BtnContainerLoadMore>
                <Button
                  onClick={() => {
                    toggleCards(!showAllCards)
                    if (showAllCards) {
                      scrollTo(id)
                    }
                  }}
                  text={!showAllCards ? "Load More" : "Show less"}
                  size={"large"}
                />
              </BtnContainerLoadMore>
            )}

            <Modal showModal={showModal} toggleModal={() => toggleModal(false)}>
              <ModalInner>
                <p>Please select a practice to book a Video Consultation:</p>
                <RadioBtnsContainer>
                  {practices &&
                    practices.map((item, key) => {
                      let formatItem = item.value
                        ?.replace(/[^A-Z0-9]+/gi, "-")
                        .toLowerCase()

                      return (
                        <RadioBtn
                          small
                          key={key}
                          onClick={() => {
                            setPractice(formatItem)
                          }}
                          isActive={practice === formatItem}
                        >
                          {item.value}
                        </RadioBtn>
                      )
                    })}
                </RadioBtnsContainer>
                {practice !== "" && (
                  <ModalBtnContainer>
                    <Button
                      text={"Continue"}
                      onClick={() => {
                        setItem("video", practice)
                        window.location.href =
                          process.env.BASE === "/"
                            ? `/general-dentistry/video-consultation?practice=${practice}`
                            : process.env.BASE +
                              `/general-dentistry/video-consultation?practice=${practice}`
                      }}
                    />
                  </ModalBtnContainer>
                )}
              </ModalInner>
            </Modal>
          </Container>
        </VerticalPadding>
      </Outter>
    )
  )
}

const Scroll = styled.div`
  margin-top: -20px;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -70px;
`

const Outter = styled.div``

const BtnContainer = styled.div`
  width: 200px;
  @media (max-width: 1023px) {
    width: auto;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
`

const Subheading = styled.div`
  border-bottom: 1px solid ${color.brand.green};
  text-align: center;
  padding-bottom: 10px;
  width: 100%;
  margin-bottom: 70px;
  text-transform: uppercase;
  font-weight: 300;
  @media (max-width: 1023px) {
    margin-bottom: 20px;
  }
`

const HContainer = styled.div`
  margin-bottom: 5.7rem;
  @media (max-width: 1023px) {
    padding: 0 25px;
    margin-bottom: 40px;
  }
`

const HeadingContainer = styled.div`
  position: relative;
`

const Card = styled.div`
  overflow: hidden;
  & img {
    width: 100%;
    max-height: 32rem;
    height: 100%;
    object-fit: cover;
    margin-bottom: 2.2rem;
    transition: all 1s ease;
    backface-visibility: hidden;
    will-change: transform;
    @media (max-width: 1023px) {
      max-height: unset;
    }
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }

  & > section {
    font-weight: 200;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  & h4 {
    margin-bottom: 1.7rem;
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }

  & h5 {
    margin-bottom: 2rem;
    @media (max-width: 767px) {
      margin-bottom: 6px;
    }
  }

  & p {
    margin-bottom: 13px;
    font-weight: 300;
  }

  @media (max-width: 1023px) {
    margin-bottom: 40px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 4.5rem;
  grid-row-gap: 7rem;
  margin: 0 auto;

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
  }
`

const Container = styled.div`
  width: 100%;
`
const BtnContainerLoadMore = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10rem;
  padding: 0 25px;

  @media (max-width: 767px) {
    margin-top: 60px;
  }
`
interface ISection {
  noMarginBottom?: boolean
}
const Section = styled.section<ISection>`
  margin-bottom: ${props => (props.noMarginBottom ? "0px" : "16px")};
`

const RadioBtnsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  & p {
    margin-bottom: 2rem;
  }
`
const ModalInner = styled.div`
  & p {
    margin-bottom: 3rem;
    font-weight: 400;
  }
`

const ModalBtnContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
`

export default TreatmentList
