import React from "react"
import FinanceCalculator from "./FinanceCalculator"
import { graphql } from "gatsby"

const FinanceCalculatorDrupal = ({ node }) => {
  const { sectionHeading } = node || {}

  return <FinanceCalculator sectionHeading={sectionHeading} />
}

export const fragment = graphql`
  fragment FinanceCalculator on paragraph__finance_calculator {
    sectionHeading: field_section_heading
  }
`

export default FinanceCalculatorDrupal
