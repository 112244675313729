import React from "react"
import TextContent from "./TextContent"
import { graphql } from "gatsby"

const TextContentDrupal = ({ node }) => {
  return <TextContent text={node.field_body.value} />
}

export default TextContentDrupal

export const fragment = graphql`
  fragment TextContent on paragraph__text_content {
    id
    field_body {
      value
    }
  }
`
