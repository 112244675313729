import React from "react"
import color from "../../style/color"
import styled from "styled-components"
import StandardSection from "../../helper/StandardSection"
import VerticalPadding from "../../helper/VerticalPadding"
import Btn from "../../shared/Button/Button"
import { formatLink } from "../../util/functions"
import { graphql } from "gatsby"

interface IVideoConsultation {}

const VideoConsultation = ({}: IVideoConsultation) => {
  return (
    <Outer>
      <IframeContainer>
        <iframe
          src="https://booking.chairsyde.com/?id=7"
          width="1000"
          height="600"
        ></iframe>
      </IframeContainer>
    </Outer>
  )
}

const Outer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 5rem;
  @media (max-width: 767px) {
    padding-top: 0;
  }
`
const IframeContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background: #f8f8f8;
  padding: 5rem 0;
  & iframe {
    border: none;
    margin: 0 auto;
  }
  @media (max-width: 1023px) {
    border-radius: 4px;
  }
  @media (max-width: 767px) {
    padding-top: 0;
  }
`
export default VideoConsultation

export const fragment = graphql`
  fragment VideoConsultation on paragraph__video_consultation {
    id
  }
`
