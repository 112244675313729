import React, { useState } from "react"
import GallerySection from "./GallerySection"
import { graphql } from "gatsby"
import { get, formatLink } from "../../util/functions"
import RadioBtn from "../Form/RadioButton"
import styled from "styled-components"
import StandardSection from "../../helper/StandardSection"

const GallerySectionDrupal = ({ node }: any) => {
  const [filter, setFilter] = useState("All")

  const { relationships = {} } = node
  const { field_gallery_section } = relationships

  const renderRadio = () => {
    const buttons = [
      <RadioBtn
        onClick={() => {
          setFilter("All")
        }}
        isActive={"All" === filter}
      >
        All
      </RadioBtn>,
    ]

    for (let i = 0; i < field_gallery_section.length; i++) {
      const section = field_gallery_section[i]
      const { heading } = section
      buttons.push(
        <RadioBtn
          onClick={() => {
            setFilter(heading)
          }}
          isActive={heading === filter}
        >
          {heading}
        </RadioBtn>
      )
    }

    return buttons
  }

  const renderGallery = () => {
    const sections = []

    for (let i = 0; i < field_gallery_section.length; i++) {
      const section = field_gallery_section[i]
      const { heading } = section
      const { field_ga } = section.relationships

      if (filter !== "All" && heading !== filter) continue

      const data = field_ga.map((bA: any) => {
        const before = get(
          ["relationships", "before", "localFile", "publicURL"],
          bA,
          ""
        )
        const after = get(
          ["relationships", "after", "localFile", "publicURL"],
          bA,
          ""
        )

        return {
          beforeAlt: bA.beforeAlt.alt,
          afterAlt: bA.afterAlt.alt,
          before,
          after,
          treatmentName: bA.field_treatment_name,
          treatmentLink: formatLink(bA.field_treatment_name_link.uri),
        }
      })
      sections.push(<GallerySection heading={heading} data={data} />)
    }

    return sections
  }

  return (
    <StandardSection>
      <Inner>
        <Container>
          <RadioContainer>{renderRadio()}</RadioContainer>{" "}
        </Container>
        {renderGallery()}
      </Inner>
    </StandardSection>
  )
}

const Inner = styled.div`
  width: 100%;
`
const Container = styled.div`
  width: 100%;
  max-width: 1020px;
  margin-top: 60px;
  margin-bottom: 66px;
  margin: 60px auto 66px;
`

const RadioContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 1023px) {
    justify-content: flex-start;
  }
`

export const fragment = graphql`
  fragment GallerySection on paragraph__gallery_section_container {
    id
    relationships {
      field_gallery_section {
        heading: field_section_heading

        relationships {
          field_ga {
            field_treatment_name
            field_treatment_name_link {
              uri
            }
            relationships {
              after: field_after {
                localFile {
                  publicURL
                }
              }
              before: field_before {
                localFile {
                  publicURL
                }
              }
            }
            afterAlt: field_after {
              alt
            }
            beforeAlt: field_before {
              alt
            }
          }
        }
      }
    }
  }
`

export default GallerySectionDrupal
