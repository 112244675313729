import React from "react"
import Carousel from "./Carousel"
import { graphql } from "gatsby"
import { get } from "../../util/functions"

const CarouselDrupal = ({ node }: any) => {
  const { heading, relationships = {} } = node || {}
  const { field_before_after_images } = relationships

  const data = field_before_after_images.map((bA: any) => {
    const before = get(
      ["relationships", "before", "localFile", "publicURL"],
      bA,
      ""
    )
    const after = get(
      ["relationships", "after", "localFile", "publicURL"],
      bA,
      ""
    )

    return {
      beforeAlt: bA.beforeAlt,
      afterAlt: bA.afterAlt,
      before,
      after,
    }
  })

  return <Carousel heading={heading} data={data} />
}

export const fragment = graphql`
  fragment BeforeAfterCarousel on paragraph__before_after_carousel {
    id
    heading: field_section_heading
    relationships {
      field_before_after_images {
        field_after {
          afterAlt: alt
        }
        field_before {
          beforeAlt: alt
        }
        relationships {
          after: field_after {
            localFile {
              publicURL
            }
          }
          before: field_before {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default CarouselDrupal
