import React from "react"
import WHeader from "./WHeader"
import { graphql } from "gatsby"

const GreyHeaderDrupal = ({ node }: any) => {
  const { field_section_heading, field_body, field_breadcrumb } = node || {}

  return (
    <WHeader
      heading={field_section_heading}
      text={field_body.value}
      breadcrumb={field_breadcrumb}
    />
  )
}

export const fragment = graphql`
  fragment WHeader on paragraph__wysiwyg_header {
    id
    field_section_heading
    field_body {
      value
    }
    field_breadcrumb {
      title
      uri
    }
  }
`

export default GreyHeaderDrupal
