import React from "react"
import styled from "styled-components"
import color from "../../style/color"

interface HeadingProps {
  heading?: string
}

const Heading = ({ heading }: HeadingProps) => {
  return <Text>{heading}</Text>
}

export default Heading

const Text = styled.h3`
  margin-bottom: 3.2rem;
`
