import jwt from "jsonwebtoken"

export const updateGoogleSheet = (
  token: string,
  sheetName: string,
  values: Array<string>,
  confirmationPage: string
): void => {
  if (token) {
    fetch(
      `https://sheets.googleapis.com/v4/spreadsheets/${process.env.SHEET}/values/${sheetName}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then(resp => resp.json())
      .then(data => {
        if (data.values) {
          const range = sheetName + "!" + "A" + (data.values.length + 1)
          fetch(
            `https://sheets.googleapis.com/v4/spreadsheets/${process.env.SHEET}/values/${range}?valueInputOption=RAW`,
            {
              method: "PUT",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                values: [values],
              }),
            }
          )
            .then(resp => resp.json())
            .then(data => {
              window.location.href = `/${confirmationPage}`
            })
        }
      })
  }
}

export const getAuthToken = (setToken: any) => {
  var pHeader = { algorithm: "RS256" }
  const timestamp = Math.floor(Date.now() / 1000)
  var pClaim = {
    iss: process.env.SERVICEEMAIL,
    scope: "https://www.googleapis.com/auth/spreadsheets",
    aud: "https://oauth2.googleapis.com/token",
    exp: timestamp + 3600,
    iat: timestamp,
  }
  var key =
    "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCLU3AzHNEuWBzz\ndo9KT/Runx3qtCm1WmTm04vY1otS+edB8AxxmQmcL+VjDd9Jd8op0Ci6K1gtvL5g\nBOgBQpsT+k9LLdVvwkjD2JzUFjpSU1CXg7r/OLRBqJA3TQwKwnWujIVGDhtI+0Qi\njaH9WyEIdLP744KqMqIKRiSxIvNYsQiZlbDzuKsebmQRMnbm40nJzAPX7STKC26+\nsM2lXrr9A1312Yswd7VV3l7aeO1JtByHrMVtcyWALBmXVC0s7TsNQ1BKC7bJbubZ\nnC0LRZrPBbPtYtau+0h3jZdVrPuSNuuXX7Odr3xPgqts5pATrSRq+cwt8Uz4MQnn\n9PUNb0ovAgMBAAECggEAKbd/Bd7gB1dcu81JFEAieSjthUQSpPl+8tpuqit7Jgor\no0OkBjODfB+fOGyD6fSTNLfeKfgme/NisU9mxFHUK0Qsj98WZFMxBGsMcjiBl/rY\nS7WmvRqWZn55SAWq0GHvaKcslJBOvSh2+z2bPkgV+PkwlwBFNH7ao+biv6MuZ+SQ\n/56V2sAq9JM0/z+A6O5MEmQIqJTy79ZEv5U2KmARyhqHkwaQRzE8Ulv94cON2txE\nj8fhue9Csx1ZsS+MBrylmX789swG97Oi1ZiEItg6v6Z261N5O8U2lHfTbLc+JwuE\nE1Pvw4aHigE+z6JwH3mpyzTWaebAqVbpFK1AHLS0wQKBgQDCo9iTHpz1FkKWw3iq\nck5+RYshQnHCfC1c6yJMrQgikPO3/okEBUXTJeLI7oH2L96te/7saaKhZXeZEhlP\nsMJfNr37GPH8iC5RCpPb9ZB9Lf5Vh+9bejrSZp01KWnCqDI3GKrI+YqxkEa6mS0s\ntHfbRPqcfWgVahtb6/VlHGGQjwKBgQC3P4t/KeSzRQCIxHnpJpcNSP9eLEI8cp14\nv2AJmvcwP4tXQLjdgoEk0XYEtfmbAR+HcNuAdizYxHU4BGyr9YMBbzcx3cNn4vLT\nFRWgh5tKRhHjrRTCuHh4Ia9F0SrUA2Wid7AWoECQ3kydpdn642e7FIHOlEbA92jy\nO6HHJwo8YQKBgFUb8gq6uyVMO4fTJ4NYgkwiuqirdjT1uZxE0PW4P7NlsLyG14Dj\nkVm32kfESx3MZtFzQ9A6jLuIM9jCGkFHaGbb+AzBJqcnISGcv3TSFwE1yF28GATn\nGKuB5UImvrE4fOSsq5k/zM7B6LuVWTSQIUzHB9K/33BlulrifaZd16evAoGASIzt\nEtSoSl2oX33Cco+nwEfFayiEtN///p1uTX9AHKcb6NikGHch4EzZt/XANCFWtU32\nab7f9JabfikqSU6zpm684I5XXhsZXn77iAoIbnidfpG5JXrZn15ewXqVxUjvBUrz\nuZGK3pBUrxnJ2q4MFKKC3iIQfTe3Iih/3ZRUViECgYA6f3J2rLqeMBbcMeXrkazI\nTm+c72f4evx9VnORr0Wyqrf8Y0ypB2L3zjSnAycdiwlm7EWiBEaVFXc/cZvicZ29\n9CTFE+gojiAyO0rP9oxfu8M2tRRUYg/4oBjtyeAjRPDG9BV5Vba3jO5vPm6qIxgk\n0RU98rvCSwcxizEPB6arwA==\n-----END PRIVATE KEY-----\n"
  var sJWS = jwt.sign(pClaim, key, pHeader)
  var urlEncodedData = ""
  var urlEncodedDataPairs = []
  urlEncodedDataPairs.push(
    encodeURIComponent("grant_type") +
      "=" +
      encodeURIComponent("urn:ietf:params:oauth:grant-type:jwt-bearer")
  )
  urlEncodedDataPairs.push(
    encodeURIComponent("assertion") + "=" + encodeURIComponent(sJWS)
  )
  urlEncodedData = urlEncodedDataPairs.join("&").replace(/%20/g, "+")
  fetch("https://oauth2.googleapis.com/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: urlEncodedData,
  })
    .then(resp => resp.json())
    .then(data => {
      setToken(data["access_token"])
    })
}
