import React, { useState } from "react"
import styled from "styled-components"
import color from "../../style/color"

interface BtnProps {
  title: string
  subTitle: string
  image: string
  hoveredImage: string
  isActive: boolean
  onClick: any
  noMarginRight?: boolean
  id: string
}

const BtnWithImage = ({
  title,
  subTitle,
  image,
  hoveredImage,
  isActive,
  onClick,
  noMarginRight,
  id,
}: BtnProps) => {
  const [isHovered, toggleHovered] = useState(false)

  return (
    <Btn
      data-testid="container"
      isActive={isActive}
      noMarginRight={noMarginRight}
      onClick={onClick}
      onMouseEnter={() => toggleHovered(!isHovered)}
      onMouseLeave={() => toggleHovered(!isHovered)}
      isHovered={isHovered}
    >
      <ImgContainer>
        <Image
          id={id}
          isActive={isActive}
          data-testid="btn-img"
          isHovered={isHovered}
          src={isActive || isHovered ? hoveredImage : image}
        />
      </ImgContainer>
      <Title data-testid="btn-text">{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
    </Btn>
  )
}

export default BtnWithImage

interface BtnStyleProps {
  isActive: boolean
  noMarginRight?: boolean
  isHovered?: boolean
}

const Btn = styled.div<BtnStyleProps>`
  min-width: 26.7rem;
  display: flex;
  padding: 3.6rem 2rem 2rem;
  flex-direction: column;
  align-items: center;
  margin: 0 10px 10px 0;
  color: ${color.brand.blue};
  cursor: pointer;
  border: 1px solid #dddddd;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: inset 0 1.5px 2px 0 rgba(0, 0, 0, 0.25);
  transition: transform 200ms ease, background-color 200ms ease,
    color 200ms ease, border-color 200ms ease, -webkit-transform 200ms ease;
  ${props =>
    props.isActive && `background: ${color.support.green}; color: white`};

  &:hover,
  &:focus {
    background-color: ${color.support.radioBtnHover};
    color: white;
  }
  &:active {
    transform: translate(0px, 3px);
  }
  @media (max-width: 767px) {
    width: 49%;
    min-width: 49%;
    height: auto;
    margin: 5px 0;
  }
`
const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
  & img {
    height: 5rem;
  }
`
interface ImageProps {
  isActive?: boolean
  isHovered?: boolean
}
const Image = styled.img<ImageProps>``

const Title = styled.p`
  font-size: 1.6rem;
  line-height: 2.6rem;
  font-weight: bold;
  text-align: center;
`
const SubTitle = styled.p`
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 300;
  text-align: center;
`
