import React from "react"
import PromotionBanner from "./PromotionBanner"
import { graphql } from "gatsby"

const PromotionBannerDrupal = ({ node }: any) => {
  const { cta = {}, sectionBody, sectionHeading, scroll } = node || {}
  return (
    <PromotionBanner
      sectionHeading={sectionHeading}
      sectionBody={sectionBody}
      ctaUrl={cta.uri}
      ctaText={cta.title}
      scroll={scroll}
    />
  )
}

export default PromotionBannerDrupal

export const fragment = graphql`
  fragment PromotionBanner on paragraph__promotion_banner {
    id
    sectionBody: field_section_body
    sectionHeading: field_section_heading
    scroll: field_button_scroll_to_form
    cta: field_cta {
      title
      uri
    }
  }
`
