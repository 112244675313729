import React, { useEffect, useState } from "react"
import ToothAnimation from "./ToothAnimation"
import ToothAnimationMobile from "./ToothAnimationMobile"

interface Props {
  sectionHeading: string
  heading: string
  text: string
  ctaUrl1: string
  ctaText1: string
  topTextAnimated: string
  steps: any
  finalStepImg: string
  mobFinalStepImg: string
}

const ToothAnimationContainer = ({
  sectionHeading,
  heading,
  text,
  ctaUrl1,
  ctaText1,
  topTextAnimated,
  steps,
  finalStepImg,
  mobFinalStepImg,
}: Props) => {
  const [animation, setAnimation] = useState(<></>)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const getAnimation = () => {
        if (window.innerWidth < 1200) {
          setAnimation(
            <ToothAnimationMobile
              sectionHeading={sectionHeading}
              topTextAnimated={topTextAnimated}
              imageDesktop={finalStepImg}
              imageMobile={mobFinalStepImg}
              stepNumber={6}
              heading={heading}
              text={text}
              ctaUrl1={ctaUrl1}
              ctaText1={ctaText1}
              steps={steps}
            />
          )
        } else {
          setAnimation(
            <ToothAnimation
              topTextAnimated={topTextAnimated}
              sectionHeading={sectionHeading}
              imageDesktop={finalStepImg}
              imageMobile={mobFinalStepImg}
              stepNumber={6}
              heading={heading}
              text={text}
              ctaUrl1={ctaUrl1}
              ctaText1={ctaText1}
              steps={steps}
            />
          )
        }
      }
      getAnimation()
      window.addEventListener("resize", getAnimation)
      window.addEventListener("scroll", getAnimation)
      return () => {
        window.removeEventListener("scroll", getAnimation)
        window.removeEventListener("resize", getAnimation)
      }
    }
  }, [])
  return <>{animation}</>
}

export default ToothAnimationContainer
