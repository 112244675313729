import React from "react"
import Benefits from "./Benefits"
import { graphql } from "gatsby"
import { get } from "../../util/functions"

const BenefitsDrupal = ({ node }: any) => {
  const { heading, subheading, alt, mobAlt, relationships } = node
  const {
    field_benefits_block,
    field_image_desktop,
    field_image_mobile,
  } = relationships

  const data = field_benefits_block.map((block: any) => {
    return {
      title: block.field_section_heading,
      text: block.field_section_body,
    }
  })

  return (
    <Benefits
      heading={heading}
      subheading={subheading}
      data={data}
      image={field_image_desktop.localFile.publicURL}
      alt={alt.alt}
      mobAlt={mobAlt.alt}
      mobImage={field_image_mobile.localFile.publicURL}
    />
  )
}

export default BenefitsDrupal

export const fragment = graphql`
  fragment Benefits on paragraph__benefits {
    id
    heading: field_section_heading
    subheading: field_subheading
    alt: field_image_desktop {
      alt
    }
    mobAlt: field_image_mobile {
      alt
    }
    relationships {
      field_image_desktop {
        localFile {
          publicURL
        }
      }
      field_image_mobile {
        localFile {
          publicURL
        }
      }
      field_benefits_block {
        field_section_heading
        field_section_body
      }
    }
  }
`
