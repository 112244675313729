import React from "react"
import FinanceTable from "./FinanceTable"
import { graphql } from "gatsby"
import { get, formatLink } from "../../util/functions"

const HalfAndHalfDrupal = ({ node }) => {
  const { topText, heading, body, relationships, smallPrint } = node || {}
  const { field_finance_example } = relationships || {}

  return (
    <FinanceTable
      topText={topText}
      heading={heading}
      body={body}
      financeExamples={field_finance_example}
      smallPrint={smallPrint}
    />
  )
}

export const fragment = graphql`
  fragment FinanceTable on paragraph__finance_table {
    id
    topText: field_top_text
    heading: field_section_heading
    body: field_section_body
    smallPrint: field_small_print
    relationships {
      field_finance_example {
        field_section_heading
        relationships {
          field_finance_row {
            field_apr
            field_treatment_cost
            field_term
            field_deposit
            field_monthly_repayment
          }
        }
      }
    }
  }
`

export default HalfAndHalfDrupal
