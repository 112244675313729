import React from "react"
import TreatmentContainer from "./TreatmentContainer"
import { graphql } from "gatsby"
import { get } from "../../util/functions"

const TreatmentContainerDrupal = ({ node }) => {
  const { sectionBody, sectionHeading, relationships } = node || {}
  const { field_treatment_card } = relationships || {}
  const data = field_treatment_card.map(treatment => {
    return {
      sectionHeading: treatment.field_section_heading,
      body: treatment.field_section_body,
      url: treatment.field_cta.uri,
      link: treatment.field_cta.title,
    }
  })

  return <TreatmentContainer sectionHeading={sectionHeading} data={data} />
}

export default TreatmentContainerDrupal

export const fragment = graphql`
  fragment TreatmentContainer on paragraph__treatment_container {
    id
    sectionHeading: field_section_heading
    relationships {
      field_treatment_card {
        field_section_heading
        field_section_body
        field_cta {
          title
          uri
        }
      }
    }
  }
`
