import React, { useContext } from "react"
import styled from "styled-components"
import { get, formatLink } from "../../util/functions"
import { graphql, Link as Lnk } from "gatsby"
import color from "../../style/color"
import ColourScheme from "../../helper/UserContext"

import Pill from "../../shared/Pill/Pill"
import Button from "../../shared/Button/Button"
import icon from "../../images/homepage/arrow-grey-icon.svg"
import { Breadcrumb, Link, Icon } from "../../shared/Breadcrumb/Breadcrumb"
import TextTwoColumnsSmall from "../TextTwoColumnsSmall/TextTwoColumnsSmall"
import StandardSection from "../../helper/StandardSection"
import { scrollPage, trackButtonClick } from "../../util/functions"
import {
  yellowBackground,
  lilacBackground,
  blueBackground,
  pinkBackground,
} from "../BackgroundGradients/BackgroundGradient"

interface ICol {
  title?: string
  text?: string
}

interface ITreatmentHeader {
  altDesktop?: string
  altMob?: string
  imageMobile?: string
  imageDesktop?: string
  sectionHeading?: string
  sectionBody?: string
  pillText?: string
  price?: string
  details?: Array<ICol>
  ctaText1: string
  ctaHref1: string
  breadcrumb: any
  textColumnsSmall: any
  textColumnHeading: string
  columnsCta: any
  logoAlt?: string
  logo?: string
  background?: string
  financeAvailable?: boolean
  internalLink?: boolean
}

const TreatmentHeader = ({
  sectionHeading,
  sectionBody,
  pillText,
  price,
  ctaText1,
  ctaHref1,
  details,
  breadcrumb,
  textColumnsSmall,
  textColumnHeading,
  columnsCta,
  logoAlt,
  logo,
  financeAvailable,
  internalLink,
}: ITreatmentHeader) => {
  const colourScheme = useContext(ColourScheme)

  let background = ""
  const getBackgroundColour = () => {
    switch (colourScheme.toLowerCase()) {
      case "yellow":
        background = yellowBackground
        break
      case "blue":
        background = blueBackground
        break
      case "lilac":
        background = lilacBackground
        break
      case "pink":
        background = pinkBackground
        break
      default:
        background = pinkBackground
    }
    return background
  }
  getBackgroundColour()
  return (
    <BackgroundColor>
      <MainContainer data-testid="main-container" background={background}>
        <StandardSection noPaddingMob noPaddingTablet>
          <InnerContainer>
            <TextContainer data-testid="text-container">
              <PaddingMob>
                {pillText && <TopText>{pillText}</TopText>}
                {sectionHeading && (
                  <SectionHeading data-testid="treatment-heading">
                    {sectionHeading}
                  </SectionHeading>
                )}
                {sectionBody && (
                  <Body data-testid="treatment-body">{sectionBody}</Body>
                )}
                {price && (
                  <Price data-testid="treatment-price">
                    From <span>£{price}</span>{" "}
                    {financeAvailable && (
                      <Finance to="/fees-and-finance/0-finance">
                        0% Finance available
                      </Finance>
                    )}
                  </Price>
                )}
                {ctaText1 && (
                         <ButtonsContainer>
                          <div>To book online, please use the 'Book Appointment' button at the bottom of the page and select the practice you wish to attend.</div>
                           {
              //      <Button
              //        text={ctaText1}
              //        href={ctaHref1}
              //        tertiary
              //        target={internalLink ? false : true}
              //        size="large"
              //        onClick={() => {
              //          trackButtonClick(
              //            `${sectionHeading} Treatment Header: ${ctaText1} Button`
              //         )
              //        }}
              //      />
              //      <Button
              //        text={"Make an enquiry"}
              //        onClick={() => {
              //          scrollPage("page-form")
              //        }}
              //        secondary
              //        size="large"
              //      />
                           }
                          </ButtonsContainer>
                )}
              </PaddingMob>
              {details.length > 0 && (
                <DetailsRow>
                  {details.map((col, key) => {
                    return (
                      <Col key={key}>
                        <p>{col.title}</p>
                        <span>{col.text}</span>
                      </Col>
                    )
                  })}
                </DetailsRow>
              )}
            </TextContainer>
          </InnerContainer>
        </StandardSection>
      </MainContainer>
      {breadcrumb && (
        <StandardSection>
          <Breadcrumb noPadding>
            {breadcrumb.map((item, key) => {
              return (
                <React.Fragment key={key}>
                  <Link
                    href={
                      key !== breadcrumb.length - 1
                        ? formatLink(item.uri)
                        : null
                    }
                  >
                    {item.title}
                  </Link>
                  <span>|</span>
                </React.Fragment>
              )
            })}
          </Breadcrumb>
        </StandardSection>
      )}

      <TextTwoColumnsSmall
        data={textColumnsSmall}
        sectionHeading={textColumnHeading}
        columnsCta={columnsCta}
        logo={logo}
        logoAlt={logoAlt}
      />
    </BackgroundColor>
  )
}

const BackgroundColor = styled.div`
  background: ${color.background.lightGrey};
`

const MainContainer = styled.div<ITreatmentHeader>`
  background: ${props => props.background};
  background-size: 200%;
  animation: animateGradient 10s infinite alternate-reverse forwards;
  @media (max-width: 1200px) {
    background-position: center;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    height: auto;
    margin-bottom: 0;
    padding-top: 0;
  }
`
const Finance = styled(Lnk)`
  &,
  &:visited,
  &:link {
    display: inline-block;
    margin-left: 15px;
    // &:hover {
    //   color: ${color.brand.green};
    // }
  }
`
const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    padding: 0;
  }
`

const TextContainer = styled.div`
  padding: 6rem 0;
  width: 630px;
  z-index: 2;
  position: relative;
  @media (max-width: 1199px) {
    left: 3%;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    padding: 70px 0 0;
    bottom: 0;
    left: 0;
  }
`
const PaddingMob = styled.div`
  @media (max-width: 767px) {
    padding: 0 25px;
  }
`

const TopText = styled.p`
  text-transform: uppercase;
  letter-spacing: 1px;
`

const SectionHeading = styled.h1`
  margin-top: 2.4rem;
  @media (max-width: 767px) {
    margin: 15px 0;
  }
`

const Body = styled.p`
  font-size: 20px;
  line-height: 30px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 26px;
  }
`
const Price = styled.div`
  margin-top: 10px;
  margin-bottom: 3.4rem;
  font-weight: 300;
  font-size: 22px;
  line-height: 32px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 14px;
  }
`

const ButtonsContainer = styled.div`
 font-weight: 300; 
  width: 100%;
  max-width: 510px;
  margin-bottom: 3rem;

  display: flex;
  & a {
    &:first-of-type {
      margin-right: 10px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 36px;

    & a {
      &:first-of-type {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
`

const DetailsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-transform: uppercase;
  margin-top: 10px;
  @media (max-width: 767px) {
    padding: 15px 25px 17px;
    background-color: rgba(255, 255, 255, 0.4);
  }
  @media (max-width: 450px) {
    justify-content: space-between;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-of-type) {
    border-right: 1px solid ${color.keyline.treatmentHeader};
    padding-right: 34px;
  }
  &:not(:first-of-type) {
    padding-left: 34px;
  }
  & p {
    letter-spacing: 1px;
  }
  & span {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.8rem;
    margin-top: 5px;
  }
  @media (max-width: 767px) {
    &:not(:last-of-type) {
      border-right: none;
      padding-right: 13px;
    }
    &:not(:first-of-type) {
      padding-left: 13px;
    }

    & p {
      font-size: 13px;
      line-height: 23px;
    }
    & span {
      font-size: 1.6rem;
      line-height: 2.6rem;
      margin-top: 0;
    }
  }
  @media (max-width: 450px) {
    &:not(:last-of-type) {
      padding-right: 0;
    }
    &:not(:first-of-type) {
      padding-left: 0;
    }
  }
`
const Hr = styled.hr`
  border: 0;
  height: 1px;
  background: ${color.keyline.treatmentHeader};
`

export default TreatmentHeader
