import React, { useState, useEffect } from "react"
import Input from "./Input"
import Button from "../../shared/Button/Button"
import styled from "styled-components"
import color from "../../style/color"
import { setItem, getItem } from "../../util/functions"
import {
  validateOnBlur,
  validatePhoneNumber,
  validateDateOfBirth,
  validateEmail,
  scrollPage,
  renderDate,
  validatePostcode,
} from "../../util/functions"
import Dropdown from "./CustomDropdown"
import { updateGoogleSheet, getAuthToken } from "../../googleapis/api-hooks"
import Checkbox from "./Checkbox"
import TitleButtons from "./TitleButtons"
import FormHeadingGrey from "./FormHeadingGrey"
import Heading from "./Heading"
import Label from "./Label"
import SecondSection from "./Layout/SecondSection"
import FirstSection from "./Layout/FirstSection"
import Inner from "./Layout/Inner"

const SpecificTreatmentForm = ({ preferedClinics }) => {
  const [firstName, setFirstName] = useState(getItem("firstName"))
  const [lastName, setLastName] = useState(getItem("lastName"))
  const [firstNameError, togglefirstNameError] = useState(false)
  const [lastNameError, togglelastNameError] = useState(false)
  const [title, setTitle] = useState()
  const [titleError, toggleTitleError] = useState(false)
  const [phone, setPhone] = useState(getItem("phone"))
  const [phoneError, togglePhoneError] = useState(false)
  const [email, setEmail] = useState(getItem("email"))
  const [emailError, toggleEmailError] = useState(false)
  const [dateOfBirth, setDateOfBirth] = useState(getItem("dob"))
  const [dateOfBirthError, toggleDobDateError] = useState(false)
  const [address1, setAddress1] = useState("")
  const [address2, setAddress2] = useState("")
  const [address3, setAddress3] = useState("")
  const [city, setCity] = useState("")
  const [postcode, setPostcode] = useState("")
  const [pAddress1, setPAddress1] = useState("")
  const [pAddress2, setPAddress2] = useState("")
  const [pAddress3, setPAddress3] = useState("")
  const [pCity, setPCity] = useState("")
  const [pPostcode, setPPostcode] = useState("")
  const [treatment, setTreatment] = useState("")
  const [pAddress1Error, togglePAddress1Error] = useState(false)
  const [address1Error, toggleAddress1Error] = useState(false)
  const [practiceNumber, setPracticeNumber] = useState("")
  const [practiceNumberError, togglePracticeNumberError] = useState(false)
  const [practiceName, setPracticeName] = useState("")
  const [practiceNameError, togglePracticeNameError] = useState(false)
  const [dentistName, setDentistName] = useState("")
  const [dentistNameError, toggleDentistNameError] = useState(false)
  const [practiceError, togglePracticeError] = useState(false)
  const [treatmentList, editTreatmentList] = useState([])

  const [token, setToken] = useState("")

  const updateTreatmentList = (treatment: string): void => {
    let index: number = treatmentList.indexOf(treatment)
    if (index === -1) {
      editTreatmentList([...treatmentList, treatment])
    } else {
      let list: Array<string> = [...treatmentList]
      list.splice(index, 1)
      editTreatmentList(list)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      let practice = document.querySelector("#patient-referral-form-dropdown1")
        .innerText
      setItem("firstName", firstName)
      setItem("lastName", lastName)
      setItem("practice", practice)
      setItem("email", email)
      setItem("treatment", treatmentList.join(", "))
      updateGoogleSheet(
        token,
        "PatientReferral",
        [
          firstName + " " + lastName,
          dateOfBirth,
          phone,
          email,
          [address1, address2, address3, city, postcode].join(" "),
          practice,
          dentistName,
          practiceName,
          [pAddress1, pAddress2, pAddress3, pCity, pPostcode].join(" "),
          treatmentList.join(", "),
          window.location.href,
          new Date(),
        ],
        "patient-form-confirmation"
      )
    }
  }, [token])

  const submitForm = () => {
    const goToNextScreen = () => {
      getAuthToken(setToken)
    }
    let practice = document.querySelector("#patient-referral-form-dropdown1")
      .innerText
    let idToScroll: string = ""

    let firstNameError: boolean = false
    let lastNameError: boolean = false
    let phoneError: boolean = false
    let dentistNameError: boolean = false
    let emailError: boolean = false
    let practiceError: boolean = false
    let titleError: boolean = false
    let pAddress1Error: boolean = false

    if (!title) {
      titleError = true
      toggleTitleError(true)
      if (!idToScroll) {
        idToScroll = "patient-title-container"
      }
    }
    if (firstName.length < 1) {
      firstNameError = true
      togglefirstNameError(true)
      if (!idToScroll) {
        idToScroll = "firstName"
      }
    }
    if (lastName.length < 1) {
      lastNameError = true
      togglelastNameError(true)
      if (!idToScroll) {
        idToScroll = "lastName"
      }
    }

    if (!validatePhoneNumber(phone)) {
      phoneError = true
      togglePhoneError(true)
      if (!idToScroll) {
        idToScroll = "phone"
      }
    }
    if (!validateEmail(email)) {
      emailError = true
      toggleEmailError(true)
      if (!idToScroll) {
        idToScroll = "email"
      }
    }
    if (dentistName.length < 1) {
      dentistNameError = true
      toggleDentistNameError(true)
      if (!idToScroll) {
        idToScroll = "dentist-name"
      }
    }

    if (practice === "Please select") {
      practiceError = true
      togglePracticeError(true)
      if (!idToScroll) {
        idToScroll = "patient-referral-form-dropdown1"
      }
    }

    if (pAddress1.length < 1) {
      pAddress1Error = true
      togglePAddress1Error(true)
      if (!idToScroll) {
        idToScroll = "p-address-1"
      }
    }

    if (
      !firstNameError &&
      !lastNameError &&
      !phoneError &&
      !emailError &&
      !dentistNameError &&
      !titleError &&
      !pAddress1Error
    ) {
      goToNextScreen()
    } else {
      if (idToScroll) {
        scrollPage(idToScroll)
      }
    }
  }

  const handleDateOfBirth = (val: string): void => {
    setDateOfBirth(renderDate(val))
  }

  return (
    <Container>
      <Form>
        <FirstSection>
          <Inner paddingBottom>
            <Heading heading="Patient details" />
            <Label label="Your title*" />
            <TitleButtons
              title={title}
              titleError={titleError}
              toggleTitleError={toggleTitleError}
              setTitle={setTitle}
              id="patient-title-container"
            />
            <Flex>
              <Input
                value={firstName}
                onChange={setFirstName}
                label={"Your first name*"}
                isValidated={firstName.length > 0}
                hasError={firstNameError}
                errorTitle="Please enter your first name"
                onBlur={() =>
                  validateOnBlur(firstName.length > 0, togglefirstNameError)
                }
                id="firstName"
                color={color.brand.primary}
              />
              <Gap />
              <Input
                value={lastName}
                onChange={setLastName}
                label={"Your last name*"}
                isValidated={lastName.length > 0}
                hasError={lastNameError}
                errorTitle="Please enter your last name"
                onBlur={() =>
                  validateOnBlur(lastName.length > 0, togglelastNameError)
                }
                id="lastName"
                color={color.brand.primary}
              />
            </Flex>

            <Input
              value={address1}
              onChange={setAddress1}
              label={"Your address"}
              isValidated={address1.length > 0}
              hasError={address1Error}
              errorTitle="Please enter an address line"
              id="address-1"
              color={color.brand.primary}
            />
            <Flex>
              <Input
                value={dateOfBirth}
                onChange={(val: any) => handleDateOfBirth(val)}
                label={"Your date of birth"}
                isValidated={dateOfBirth.length > 0}
                placeholder={"DD/MM/YY"}
                hasError={dateOfBirthError}
                errorTitle="This is not a valid date of birth"
                onBlur={() => {
                  if (dateOfBirth) {
                    validateOnBlur(
                      validateDateOfBirth(dateOfBirth),
                      toggleDobDateError
                    )
                  } else {
                    toggleDobDateError(false)
                  }
                }}
                id="dob"
                color={color.brand.primary}
              />
              <Gap />
              <Input
                value={phone}
                onChange={phone => phone.length < 20 && setPhone(phone)}
                label={"Your contact number*"}
                isValidated={validatePhoneNumber(phone)}
                hasError={phoneError}
                errorTitle="Please enter a valid telephone number"
                onBlur={() =>
                  validateOnBlur(validatePhoneNumber(phone), togglePhoneError)
                }
                id="phone"
                color={color.brand.primary}
              />
            </Flex>
            <Flex>
              <Input
                value={email}
                onChange={setEmail}
                label={"Your email address*"}
                isValidated={validateEmail(email)}
                hasError={emailError}
                errorTitle="Please enter a valid email address"
                onBlur={() => {
                  validateOnBlur(validateEmail(email), toggleEmailError)
                }}
                id="email"
                color={color.brand.primary}
              />
              <Gap />

              <DropdownContainer>
                <Dropdown
                  toggleDropdownError={togglePracticeError}
                  id="patient-referral-form-dropdown1"
                  heading="Your preferred clinic*"
                  placeholder="Please select"
                  options={preferedClinics}
                  color={color.brand.primary}
                  hasError={practiceError}
                  errorTitle="Please select preferred clinic"
                />
              </DropdownContainer>
            </Flex>
          </Inner>
        </FirstSection>
        <Background>
          <SecondSection>
            <Inner paddingBottom>
              <Heading heading="Your dental practice details" />
              <Flex>
                <Input
                  value={practiceName}
                  onChange={setPracticeName}
                  label={"Practice name"}
                  isValidated={practiceName.length > 0}
                  hasError={practiceNameError}
                  errorTitle="Please enter your practice name"
                  id="dentist-name"
                  color={color.brand.primary}
                />
                <Gap />
                <Input
                  value={dentistName}
                  onChange={setDentistName}
                  label={"Name of current dentist*"}
                  isValidated={dentistName.length > 0}
                  hasError={dentistNameError}
                  errorTitle="Please enter your dentist name"
                  onBlur={() =>
                    validateOnBlur(
                      dentistName.length > 0,
                      toggleDentistNameError
                    )
                  }
                  id="practice-name"
                  color={color.brand.primary}
                />
              </Flex>
              <Input
                value={pAddress1}
                onChange={setPAddress1}
                label={"Practice address*"}
                isValidated={pAddress1.length > 0}
                hasError={pAddress1Error}
                errorTitle="Please enter an address line"
                onBlur={() =>
                  validateOnBlur(pAddress1.length > 0, togglePAddress1Error)
                }
                id="p-address-1"
                color={color.brand.primary}
              />

              <Label label="Which specialist treatment do you require?" />
              <CheckboxContainer>
                <Checkbox
                  onClick={updateTreatmentList}
                  text="Dental Examinations"
                />
                <Checkbox
                  onClick={updateTreatmentList}
                  text="Crowns, Bridges, Inlays & Onlays"
                />
                <Checkbox onClick={updateTreatmentList} text="Root canal" />
                <Checkbox
                  onClick={updateTreatmentList}
                  text="Amalgam Free Fillings"
                />
                <Checkbox onClick={updateTreatmentList} text="Dental Hygiene" />
                <Checkbox onClick={updateTreatmentList} text="Invisalign®" />
                <Checkbox onClick={updateTreatmentList} text="Fixed Braces" />
                <Checkbox onClick={updateTreatmentList} text="Lingual Braces" />

                <Checkbox
                  onClick={updateTreatmentList}
                  text="Porcelain Veneers"
                />
                <Checkbox
                  onClick={updateTreatmentList}
                  text="Teeth Whitening"
                />
                <Checkbox
                  onClick={updateTreatmentList}
                  text="Composite Bonding"
                />
                <Checkbox
                  onClick={updateTreatmentList}
                  text="Re-contouring Gumline"
                />
                <Checkbox
                  onClick={updateTreatmentList}
                  text="Dental Implants"
                />
                <Checkbox
                  onClick={updateTreatmentList}
                  text="Emergency Treatment"
                />
                <Checkbox onClick={updateTreatmentList} text="Botox" />
                <Checkbox onClick={updateTreatmentList} text="Fillers" />
              </CheckboxContainer>
              <BtnContainer>
                <Button
                  text="Send self referral"
                  onClick={submitForm}
                  primary
                />
              </BtnContainer>
            </Inner>
          </SecondSection>
        </Background>
      </Form>
    </Container>
  )
}

export default SpecificTreatmentForm

const Background = styled.div`
  background: ${color.background.green10};
`

const Container = styled.div`
  @media (max-width: 767px) {
  }
`
const Form = styled.div`
  & label {
    font-weight: 300;
  }
  @media (max-width: 767px) {
  }
`

interface IRadioContainer {
  hasError: boolean
}
const RadioContainer = styled.div<IRadioContainer>`
  display: flex;
  padding-bottom: 1.6rem;
  padding-top: 8px;
  flex-wrap: wrap;

  & label {
    display: block;
  }
  & div {
    ${props =>
      props.hasError &&
      `
        border-color: #d0021b;
        color: #d0021b;
        `}
  }

  @media only screen and (max-width: 850px) {
    & > div {
      margin: 0 10px 10px 0;
      padding: 20px 29px;

      @media (max-width: 850px) {
        padding: 12px 30px;
      }
    }
  }
`
const Flex = styled.div`
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
const Gap = styled.div`
  width: 4.3rem;
  @media (max-width: 767px) {
    display: none;
  }
`
const DropdownContainer = styled.div`
  flex: 1;
  @media (max-width: 767px) {
    max-width: 100%;
  }
`
const BtnContainer = styled.div`
  width: 100%;
  margin-top: 6rem;
  display: flex;
  justify-content: center;
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
