import React, { useEffect, useState } from "react"
import Layout from "../helper/layout"
import { getParagraph } from "../util/paragraphHandler"
import Helmet from "react-helmet"
import NavigationContainer from "../shared/Navigation/NavigationDrupal"
import Footer from "../shared/Footer/FooterDrupal"
import LogoTop from "../shared/LogoTop/LogoTop"
import { graphql } from "gatsby"
import styled from "styled-components"
import PageForm from "../components/Form/PageForm"
import { UserProvider, MeetUserProvider } from "../helper/UserContext"
import { getItem } from "../util/functions"
import { GlobalStyle } from "../helper/GlobalStyle"

const MasterTemplate = ({ data }) => {
  if (!data) return <></>
  const paragraphs = data.page.relationships.paragraphs.map(getParagraph)
  const colourScheme = data.page.field_colour_scheme
  const [mTTFilter, setMTTFilter] = useState("All")
  const [menuIsOpen, toggleMenu] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      const treatId = getItem("treatId")
      if (treatId) {
        const element = document.querySelector("#" + treatId)
        if (element) {
          element.scrollIntoView({ behavior: "smooth" })
        }
      }
      window.sessionStorage.clear()
    }, 100)
  }, [])
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.page.title}</title>
        <meta name="description" content={data.page.field_meta_description} />
        {/* {process.env.GATSBY_AWS_BRANCH !== "uat" && (
      <meta name="robots" content="noindex, nofollow" />
    )} */}
        {/* <meta name="robots" content="noindex, nofollow" /> */}
        <link
          rel="canonical"
          href={`https://www.wytes.co.uk` + data?.page?.path?.alias}
        />

        <script type="application/ld+json">
          {`
      {
      "@context": "http://schema.org",
      "@type": "Organization",
      "name": "Wytes",
      "url": "https://www.wytes.co.uk",
      "address": "13 Station Crescent, Westcombe Park, SE3 7EQ",
      "sameAs": [
      "https://www.facebook.com/whiteandcodental/",
      "https://www.instagram.com/whiteandcodental/"
    ]
  }
  `}
        </script>
      </Helmet>

      <Layout hideStickyButtons={data.page.field_hide_enquiry_buttons}>
        <LogoTop />
        <NavigationContainer
          slug={data.page.field_url}
          toggleMenu={toggleMenu}
          menuIsOpen={menuIsOpen}
        />
        <ComponentContainer hideContent={menuIsOpen}>
          <Mask>
            <UserProvider value={colourScheme}>
              <MeetUserProvider
                value={{
                  filter: mTTFilter,
                  setFilter: setMTTFilter,
                }}
              >
                {paragraphs}
              </MeetUserProvider>
            </UserProvider>
            <div id="page-form">
              <PageForm form={data.page.field_page_form} />
            </div>
          </Mask>

          <Footer />
        </ComponentContainer>
      </Layout>
    </React.Fragment>
  )
}

export default MasterTemplate

interface ComponentContainerProps {
  hideContent: boolean
}

const ComponentContainer = styled.div<ComponentContainerProps>`
  ${props => props.hideContent && `visibility: hidden`};
`
const Mask = styled.div`
  position: relative;
`

export const pageQuery = graphql`
  query($slug: String!) {
    page: nodeWhitesPages(field_url: { eq: $slug }) {
      id
      title
      path {
        alias
      }
      field_url
      field_colour_scheme
      field_meta_description
      field_page_form
      field_hide_enquiry_buttons
      relationships {
        paragraphs: field_component {
          type: __typename
          ...WHeader
          ...Sitemap
          ...AllPatientStories
          ...PatientReferrals
          ...Breadcrumb
          ...TextContent
          ...FinanceTable
          ...AboutUsHeader
          ...TeamSection
          ...ToothSplit
          ...DentalImplantsTable
          ...RangeOfTreatments
          ...SmileHeader
          ...InvisalignTable
          ...InvisalignAnimation
          ...DentalAnimation
          ...ExperienceCarousel
          ...PracticesContainer
          ...PatientStories
          ...HalfAndHalf
          ...TreatmentContainer
          ...HomepageHeader
          ...GreyHeader
          ...PricePageTable
          ...TreatmentPriceTable
          ...CategoryHeader
          ...BeforeAfterCarousel
          ...PromotionBanner
          ...FinanceBanner
          ...Benefits
          ...StepsToPerfectSmile
          ...TreatmentHeader
          ...TreatmentListDrupal
          ...TreatmentExplanation
          ...Faq
          ...BlogPosts
          ...AboutUsSection
          ...PracticeCards
          ...PracticeDetails
          ...PriceListLinks
          ...Press
          ...ReferralSection
          ...FinanceCalculator
          ...EmergencyHeader
          ...HomepageMeetTheTeamDrupal
          ...MeetTheTeamFilter
          ...GallerySection
          ...VideoConsultation
          ...VideoConsultationDrupal
          ...InvisalignVideo
        }
      }
    }
  }
`
