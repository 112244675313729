import React from "react"
import styled from "styled-components"
import color from "../../style/color"

interface TextAreaProps {
  heading: string
  value: string
  onChange: any
  color?: string
}

const TextArea = ({ heading, value = "", onChange, color }: TextAreaProps) => {
  return (
    <Container color={color}>
      <label>
        {heading}
        <textarea
          data-testid="text-area"
          value={value}
          onChange={e => onChange(e.target.value)}
        />
      </label>
    </Container>
  )
}

export default TextArea

interface IContainer {
  color?: string
}
const Container = styled.div<IContainer>`
  width: 100%;

  & textarea {
    resize: both;
    width: 100%;
    min-height: 8rem;
    border: 1px solid #dddddd;
    box-shadow: inset 0 1.5px 2px 0 rgba(0, 0, 0, 0.25);
    margin-top: 8px;
    padding: 2rem;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    border-radius: 0;
    color: ${color.brand.blue};
    &:hover,
    &:focus {
      border: 1px solid ${color.brand.green};
      outline: none;
    }
  }

  & label {
    color: ${props => (props.color ? props.color : color.brand.blue)};
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-weight: 300;
    @media (max-width: 767px) {
      font-size: 2rem;
      line-height: 3rem;
    }
  }
`
