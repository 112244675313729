import React from "react"
import PricePageTable from "./PricePageTable"
import { graphql, useStaticQuery } from "gatsby"
import { get } from "../../util/functions"

interface Price {
  title: string
  value: string
}

const TreatmentPriceTableDrupal = ({ node }: any) => {
  const { treatment, relationships } = node || {}
  return (
    <PricePageTable
      heading={treatment}
      array={relationships.field_pricing_row}
    />
  )
}

export default TreatmentPriceTableDrupal

export const fragment = graphql`
  fragment PricePageTable on paragraph__price_page_table {
    id
    treatment: field_treatment_name
    relationships {
      field_pricing_row {
        value: field_treatment_s_price
        title: field_treatment_s_name
      }
    }
  }
`
