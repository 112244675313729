import React, { useEffect } from "react"
import Btn from "../../../shared/Button/Button"
import styled from "styled-components"
import color from "../../../style/color"
import Label from "../Label"
import TitleButtons from "../TitleButtons"
import {
  validateOnBlur,
  validatePhoneNumber,
  validateEmail,
  getItem,
} from "../../../util/functions"
import Input from "../Input"
import Heading from "../Heading"
import Dropdown from "../CustomDropdown"
import SecondSection from "../Layout/SecondSection"
import Inner from "../Layout/Inner"

interface Option {
  label: string
  value: string
}

interface StepProps {
  changeStep: any
  title: string
  setTitle: any
  titleError: boolean
  toggleTitleError: any
  firstName: string
  setFirstName: any
  firstNameError: boolean
  toggleFirstNameError: any
  lastName: string
  setLastName: any
  lastNameError: boolean
  toggleLastNameError: any
  phone: string
  setPhone: any
  phoneError: boolean
  togglePhoneError: any
  email: string
  setEmail: any
  emailError: boolean
  toggleEmailError: any
  practice: string
  setPractice: any
  practiceError: boolean
  togglePracticeError: any
  preferedClinics: Array<Option>
  submitForm: any
}

const InvisalignStep4 = ({
  changeStep,
  title,
  setTitle,
  titleError,
  toggleTitleError,
  firstName,
  setFirstName,
  firstNameError,
  toggleFirstNameError,
  lastName,
  setLastName,
  lastNameError,
  toggleLastNameError,
  phone,
  setPhone,
  phoneError,
  togglePhoneError,
  email,
  setEmail,
  emailError,
  toggleEmailError,
  practiceError,
  togglePracticeError,
  preferedClinics,
  submitForm,
}: StepProps) => {
  useEffect(() => {
    const practice = getItem("practice")
    if (practice) {
      const dropdown = document.querySelector("#invisalign-form-dropdown1")
      if (dropdown) {
        dropdown.innerText = practice
      }
    }
  }, [])

  return (
    <SecondSection>
      <Form>
        <Inner paddingBottom invisalignLastStep>
          <Heading heading="Tell us about yourself" />
          <Label label="Your title*" />
          <TitleButtons
            title={title}
            titleError={titleError}
            toggleTitleError={toggleTitleError}
            setTitle={setTitle}
            id="smile-form-title"
          />
          <Flex>
            <Input
              value={firstName}
              onChange={setFirstName}
              label={"Your first name*"}
              isValidated={firstName.length > 0}
              hasError={firstNameError}
              errorTitle="Please enter your first name"
              onBlur={() =>
                validateOnBlur(firstName.length > 0, toggleFirstNameError)
              }
              id="firstName"
              color={color.brand.primary}
            />
            <Gap />
            <Input
              value={lastName}
              onChange={setLastName}
              label={"Your last name*"}
              isValidated={lastName.length > 0}
              hasError={lastNameError}
              errorTitle="Please enter your last name"
              onBlur={() =>
                validateOnBlur(lastName.length > 0, toggleLastNameError)
              }
              id="lastName"
              color={color.brand.primary}
            />
          </Flex>
          <Flex>
            <Input
              value={phone}
              onChange={phone => phone.length < 20 && setPhone(phone)}
              label={"Your telephone*"}
              isValidated={validatePhoneNumber(phone)}
              hasError={phoneError}
              errorTitle="Please enter a valid telephone number"
              onBlur={() =>
                validateOnBlur(validatePhoneNumber(phone), togglePhoneError)
              }
              id="phone"
              color={color.brand.primary}
            />
            <Gap />
            <Input
              value={email}
              onChange={setEmail}
              label={"Your email address*"}
              isValidated={validateEmail(email)}
              hasError={emailError}
              errorTitle="Please enter a valid email address"
              onBlur={() => {
                validateOnBlur(validateEmail(email), toggleEmailError)
              }}
              id="email"
              color={color.brand.primary}
            />
          </Flex>
          <Flex>
            <DropdownContainer>
              <Dropdown
                id="invisalign-form-dropdown1"
                heading="Your preferred clinic*"
                placeholder="Please select"
                options={preferedClinics}
                color={color.brand.primary}
                hasError={practiceError}
                errorTitle="Please select preferred clinic"
                toggleDropdownError={togglePracticeError}
              />
            </DropdownContainer>
            <Gap />
            <EmptyBox />
          </Flex>
          <BtnContainer>
            <Btn
              text="Get your results"
              onClick={submitForm}
              tertiary
              size={"large"}
            />
          </BtnContainer>
        </Inner>
      </Form>
    </SecondSection>
  )
}

export default InvisalignStep4

const Form = styled.div`
  & label {
    font-weight: 300;
  }
  @media (max-width: 767px) {
  }
`
const FirstSection = styled.div`
  padding: 7.7rem 0 0;
  margin: 0 auto;
  background: ${color.background.lightGrey};
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 0 8rem;
  }
`
interface InnerProps {
  paddingBottom?: boolean
}

const Flex = styled.div`
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
const Gap = styled.div`
  width: 4.3rem;
  @media (max-width: 767px) {
    display: none;
  }
`
const BtnContainer = styled.div`
  width: 100%;
  margin-top: 5rem;
  display: flex;
  justify-content: center;
`
const DropdownContainer = styled.div`
  flex: 1;
  @media (max-width: 767px) {
  }
`
const EmptyBox = styled.div`
  flex: 1;
  @media (max-width: 767px) {
    display: none;
  }
`
