import React, { useState, useEffect } from "react"
import color from "../../style/color"
import styled from "styled-components"
import StandardSection from "../../helper/StandardSection"
import VerticalPadding from "../../helper/VerticalPadding"
import Btn from "../../shared/Button/Button"
import { formatLink, getItem } from "../../util/functions"
import { graphql } from "gatsby"

interface Link {
  title?: string
  uri?: string
}
interface IVideoConsultation {
  data: Array<Link>
}

const VideoConsultationNew = ({ data }: IVideoConsultation) => {
  const [iframeUrl, setIFrameUrl] = useState("")

  useEffect(() => {
    let formatQueryString = getItem("video")
      ?.replace(/[^A-Z0-9]+/gi, " ")
      .toLowerCase()
    data &&
      data.map(item => {
        let formatItem = item.title?.replace(/[^A-Z0-9]+/gi, " ").toLowerCase()
        if (formatItem === formatQueryString) {
          return setIFrameUrl(item.uri)
        }
      })
  }, [])

  // return <iframe src={item.uri} width="1000" height="600"></iframe>
  return (
    <Outer>
      <IframeContainer>
        {<iframe src={iframeUrl} width="1000" height="600"></iframe>}
      </IframeContainer>
    </Outer>
  )
}

const Outer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 5rem;
  background: #f8f8f8;
  @media (max-width: 767px) {
    margin-top: 0;
  }
`
const IframeContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background: #f8f8f8;
  padding: 5rem 0;
  & iframe {
    border: none;
    margin: 0 auto;
    @media (max-width: 767px) {
      height: 800px;
    }
  }
  @media (max-width: 1023px) {
    border-radius: 4px;
    width: 90%;
  }
  @media (max-width: 767px) {
    padding-top: 0;
  }
`
export default VideoConsultationNew
