import React, { useState } from "react"
import styled from "styled-components"
import color from "../../style/color"
import checked from "../../white-co-images/checked.svg"
import checkedWhite from "../../white-co-images/checked-white.svg"

interface CheckboxProps {
  text: string
  onClick(text: string): void
}

const Checkbox = ({ text, onClick }: CheckboxProps) => {
  const [imageSrc, setImgSource] = useState(false)
  const [isActive, setActive] = useState(false)

  return (
    <Container
      tabIndex="0"
      isActive={isActive}
      onClick={() => {
        setImgSource(!imageSrc)
        onClick(text)
        setActive(!isActive)
      }}
    >
      <Box>
        <Img
          src={!imageSrc ? checkedWhite : checked}
          alt="Checked"
          onClick={() => {
            setImgSource(!imageSrc)
            onClick(text)
            setActive(!isActive)
          }}
          checked={imageSrc}
        />

        <span>{text}</span>
      </Box>
    </Container>
  )
}

export default Checkbox
interface ContainerProps {
  isActive?: boolean
  checked?: boolean
}
const Container = styled.div<ContainerProps>`
  border: 1px solid #dddddd;
  background: white;
  padding: 1.7rem 2rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  color: ${color.text.formBlack};
  font-size: 1.6rem;
  line-height: 2.1rem;
  min-width: 14rem;
  cursor: pointer;
  ${props => props.isActive && `border: 1px solid ${color.support.green};`}

  &:hover, &:focus {
    border: 1px solid ${color.support.green};
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 767px) {
    padding: 1.2rem 1.5rem;
    font-size: 14px;
    line-height: 24px;
    margin-right: 5px;
  }
  & div {
    display: flex;
    align-items: center;
  }
`
interface ImgProps {
  checked?: boolean
}
const Img = styled.img<ImgProps>`
  ${props =>
    props.checked ? "border:  1px solid white;" : "border: 1px solid #dddddd;"}
  border-radius: 4px;
  box-shadow: inset 0 1.5px 2px 0 rgba(0, 0, 0, 0.25);
  width: 26px;
  height: 26px;
  margin-right: 10px;
  cursor: pointer;
  @media (max-width: 767px) {
    height: 18px;
    width: 18px;
    margin-right: 5px;
  }
`
const Box = styled.div``
