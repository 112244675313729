import React from "react"
import PracticeDetails from "./PracticeDetails"
import { graphql } from "gatsby"

const PracticeDetailsDrupal = ({ node }: any) => {
  const {
    practiceName,
    address,
    postcode,
    email,
    body,
    logoAlt,
    relationships,
    phone,
    whatsApp,
    lat,
    lng,
  } = node || {}
  const { openingTimes, howToFindUs, field_icon } = relationships || {}
  return (
    <PracticeDetails
      whatsApp={whatsApp}
      practiceName={practiceName}
      address={address}
      postcode={postcode}
      email={email && email}
      phone={phone}
      body={body}
      logoAlt={field_icon && logoAlt.alt}
      logo={field_icon && field_icon.localFile.publicURL}
      openingTimes={openingTimes}
      howToFindUs={howToFindUs}
      lat={lat && Number(lat)}
      lng={lng && Number(lng)}
    />
  )
}

export default PracticeDetailsDrupal

export const fragment = graphql`
  fragment PracticeDetails on paragraph__practice_details {
    id
    practiceName: field_practice_name
    address: field_address
    postcode: field_postcode
    email: field_email
    phone: field_phone_number
    body: field_section_body
    lat: field_latitude
    lng: field_longitude
    whatsApp: field_whatsapp_link {
      title
      uri
    }
    logoAlt: field_icon {
      alt
    }
    relationships {
      openingTimes: field_opening_times {
        days: field_day_s_
        times: field_times
      }
      howToFindUs: field_how_to_f {
        title: field_section_heading
        text: field_section_body
      }
      field_icon {
        localFile {
          publicURL
        }
      }
    }
  }
`
