import React from "react"
import Table from "../Table/DentalImplantsTable"
import { graphql } from "gatsby"

const DentalImplantsTable = ({ node }) => {
  const { heading, smallHeading, dentalImplants, dentalBridge, bottomText } =
    node || {}
  const stickyHeading = [
    { title: "Dental Implants" },
    { title: "Dental Bridge" },
  ]

  const rows = [
    ["Longevity", "Many years", "5 - 7 yrs"],
    ["Appearance", "Natural", "Best match"],
    ["Recurrent Decay", "No", "Yes"],
    ["Treatment time", "Within months", "Within weeks"],
    ["Price from", dentalImplants, dentalBridge],
  ]

  return (
    <Table
      heading={heading}
      smallHeading={smallHeading}
      stickyHeading={stickyHeading}
      rows={rows}
      bottomText={bottomText}
    />
  )
}

export default DentalImplantsTable

export const fragment = graphql`
  fragment DentalImplantsTable on paragraph__dental_implants_table {
    id
    heading: field_section_heading
    smallHeading: field_top_text
    dentalImplants: field_dental_implants_price
    dentalBridge: field_dental_bridge
    bottomText: field_bottom_text
  }
`
