import React from "react"
import ToothAnimation from "./ToothAnimationContainer"
import { graphql } from "gatsby"
import { formatLink } from "../../util/functions"

const DentalImplantDrupal = ({ node }) => {
  const { sectionHeading, subHeading, topTextAnimated, relationships } =
    node || {}

  const { field_final_step, field_dental_step } = relationships || {}
  const { text, heading, field_cta } = field_final_step
  const desktopImage =
    field_final_step.relationships.field_image_desktop.localFile.publicURL
  const mobileImage =
    field_final_step.relationships.field_image_mobile.localFile.publicURL

  const steps = field_dental_step.map((step, i) => {
    return {
      heading: step.field_section_heading,
      text: step.field_section_body,
      period: step.field_period,
      stepNumber: i + 1,
      week: step.field_week,
    }
  })

  return (
    <ToothAnimation
      sectionHeading={sectionHeading}
      subHeading={subHeading}
      heading={heading}
      text={text}
      ctaUrl1={formatLink(field_cta.uri)}
      ctaText1={field_cta.title}
      topTextAnimated={topTextAnimated}
      steps={steps}
      finalStepImg={desktopImage}
      mobFinalStepImg={mobileImage}
    />
  )
}

export const fragment = graphql`
  fragment DentalAnimation on paragraph__dental_implant_animation {
    id
    sectionHeading: field_section_heading
    subHeading: field_subheading
    topTextAnimated: field_mobile_sub_heading
    relationships {
      field_dental_step {
        field_period
        field_section_body
        field_section_heading
        field_week
      }
      field_final_step {
        field_cta {
          uri
          title
        }
        text: field_section_body
        heading: field_section_heading
        relationships {
          field_image_desktop {
            localFile {
              publicURL
            }
          }
          field_image_mobile {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default DentalImplantDrupal
