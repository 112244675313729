import React from "react"
import color from "../../style/color"
import styled from "styled-components"
import StandardSection from "../../helper/StandardSection"
import VerticalPadding from "../../helper/VerticalPadding"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"
import Img from "gatsby-image"
import Btn from "../../shared/Button/Button"
import { formatLink } from "../../util/functions"

interface ITreatmentExplaination {
  topText: string
  heading: string
  flip?: boolean
  text?: HTMLElement
  imageDesktop?: string
  imageMobile?: string
  altDesktop?: string
  altMobile?: string
  sources: any
  cta?: any
  changeColor?: any
}

interface IMainContainer {
  flip?: boolean
}

const TreatmentExplaination = ({
  topText,
  heading,
  flip,
  text,
  imageDesktop,
  imageMobile,
  altDesktop,
  altMobile,
  sources,
  cta,
  changeColor,
}: ITreatmentExplaination) => {
  return (
    <Outer>
      <VerticalPadding>
        <StandardSection>
          <MainContainer flip={flip} data-testid="treatment-explanation">
            <TextSection flip={flip}>
              <InnerTextContainer>
                <TopText data-testid="topText">{topText}</TopText>
                {heading && <h3>{heading}</h3>}
                {text && (
                  <SectionBody
                    dangerouslySetInnerHTML={{
                      __html: text.value,
                    }}
                  />
                )}
                {cta && (
                  <BtnContainer>
                    <Btn
                      href={formatLink(cta.uri)}
                      text={cta.title}
                      primary={changeColor}
                    />
                  </BtnContainer>
                )}
              </InnerTextContainer>
            </TextSection>

            <ImgContainer>
              <Controller>
                <Scene duration="100%">
                  <Timeline wrapper={<div className="parallax-treatment" />}>
                    <Tween
                      position="0"
                      from={{
                        scale: 1.1,
                      }}
                      to={{
                        scale: 1,
                      }}
                    >
                      <Mask>
                        {sources && (
                          <Image
                            fluid={sources}
                            alt={altDesktop}
                            placeholderStyle={{ opacity: 0 }}
                          />
                        )}
                      </Mask>
                    </Tween>
                  </Timeline>
                </Scene>
              </Controller>
            </ImgContainer>
          </MainContainer>
        </StandardSection>
      </VerticalPadding>
    </Outer>
  )
}

const BtnContainer = styled.div`
  @media (max-width: 1023px) {
    margin-top: 20px;
  }
`

const Outer = styled.div`
  width: 100%;
`

const MainContainer = styled.div<IMainContainer>`
  position: relative;
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: flex-end;
  flex-direction: ${props => props.flip && "row-reverse"};
  align-items: center;
  @media (max-width: 1023px) {
    width: 100%;
    flex-direction: column-reverse;
    border-top: 1px solid ${color.keyline.grey};
  }
`
const TextSection = styled.div<IMainContainer>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4.7rem 7.3rem 18.5rem;
  padding-left: 0;
  padding-bottom: 0;
  ${props => (props.flip ? "right: 20%;  padding-left: 7.3rem;" : "left: 20%;")}
  z-index: 3;
  width: 50%;
  background: white;
  backface-visibility: hidden;
  @media (min-width: 3000px) {
    ${props => (props.flip ? "right: 30%;" : "left: 30%;")}
  }
  @media (max-width: 1600px) {
    ${props => (props.flip ? "right: 10%;" : "left: 10%;")}
  }

  @media (max-width: 1023px) {
    padding: 7rem;
    ${props => (props.flip ? "right: 10%;" : "left: 10%;")}

    width: 50%;
  }
  @media (max-width: 1023px) {
    position: relative;
    width: 100%;
    max-width: 100%;
    min-height: 50px;
    left: 0;
    right: 0;
    padding: 5rem 0 0;
  }
`
const Mask = styled.div`
  position: relative;
  width: 100%;
  transform-origin: bottom-right;
  transition: all 1s ease;
  backface-visibility: hidden;
  will-change: transform;

  @media (max-width: 1023px) {
    transform: none !important;
  }
`

const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: center;
  width: 50%;
  overflow: hidden;
  & > div {
    align-self: stretch;
    flex: 1;
    display: flex;
    justify-content: stretch;
    height: 100%;
  }
  .parallax-treatment {
    width: 100%;
    display: flex;
  }
  @media (max-width: 1023px) {
    width: 100%;
  }
`
const Image = styled(Img)`
  display: block;
  object-fit: cover;
  transition: all 0.4s ease;
  backface-visibility: hidden;
  overflow: hidden;
  flex: 1;
  z-index: 3;
  height: 100%;
  max-height: 670px;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  transition: all 1.5s ease;
`

const ImgMob = styled(Img)`
  display: none;
  @media (max-width: 1023px) {
    display: block;
    width: 100%;
  }
`
const SectionBody = styled.div`
  margin: 3rem 0;

  & p {
    margin-bottom: 1.3rem;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Roboto" !important;
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 1.5rem;
  }

  & li {
    display: flex;
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-weight: 300;
    margin-bottom: 8px !important;
    @media (max-width: 1023px) {
      font-size: 14px;
      line-height: 24px;
    }

    &,
    & > p {
      margin-bottom: 0;
    }
  }

  & a {
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-weight: 300;
    @media (max-width: 1023px) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  & strong {
    display: inline-block;
    margin-top: 12px;
    font-size: 2rem;
    font-weight: 400;
  }

  & img {
    margin-top: 20px;
    border: 1px solid #eee;
    padding: 20px;
  }
  @media (max-width: 1023px) {
    margin-bottom: 0;
    margin-top: 11px;
  }
`

const InnerTextContainer = styled.div`
  & p {
  }

  @media (max-width: 1023px) {
    width: 100%;
    max-width: 100%;

    & h2 {
      font-size: 20px;
      line-height: 30px;
    }
  }
`
const TopText = styled.p`
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 2rem;
`

export default TreatmentExplaination
