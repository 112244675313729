import React from "react"
import styled from "styled-components"
import SmallSection from "../../helper/SmallSection"
import color from "../../style/color"

interface Props {
  text: string
}

const TextContent = ({ text }: Props) => {
  return (
    <TinySection>
      <Content dangerouslySetInnerHTML={{ __html: text }} />
    </TinySection>
  )
}

export default TextContent

const Content = styled.div`
  & h1,
  & h2 {
    margin-bottom: 6.2rem;
  }

  & h3,
  & h4,
  & h5,
  p,
  div {
    margin-bottom: 1.8rem;
    display: inline-block;
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    display: block;
  }
  & hr {
    margin-top: 3.5rem;
    margin-bottom: 5.3rem;
    color: ${color.background.lightGrey};
    opacity: 0.2;
  }
  & a {
    white-space: nowrap;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
  margin-top: 7.3rem;
  margin-bottom: 13.8rem;

  @media (max-width: 1023px) {
    margin-top: 36px;
    margin-bottom: 102px;

    & h1,
    & h2 {
      margin-bottom: 36px;
    }

    & h3,
    & h4,
    & h5,
    p,
    div {
      margin-bottom: 16px;
    }

    & hr {
      margin-top: 24px;
      margin-bottom: 40px;
    }
  }
`

const TinySection = styled.div`
  max-width: 994px;
  margin: 0 auto;
  @media (max-width: 1199px) {
    max-width: 100%;
    padding: 0 3%;
  }
  @media (max-width: 1023px) {
    max-width: 100%;
    padding: 0 25px;
  }
`
