import React from "react"
import styled from "styled-components"
import { get, formatLink, scrollPage } from "../../util/functions"
import color from "../../style/color"
import Cta from "../../shared/Button/Button"
import VerticalPadding from "../../helper/VerticalPadding"
interface IPromotionBanner {
  sectionHeading?: string
  sectionBody?: string
  ctaText?: string
  ctaUrl: string
  scroll: boolean
}

const PromotionBanner = ({
  sectionHeading,
  sectionBody,
  ctaText,
  ctaUrl,
  scroll,
}: IPromotionBanner) => {
  return (
    <Outer>
      <VerticalPadding>
        <MainContainer data-testid="promotion-banner">
          <TextContainer data-testid="text-container">
            {sectionHeading && (
              <SectionHeading data-testid="promotion-heading">
                {sectionHeading}
              </SectionHeading>
            )}
            {sectionBody && (
              <Body data-testid="promotion-body">{sectionBody}</Body>
            )}
          </TextContainer>
          {ctaText && scroll ? (
            <Cta
              onClick={() => {
                scrollPage("page-form")
              }}
              text={ctaText}
            />
          ) : (
            <Cta href={ctaUrl} text={ctaText} />
          )}
        </MainContainer>
      </VerticalPadding>
    </Outer>
  )
}
interface IOuter {
  background?: string
}

const Outer = styled.div<IOuter>`
  background: ${color.background.blueLight};
  background-size: 200%;
  animation: animateGradient 10s infinite alternate-reverse forwards;
`

const MainContainer = styled.div<IPromotionBanner>`
  margin: 0 auto;
  width: 100%;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1023px) {
    align-items: flex-start;
  }
`
const TextContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 1023px) {
    width: 100%;
    text-align: left;
  }
`

const SectionHeading = styled.h2`
  margin-bottom: 3rem;
`

const Body = styled.p`
  width: 100%;
  margin: 0 auto;
  font-weight: 300;
  margin-bottom: 3.5rem;
  font-size: 2.2rem;
  line-height: 3.2rem;
  max-width: 600px;
  @media (max-width: 1023px) {
    max-width: 100%;
  }
`

export default PromotionBanner
