import React from "react"
import styled from "styled-components"
import RadioBtn from "./RadioButton"
import color from "../../style/color"

interface TitleButtonsProps {
  title: string
  titleError: boolean
  setTitle: any
  toggleTitleError: any
  id: string
}

const TitleButtons = ({
  title,
  titleError,
  setTitle,
  toggleTitleError,
  id,
}: TitleButtonsProps) => {
  return (
    <RadioContainer hasError={titleError}>
      <Anchor id={id} />
      <RadioBtn
        onClick={() => {
          setTitle("Mr")
          toggleTitleError(false)
        }}
        isActive={title === "Mr"}
      >
        Mr
      </RadioBtn>
      <RadioBtn
        onClick={() => {
          setTitle("Mrs")
          toggleTitleError(false)
        }}
        isActive={title === "Mrs"}
      >
        Mrs
      </RadioBtn>
      <RadioBtn
        onClick={() => {
          setTitle("Miss")
          toggleTitleError(false)
        }}
        isActive={title === "Miss"}
      >
        Miss
      </RadioBtn>
      <RadioBtn
        onClick={() => {
          setTitle("Ms")
          toggleTitleError(false)
        }}
        isActive={title === "Ms"}
      >
        Ms
      </RadioBtn>
    </RadioContainer>
  )
}

const Anchor = styled.div`
  position: absolute;
  top: -100px;
  left: 0;
`
interface IRadioContainer {
  hasError: boolean
}

const RadioContainer = styled.div<IRadioContainer>`
  display: flex;
  padding-bottom: 12px;
  flex-wrap: wrap;
  position: relative;
  & div {
    ${props =>
      props.hasError &&
      `
        border-color: ${color.support.red};
        color: ${color.support.red};
        `}
  }

  @media only screen and (max-width: 850px) {
    & > div {
      margin: 0 10px 10px 0;
      padding: 20px 29px;

      @media (max-width: 850px) {
        padding: 12px 30px;
      }
    }
  }
`

export default TitleButtons
