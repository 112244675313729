import React, { useContext } from "react"
import styled from "styled-components"
import { formatLink } from "../../util/functions"
import { Breadcrumb, Link, Icon } from "../../shared/Breadcrumb/Breadcrumb"
import Button from "../../shared/Button/Button"
import StandardSection from "../../helper/StandardSection"
import { Overlay } from "./HomepageHeader"
import color from "../../style/color"
import ColourScheme from "../../helper/UserContext"
import {
  yellowBackground,
  lilacBackground,
  blueBackground,
  pinkBackground,
} from "../BackgroundGradients/BackgroundGradient"

interface ICategoryHeader {
  sectionHeading?: string
  sectionBody?: string
  imageMobile?: string
  imageDesktop?: string
  ctaText?: string
  ctaUrl?: string
  breadcrumb: any
}

const CategoryHeader = ({
  sectionHeading,
  sectionBody,
  imageMobile,
  imageDesktop,
  breadcrumb,
  ctaText,
  ctaUrl,
}: ICategoryHeader) => {
  const colourScheme = useContext(ColourScheme)

  let background = ""
  const getBackgroundColour = () => {
    if (colourScheme) {
      switch (colourScheme.toLowerCase()) {
        case "yellow":
          background = yellowBackground
          break
        case "blue":
          background = blueBackground
          break
        case "lilac":
          background = lilacBackground
          break
        case "pink":
          background = pinkBackground
          break
        default:
          background = pinkBackground
      }
      return background
    }
  }
  getBackgroundColour()
  return (
    <>
      <MainContainer
        background={background}
        imageMobile={imageMobile}
        imageDesktop={imageDesktop}
        noPaddingMobile={imageDesktop}
      >
        {/* {imageDesktop && <Overlay />} */}
        <StandardSection
          noPaddingSmallTablet={imageDesktop}
          noPaddingMob={imageDesktop}
        >
          {sectionHeading && (
            <TextContainer mobileOverlay={imageDesktop}>
              {sectionHeading && (
                <SectionHeading data-testid="category-header">
                  {sectionHeading}
                </SectionHeading>
              )}
              {sectionBody && (
                <Body data-testid="category-body" noPaddingBottom={!ctaText}>
                  {sectionBody}
                </Body>
              )}
              {ctaText && <Button text={ctaText} href={ctaUrl} />}
            </TextContainer>
          )}
        </StandardSection>
      </MainContainer>
      {breadcrumb && (
        <StandardSection>
          <Breadcrumb noPadding>
            {breadcrumb.map((item, key) => {
              return (
                <React.Fragment key={key}>
                  <Link
                    href={
                      key !== breadcrumb.length - 1
                        ? formatLink(item.uri)
                        : null
                    }
                  >
                    {item.title}
                  </Link>
                  <span>|</span>
                </React.Fragment>
              )
            })}
          </Breadcrumb>
        </StandardSection>
      )}
    </>
  )
}

interface IMainContainer {
  background?: string
  imageDesktop?: string
  imageMobile?: string
  noPaddingMobile?: boolean
}

const MainContainer = styled.div<IMainContainer>`
  margin: 0 auto;
  width: 100%;
  ${props =>
    props.background
      ? `background: ${props.background};
background-size: 200%;
animation: animateGradient 10s infinite alternate-reverse forwards;`
      : `background-image: url(${props.imageDesktop});
background-repeat: no-repeat;
background-size: cover;`}
  padding: 100px 0 155px;
  display: flex;
  align-items: center;
  min-height: 400px;
  position: relative;

  @media (max-width: 1200px) {
    background-position: center;
  }
  @media (max-width: 1023px) {
    padding: 140px 0 50px;
    align-items: flex-end;
    min-height: 300px;
    padding-bottom: ${props => props.noPaddingMobile && "0px"};
  }
  @media (max-width: 767px) {
    min-height: 300px;
    ${props =>
      props.background
        ? `background: ${props.background};`
        : `background-image: url(${props.imageMobile});`}
  }
`
const TextContainer = styled.div`
  width: 100%;
  position: relative;

  @media (max-width: 1023px) {
    text-align: left;
    background: ${props => props.mobileOverlay && "rgba(255, 255, 255, 0.65)"};
    padding: 25px;
  }
`

const SectionHeading = styled.h1`
  margin-bottom: 2rem;
  width: 100%;
  max-width: 52.5rem;
  @media (max-width: 1023px) {
    max-width: 100%;
    margin-bottom: 34px;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 5px;
  }
`
interface IBody {
  noPaddingBottom?: boolean
}
const Body = styled.p<IBody>`
  width: 100%;
  max-width: 52rem;
  margin-bottom: ${props => (props.noPaddingBottom ? "0" : "3rem")};
  font-size: 2rem;
  line-height: 3rem;
  @media (max-width: 1023px) {
    max-width: 100%;
    margin-bottom: ${props => (props.noPaddingBottom ? "0" : "20px")};
  }
`

export default CategoryHeader
