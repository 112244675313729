import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import color from "../../style/color"
import Cta from "../../shared/Button/Button"
import { yellowBackground } from "../BackgroundGradients/BackgroundGradient"
import StandardSection from "../../helper/StandardSection"

interface IFinanceBanner {
  sectionHeading?: string
  sectionBody?: string
  ctaText?: string
  ctaUrl: string
  background?: string
}

const FinanceBanner = ({
  sectionHeading,
  sectionBody,
  ctaText,
  ctaUrl,
}: IFinanceBanner) => {
  let background = yellowBackground

  return (
    <MainContainer data-testid="finance-banner" background={background}>
      <StandardSection>
        <TextContainer data-testid="text-container">
          {sectionHeading && (
            <SectionHeading data-testid="finance-heading">
              {sectionHeading}
            </SectionHeading>
          )}
        </TextContainer>
      </StandardSection>
      <StandardSection>
        <Mask>
          <RightCol>
            {sectionBody && (
              <Body data-testid="finance-body">{sectionBody}</Body>
            )}
            {ctaText && ctaUrl && (
              <BtnContainer>
                <Cta href={ctaUrl} text={ctaText} size="small" />
              </BtnContainer>
            )}
          </RightCol>
        </Mask>
      </StandardSection>
    </MainContainer>
  )
}

export default FinanceBanner

const BtnContainer = styled.div`
  width: 100%;
  max-width: 150px;
`

const MainContainer = styled.div<IFinanceBanner>`
  margin: 0 auto;
  width: 100%;
  padding: 8.4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.background};
  background-size: 200%;
  animation: animateGradient 10s infinite alternate-reverse forwards;
  color: ${color.brand.blue};
  @media (max-width: 1200px) {
    padding: 70px 0;
  }
`
const TextContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-left: 18%;
  @media (max-width: 1023px) {
    padding-left: 0;
  }
`
const Mask = styled.div`
  width: 100%;
`

const RightCol = styled.div`
  width: 41.5rem;
  margin-right: 20%;
  margin-left: 50%;
  margin-top: 5px;
  z-index: 3;
  @media (max-width: 1023px) {
    width: 100%;
    margin-left: 0;
    margin-top: 14px;
  }
`

const SectionHeading = styled.h3`
  position: relative;
  font-size: 8rem;
  line-height: 9rem;
  width: 58rem;
  @media (max-width: 1023px) {
    font-size: 38px;
    line-height: 48px;
    width: 100%;
  }
`

const Body = styled.p`
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  font-weight: 300;
  margin-bottom: 2.3rem;
  font-size: 20px;
  line-height: 30px;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 18px;
  }
`
