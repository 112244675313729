import React, { useState } from "react"
import styled from "styled-components"
import SectionHeading from "../../shared/SectionHeading/SectionHeading"
import SmallHeading from "../../shared/SmallHeading/SmallHeading"
import SmallSection from "../../helper/SmallSection"
import color from "../../style/color"
import Table from "./Table"
import Btn from "../../shared/Button/Button"
import { formatLink } from "../../util/functions"

interface TableProps {
  topText: string
  heading: string
  body: string
  financeExamples: any
  smallPrint: string
}

const FinanceTable = ({
  topText,
  heading,
  body,
  financeExamples,
  smallPrint,
}: TableProps) => {
  const [currentExample, setExample] = useState(0)

  return (
    <Container>
      <SmallSection>
        <ScrollContainer>
          <HeadingContainer>
            <SmallHeading heading={topText} marginBottom />
            <SectionHeading heading={heading} leftAlignMobile />
            <Body>{body}</Body>
          </HeadingContainer>
          <div>
            <TableContainer>
              <Tabs>
                {financeExamples.map((ex, i) => {
                  return (
                    <Tab
                      key={i}
                      onClick={() => setExample(i)}
                      isActive={i === currentExample}
                    >
                      {ex.field_section_heading}
                    </Tab>
                  )
                })}
              </Tabs>
              <Table data={financeExamples[currentExample]} />
            </TableContainer>
          </div>
          <SmallPrint>{smallPrint}</SmallPrint>
        </ScrollContainer>
      </SmallSection>
      <BtnContainer>
        <Btn
          href={
            process.env.BASE === "/"
              ? "/fees-and-finance/finance-calculator"
              : process.env.BASE + "/fees-and-finance/finance-calculator"
          }
          text="Try our finance calculator"
        />
      </BtnContainer>
    </Container>
  )
}

const ScrollContainer = styled.div`
  width: 100%;
`
const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  @media (max-width: 767px) {
    padding: 0 20px;
  }
`
const SmallPrint = styled.p`
  font-size: 14px;
  line-height: 24px;
  margin-top: 24px;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 22px;
  }
`

const Tabs = styled.div`
  display: flex;
  justify-content: center;
`

const Tab = styled.p`
  width: 260px;
  padding-bottom: 26px;
  border-bottom: 1px solid ${color.background.grey};
  ${props =>
    props.isActive &&
    `border-bottom: 5px solid ${color.keyline.financeTableBorder};
    `}
  cursor: pointer;
  text-align: center;

  @media (max-width: 1023px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 14px 11px;
    ${props => props.isActive && `padding: 0 8px 11px;`}

    font-size: 13px;
    line-height: 23px;
  }
`

const TableContainer = styled.div`
  margin-top: 6.4rem;
`

const Container = styled.div`
  margin-top: 7rem;
  padding-bottom: 13.6rem;
  border-bottom: 1px solid ${color.background.grey};

  @media (max-width: 767px) {
    padding-top: 11px;
    padding-bottom: 38px;
  }
`

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 1023px) {
    text-align: left;
    & > * {
      text-align: left;
    }
  }
`
const Body = styled.p`
  margin-top: 23px;
  font-size: 2rem;
  line-height: 3rem;
  max-width: 857px;
  @media (max-width: 1023px) {
    max-width: 100%;
  }
  @media (max-width: 767px) {
    margin-top: 7px;
  }
`

export default FinanceTable
