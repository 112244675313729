import React from "react"
import AllPatientStories from "./AllPatientStories"
import { graphql } from "gatsby"

const AllPatientStoriesDrupal = ({ node }: any) => {
  const { relationships } = node || {}
  const { sections } = relationships || {}
  return <AllPatientStories sections={sections} />
}

export default AllPatientStoriesDrupal

export const fragment = graphql`
  fragment AllPatientStories on paragraph__all_patient_stories_ {
    id
    relationships {
      sections: field_category_patient_story {
        field_section_heading
        relationships {
          field_patient_story_card {
            field_section_heading
            field_section_body
            field_name
            field_image_desktop {
              alt
            }
            relationships {
              field_image_desktop {
                localFile {
                  publicURL
                }
              }
              field_video {
                localFile {
                  publicURL
                }
              }
              field_video_poster {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  }
`
