import React, { useState, useEffect } from "react"
import styled from "styled-components"
import SectionHeading from "../../shared/SectionHeading/SectionHeading"
import color from "../../style/color"
import SmallHeading from "../../shared/SmallHeading/SmallHeading"
import arrowGrey from "../../white-co-images/largeArrowGrey.svg"
import arrowGreen from "../../white-co-images/largeArrowGreen.svg"
import StandardSection from "../../helper/StandardSection"

interface CarouselProps {
  heading: string
  data: Array<BeforeAfter>
}

interface BeforeAfter {
  before: string
  beforeAlt: string
  after: string
  afterAlt: string
}

const Carousel = ({ heading, data }: CarouselProps) => {
  const [activeSlide, changeSlide] = useState(0)
  const [clientScroll, setClientScroll] = useState(0)
  const [containerWidth, setContainerWidth] = useState(0)

  const moveSlider = (slide: number): void => {
    const carousel = document.getElementById("ba-carousel")

    const width: number = document.getElementById("ba-carousel")?.clientWidth
    setContainerWidth(width)
    const screenWidth: number = window.innerWidth

    const moveBy: number = screenWidth > 767 ? width / 2 : width
    carousel.style.transform = `translateX(-${moveBy * slide}px)`
  }

  const nextSlide = (): void => {
    if (activeSlide < data.length - 2) {
      changeSlide(activeSlide + 1)
      moveSlider(activeSlide + 1)
    }
  }

  const prevSlide = (): void => {
    if (activeSlide > 0) {
      changeSlide(activeSlide - 1)
      moveSlider(activeSlide - 1)
    }
  }

  const setSlide = (i: number): void => {
    changeSlide(i)
    moveSlider(i)
  }

  const setClientX = e => {
    const clientX = e.touches[0].clientX
    setClientScroll(clientX)
  }

  const handleTouch = e => {
    if (e) {
      const touchStart = e.changedTouches[0].clientX
      const touchEnd = clientScroll

      //get end touch coordinates
      if (touchStart > touchEnd) {
        prevSlide(e)
      } else {
        nextSlide(e)
      }
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const width: number = document.getElementById("ba-carousel")?.clientWidth
      setContainerWidth(width)

      const resetSlider = () => {
        moveSlider(0)
        changeSlide(0)
      }
      window.addEventListener("resize", resetSlider)
      return () => {
        window.removeEventListener("resize", resetSlider)
      }
    }
  }, [])

  return (
    <Container>
      {heading && (
        <TitleContainer data-testid="carousel-heading-container">
          <SmallHeading heading="Before and After" marginBottom />
          <SectionHeading heading={heading} />
        </TitleContainer>
      )}
      <StandardSection noPaddingMob>
        <InnerContainer>
          <LeftArrow
            alt="left arrow"
            src={activeSlide !== 0 ? arrowGreen : arrowGrey}
            onClick={prevSlide}
          />
          <OuterContainer
            id="ba-container"
            onTouchStart={setClientX}
            onTouchEnd={handleTouch}
          >
            <ImageContainer id="ba-carousel">
              {data.map((bA: BeforeAfter, i: number) => {
                return (
                  <ImageBlock key={i} data-testid="carousel-ba">
                    <Image
                      containerWidth={containerWidth}
                      background={bA.before}
                    />
                    <Image
                      containerWidth={containerWidth}
                      background={bA.after}
                    />
                  </ImageBlock>
                )
              })}
            </ImageContainer>
          </OuterContainer>
          <RightArrow
            alt="right arrow"
            src={activeSlide !== data.length - 2 ? arrowGreen : arrowGrey}
            onClick={nextSlide}
          />
        </InnerContainer>
      </StandardSection>
      <ProgressContainer>
        <DotContainer>
          {data.map((_, i) => {
            if (i < data.length - 1) {
              return (
                <Dot
                  key={i}
                  isActive={i === activeSlide}
                  onClick={() => setSlide(i)}
                />
              )
            }
          })}
        </DotContainer>
      </ProgressContainer>
    </Container>
  )
}

const DotContainer = styled.div`
  display: flex;
  margin-top: 3rem;
  @media (max-width: 767px) {
    margin: 0 auto;
  }
`
interface IDot {
  isActive?: boolean
}

const LeftArrow = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 22px;
  z-index: 4;
  cursor: pointer;
  transform: rotate(180deg);
  @media (max-width: 767px) {
    width: 25px;
    height: 25px;
    margin-right: 0;
  }
`

const RightArrow = styled(LeftArrow)`
  margin-right: 0;
  margin-left: 22px;
  transform: rotate(0deg);
  @media (max-width: 767px) {
    margin-left: 0;
    margin-right: 0;
  }
`

const Dot = styled.div<IDot>`
  height: 11px;
  width: 11px;
  background-color: ${props =>
    props.isActive ? color.brand.blue : "transparent"};
  border: 1px solid
    ${props => (props.isActive ? color.brand.blue : color.brand.blue)};
  border-radius: 50%;
  margin-right: 7px;

  transition: all 0.5s ease;
  cursor: pointer;

  &:last-of-type {
    margin-right: 0;
  }
  @media (max-width: 767px) {
    height: 7px;
    width: 7px;
    margin-right: 5px;
  }
`

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1380px;
  margin: 0 auto;
  position: relative;

  @media (max-width: 1440px) {
    max-width: 1160px;
  }

  @media (max-width: 1199px) {
    // max-width: 728px;
  }

  @media (max-width: 767px) {
    width: calc(100vw - 10px);
  }
`

const TitleContainer = styled.div`
  margin-bottom: 5rem;
  text-align: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 5%;
  }
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem 0;
  background: ${color.background.lightGrey};

  @media (max-width: 767px) {
    padding: 50px 0;
  }
`

const ImageBlock = styled.div`
  display: flex;
  margin-right: 17px;
  flex: 1;

  & > div {
    flex: 1;
  }

  & > div:nth-of-type(1) {
    margin-right: 10px;

    @media (max-width: 767px) {
      margin-right: 2px;
    }
  }
  @media (max-width: 767px) {
    margin-right: 0;
    width: 100%;
    flex: auto;
    flex-shrink: 0;
  }
`

const Image = styled.div`
  height: 340px;
  width: ${props => (props.containerWidth - 17 / 2) / 4 - 10}px;

  @media (max-width: 1199px) {
    height: 300px;
  }

  @media (max-width: 767px) {
    height: 250px;
    width: 100%;
    flex-shrink: 0;
  }

  background-image: url(${props => props.background});
  background-position: center center;
  background-size: cover;
`

const ImageContainer = styled.div`
  display: flex;
  transition: all 1s ease;

  // @media (max-width: 767px) {
  //   & > div:nth-of-type(4n + 1) {
  //     display: none;
  //   }
  // }
`

const OuterContainer = styled.div`
  overflow-x: hidden;
  transition: all 0.2s ease;
  width: 100%;
  position: relative;
`
const ProgressContainer = styled.div`
  margin: 0 auto;
  margin-top: 2rem;
  @media (max-width: 767px) {
    padding: 0 25px;
  }
`
export default Carousel
