import React from "react"
import styled from "styled-components"
import color from "../../style/color"
import StandardSection from "../../helper/StandardSection"
import Button from "../../shared/Button/Button"

interface TwoColumnsTextSmallProps {
  sectionHeading: string
  text: string
  cta?: any
}

const TwoColumnsSmallSmile = ({
  sectionHeading,
  text,
  cta,
}: TwoColumnsTextSmallProps) => {
  return (
    <StandardSection>
      <Grid>
        <Column>
          {sectionHeading && (
            <div data-testid="heading-textTwoCols-container">
              <SectionHeading>{sectionHeading}</SectionHeading>
            </div>
          )}
        </Column>
        <Column>
          <Body
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
          {cta && (
            <BtnContainer>
              <Button text={cta.title} href={cta.uri} />
            </BtnContainer>
          )}
        </Column>
      </Grid>
    </StandardSection>
  )
}

export default TwoColumnsSmallSmile

const Body = styled.div`
  & h2 {
    font-family: Roboto;
    font-size: 2.2rem;
    line-height: 3.2rem;
    font-weight: 300;
    margin-bottom: 24px;
  }
`

const SectionHeading = styled.h3``

const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 6rem 0;
  padding-bottom: 12rem;
  width: 100%;

  @media (max-width: 1023px) {
    padding: 5rem 0;
  }
  @media (max-width: 1023px) {
    flex-direction: column;
  }

  @media (max-width: 767px) {
    padding: 40px 0 50px;
  }
`

const Column = styled.div`
  width: 46%;
  @media (max-width: 1200px) {
    width: 48%;
  }
  @media (max-width: 1023px) {
    width: 100%;
    &:nth-of-type(2) {
      margin-top: 40px;
    }
  }
`
const BtnContainer = styled.div`
  margin-top: 20px;
`
