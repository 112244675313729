import React, { useState, useEffect } from "react"
import Input from "./Input"
import TextArea from "./TextArea"
import Button from "../../shared/Button/Button"
import styled from "styled-components"
import Dropdown from "./CustomDropdown"
import {
  validateOnBlur,
  validatePhoneNumber,
  validateEmail,
  scrollPage,
  getPracticeEmail,
  trackButtonClick,
} from "../../util/functions"
import { updateGoogleSheet, getAuthToken } from "../../googleapis/api-hooks"
import { setItem, getItem } from "../../util/functions"
import TitleButtons from "./TitleButtons"
import Heading from "./Heading"
import Label from "./Label"
import emailjs from "emailjs-com"
import Loading from "./Loading"
import color from "../../style/color"

interface Option {
  label: string
  value: string
}

const FreeConsultationForm = ({ preferedClinics }) => {
  const [firstName, setFirstName] = useState(getItem("firstName"))
  const [lastName, setLastName] = useState(getItem("lastName"))
  const [firstNameError, togglefirstNameError] = useState(false)
  const [lastNameError, togglelastNameError] = useState(false)
  const [phone, setPhone] = useState(getItem("phone"))
  const [phoneError, togglePhoneError] = useState(false)
  const [email, setEmail] = useState(getItem("email"))
  const [emailError, toggleEmailError] = useState(false)
  const [anythingElse, setAnythingElse] = useState("")
  const [title, setTitle] = useState(getItem("title"))
  const [titleError, toggleTitleError] = useState(false)
  const [practiceError, togglePracticeError] = useState(false)
  const [token, setToken] = useState("")

  const [isLoading, toggleLoading] = useState(false)

  useEffect(() => {
    if (token) {
      if (typeof window !== "undefined" && typeof document !== "undefined") {
        toggleLoading(true)
        document.querySelector("body").style.overflow = "hidden"
        let practice = document.querySelector(
          "#free-consultation-form-dropdown1"
        ).innerText
        setItem("firstName", firstName)
        setItem("lastName", lastName)
        setItem("practice", practice)
        setItem("email", email)

        const params = {
          emailTo: getPracticeEmail(practice),
          message: `
          <p>Form: Invisalign Consultation</p>
          <p>Name: ${title} ${firstName} ${lastName}</p>
          <p>Preferred practice: ${practice}</p>
          <p>Phone: ${phone}</p>
          <p>Email: ${email}</p>
          <p>Message: ${anythingElse}</p>
          `,
        }

        emailjs
          .send(
            "gmail",
            process.env.EMAILJS_TEMPLATE,
            params,
            process.env.EMAILJS_ID
          )
          .then(
            result => {
              console.log(result.text)
            },
            error => {
              console.log(error.text)
            }
          )
          .then(() => {
            updateGoogleSheet(
              token,
              "FreeConsultation",
              [
                firstName + " " + lastName,
                phone,
                email,
                practice,
                anythingElse,
                window.location.href,
                new Date(),
              ],
              "consultation-form-confirmation"
            )
          })
      }
    }
  }, [token])

  const submitForm = () => {
    const goToNextScreen = () => {
      trackButtonClick(`Free Consultation Form Submission`)
      getAuthToken(setToken)
    }
    let practice = document.querySelector("#free-consultation-form-dropdown1")
      .innerText
    let idToScroll: string = ""

    let firstNameError: boolean = false
    let lastNameError: boolean = false
    let phoneError: boolean = false
    let emailError: boolean = false
    let practiceError: boolean = false
    let titleError: boolean = false

    if (!title) {
      titleError = true
      toggleTitleError(true)
      if (!idToScroll) {
        idToScroll = "title-container-consultation"
      }
    }
    if (firstName.length < 1) {
      firstNameError = true
      togglefirstNameError(true)
      if (!idToScroll) {
        idToScroll = "firstName"
      }
    }
    if (lastName.length < 1) {
      lastNameError = true
      togglelastNameError(true)
      if (!idToScroll) {
        idToScroll = "lastName"
      }
    }
    if (!validatePhoneNumber(phone)) {
      phoneError = true
      togglePhoneError(true)
      if (!idToScroll) {
        idToScroll = "phone"
      }
    }
    if (!validateEmail(email)) {
      emailError = true
      toggleEmailError(true)
      if (!idToScroll) {
        idToScroll = "email"
      }
    }
    if (practice === "Please select") {
      practiceError = true
      togglePracticeError(true)
      if (!idToScroll) {
        idToScroll = "practice"
      }
    }

    if (
      !firstNameError &&
      !lastNameError &&
      !phoneError &&
      !emailError &&
      !titleError &&
      !practiceError
    ) {
      goToNextScreen()
    } else {
      if (idToScroll) {
        scrollPage(idToScroll)
      }
    }
  }

  useEffect(() => {
    const practice = getItem("practice")
    if (practice) {
      document.querySelector(
        "#free-consultation-form-dropdown1"
      ).innerText = practice
    }
  }, [])

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <Form>
        <Heading heading="Tell us about yourself" />
        <Label label="Your title*" />

        <TitleButtons
          title={title}
          titleError={titleError}
          toggleTitleError={toggleTitleError}
          setTitle={setTitle}
          id="title-container-consultation"
        />
        <Flex>
          <Input
            value={firstName}
            onChange={setFirstName}
            label={"Your first name*"}
            isValidated={firstName.length > 0}
            hasError={firstNameError}
            errorTitle="Please enter your first name"
            onBlur={() =>
              validateOnBlur(firstName.length > 0, togglefirstNameError)
            }
            id="firstName"
            color={color.brand.primary}
          />
          <Gap />
          <Input
            value={lastName}
            onChange={setLastName}
            label={"Your last name*"}
            isValidated={lastName.length > 0}
            hasError={lastNameError}
            errorTitle="Please enter your last name"
            onBlur={() =>
              validateOnBlur(lastName.length > 0, togglelastNameError)
            }
            id="lastName"
            color={color.brand.primary}
          />
        </Flex>
        <Flex>
          <Input
            value={phone}
            onChange={phone => phone.length < 20 && setPhone(phone)}
            label={"Your contact number*"}
            isValidated={validatePhoneNumber(phone)}
            hasError={phoneError}
            errorTitle="Please enter a valid telephone number"
            onBlur={() =>
              validateOnBlur(validatePhoneNumber(phone), togglePhoneError)
            }
            id="phone"
            color={color.brand.primary}
          />
          <Gap />
          <Input
            value={email}
            onChange={setEmail}
            label={"Your email address*"}
            isValidated={validateEmail(email)}
            hasError={emailError}
            errorTitle="Please enter a valid email address"
            onBlur={() => {
              validateOnBlur(validateEmail(email), toggleEmailError)
            }}
            id="email"
            color={color.brand.primary}
          />
        </Flex>
        <Flex>
          <DropdownContainer>
            <Dropdown
              id="free-consultation-form-dropdown1"
              heading="Your preferred clinic*"
              placeholder="Please select"
              options={preferedClinics}
              color={color.brand.primary}
              hasError={practiceError}
              errorTitle="Please select preferred clinic"
              toggleDropdownError={togglePracticeError}
            />
          </DropdownContainer>
          <Gap />
          <EmptyBox />
        </Flex>

        <TextArea
          heading={"Your message"}
          value={anythingElse}
          onChange={setAnythingElse}
          color={color.brand.primary}
        />
        <BtnContainer>
          <Button
            text="Request free consultation"
            onClick={submitForm}
            primary
          />
        </BtnContainer>
      </Form>
    </Container>
  )
}

export default FreeConsultationForm

const Container = styled.div`
  padding-bottom: 8rem;
  @media (max-width: 767px) {
  }
`

const Form = styled.div`
  width: 92%;
  max-width: 995px;
  margin: 0 auto;
  padding-top: 7.7rem;

  & label {
    font-weight: 300;
  }
  @media (max-width: 767px) {
    padding: 7.7rem 25px 0;
    width: 100%;
  }
`

interface IRadioContainer {
  hasError: boolean
}

const RadioContainer = styled.div<IRadioContainer>`
  display: flex;
  padding-bottom: 1.6rem;
  padding-top: 8px;
  flex-wrap: wrap;

  & label {
    display: block;
  }
  & div {
    ${props =>
      props.hasError &&
      `
        border-color: #d0021b;
        color: #d0021b;
        `}
  }

  @media only screen and (max-width: 850px) {
    & > div {
      margin: 0 10px 10px 0;
      padding: 20px 29px;

      @media (max-width: 850px) {
        padding: 12px 30px;
      }
    }
  }
`
const Flex = styled.div`
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
const Gap = styled.div`
  width: 4.3rem;
  @media (max-width: 767px) {
    display: none;
  }
`
const DropdownContainer = styled.div`
  flex: 1;
  @media (max-width: 767px) {
  }
`
const BtnContainer = styled.div`
  width: 100%;
  margin-top: 7rem;
  display: flex;
  justify-content: center;
`
const EmptyBox = styled.div`
  flex: 1;
  @media (max-width: 767px) {
    display: none;
  }
`
