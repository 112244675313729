import React from "react"
import styled from "styled-components"
import color from "../../style/color"

interface LabelProps {
  label: string
}

const Label = ({ label }: LabelProps) => {
  return <Text>{label}</Text>
}

export default Label

const Text = styled.label`
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.6rem;
  margin-bottom: 8px;
  color: ${color.brand.blue};
  display: block;
  @media (max-width: 767px) {
    font-size: 2rem;
    line-height: 3rem;
  }
`
