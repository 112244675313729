import React from "react"
import MeetTheTeamFilter from "./MeetTheTeamFilter"
import { graphql } from "gatsby"

const MeetTheTeamFilterDrupal = ({ node }: any) => {
  const { field_practices } = node || {}
  return <MeetTheTeamFilter practices={["All", ...field_practices]} />
}

export const fragment = graphql`
  fragment MeetTheTeamFilter on paragraph__meet_the_team_filter {
    id
    field_practices
  }
`

export default MeetTheTeamFilterDrupal
