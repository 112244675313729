import React from "react"
import styled from "styled-components"

interface Section {
  children: any
  background?: string
  noPaddingBottomMob?: boolean
  noPaddingBottomTablet?: boolean
}
const PaddingTop = ({
  children,
  background,
  noPaddingBottomMob,
  noPaddingBottomTablet,
}: Section) => {
  return (
    <Section
      background={background}
      noPaddingBottomMob={noPaddingBottomMob}
      noPaddingBottomTablet={noPaddingBottomTablet}
    >
      {children}
    </Section>
  )
}

interface ISection {
  background?: string
  noPaddingBottomMob?: boolean
  noPaddingBottomTablet?: boolean
}
const Section = styled.div<ISection>`
  padding: 10rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.background && `background: ${props.background};`}
  @media (max-width: 1023px) {
    padding: 50px 0;
    ${props => props.noPaddingBottomTablet && `padding-bottom: 0;`}
  }

  @media (max-width: 767px) {
    ${props => props.noPaddingBottomMob && `padding-bottom: 0;`}
  }
`

export default PaddingTop
