import React from "react"
import Table from "../Table/InvisalignTable"
import { graphql } from "gatsby"

const InvisalignTableDrupal = ({ node }) => {
  const { heading, smallHeading, fixedBrace, full, i7, lite, lingual } =
    node || {}
  const stickyHeading = [
    { title: "Invisalign® Braces" },
    { title: "Traditional Braces" },
  ]

  const rows = [
    ["Brace type", "Removable", "Removable", "Removable", "Fixed", "Fixed"],
    [
      "Smile time",
      "12 months",
      "6 months",
      "3 months",
      "18 months",
      "18 months",
    ],
    [
      "Wear time",
      "22hrs/day",
      "22hrs/day",
      "22hrs/day",
      "24hrs/day",
      "24hrs/day",
    ],
    ["Price from", full, lite, i7, lingual, fixedBrace],
  ]

  const stickySubHeading = [
    {
      title1: "Invisalign® Full",
      title2: "Invisalign® Lite",
      title3: "Invisalign® i7",
    },
    { title1: "Lingual brace", title2: "Fixed brace" },
  ]

  return (
    <Table
      heading={heading}
      smallHeading={smallHeading}
      stickySubHeading={stickySubHeading}
      stickyHeading={stickyHeading}
      rows={rows}
    />
  )
}

export default InvisalignTableDrupal

export const fragment = graphql`
  fragment InvisalignTable on paragraph__invisalign_table {
    id
    heading: field_section_heading
    smallHeading: field_top_text
    fixedBrace: field_fixed_brace
    full: field_invisalign_full_price
    i7: field_invisalign_i7_price
    lite: field_invisalign_lite_price
    lingual: field_lingual_brace
  }
`
