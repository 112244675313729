import React from "react"
import GreyHeader from "./FormHeadingGrey"
import { graphql, useStaticQuery } from "gatsby"
import { get } from "../../util/functions"

const GreyHeaderDrupal = ({ node }: any) => {
  const {
    field_section_heading,
    field_section_body,
    field_breadcrumb,
    field_grey_background,
  } = node || {}

  return (
    <GreyHeader
      heading={field_section_heading}
      text={field_section_body}
      breadcrumb={field_breadcrumb}
      greyBackgroundBreadcrumb={field_grey_background}
    />
  )
}

export const fragment = graphql`
  fragment GreyHeader on paragraph__grey_header {
    id
    field_section_heading
    field_grey_background
    field_section_body
    field_breadcrumb {
      title
      uri
    }
  }
`

export default GreyHeaderDrupal
