import React from "react"
import styled from "styled-components"
import circle from "../../images/loading-circle.svg"
import spinner from "../../images/loading-spinner.svg"
import color from "../../style/color"

const Loading = () => {
  return (
    <Container>
      <div>
        <Loader>
          <img src={circle} />
          <img src={spinner} />
        </Loader>
        <p>We're submitting your enquiry</p>
      </div>
    </Container>
  )
}

const Loader = styled.div`
  position: relative;
  height: 150px;

  @media only screen and (max-width: 850px) {
    height: 86px;
  }

  & img {
    top: 0;
    left: 32%;
    position: absolute;
    height: 150px;
    @media only screen and (max-width: 850px) {
      height: 86px;
    }

    @keyframes spin {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
    animation: spin 1s linear;
    animation-iteration-count: infinite;

    &:nth-of-type(1) {
      opacity: 0.5;
    }
  }
`

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: ${color.brand.blue};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  & p {
    margin-top: 20px;
    font-size: 3rem;
    color: white;
    font-weight: 300;

    @media only screen and (max-width: 850px) {
      margin-bottom: 15px;
    }
  }
`

export default Loading
