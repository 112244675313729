import React from "react"
import styled from "styled-components"
import color from "../../../style/color"

interface FirstSectionProps {
  children: any
}

const FirstSection = ({ children }: FirstSectionProps) => {
  return <Container>{children}</Container>
}

export default FirstSection

const Container = styled.div`
  padding: 7.7rem 0 0;
  margin: 0 auto;
  background: white;
  @media (max-width: 767px) {
    width: 100%;
    padding: 5rem 0 0;
  }
`
