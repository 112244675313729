import styled from "styled-components"
import color from "./color"

export const OrangeLink = styled.a`
  color: ${color.brand.orange};
  font-size: inherit;
  cursor: pointer;
  word-wrap: break-word;
  &::after {
    display: block;
    content: " ";
    height: 2px;
    position: relative;
    bottom: 2px;
    background: ${props => (props.color ? props.color : color.brand.orange)};
    transform: scale(0);
    transition: transform 0.4s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.3s,
      background-color 0.1s;
  }

  &:hover::after,
  &:active::after {
    transform: scale(1);
    width: 100%;
  }

  &:active {
    color: ${color.brand.orange};
  }
`

export const mainGridDesktop = "1280px"
