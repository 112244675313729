import React from "react"
import styled from "styled-components"

interface Section {
  children: any
  noPaddingMob?: boolean
}
const SmallSection = ({ children, noPaddingMob }: Section) => {
  return <Section noPaddingMob={noPaddingMob}>{children}</Section>
}

interface ISection {
  noPaddingMob?: boolean
}
const Section = styled.div<ISection>`
  display: flex;
  width: 90%;
  max-width: 1120px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  @media (max-width: 1199px) {
    width: 100%;
    padding: 0 3%;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 25px;
    ${props => props.noPaddingMob && `padding: 0;`}
  }
`

export default SmallSection
