import React from "react"
import styled from "styled-components"
import color from "../../../style/color"
import StepNumber from "./StepNumber"
import Pill from "../../../shared/Pill/Pill"

interface StepProp {
  heading?: string
  text?: string
  period?: string
  stepNumber?: number
  week?: string
}
const Step = ({ heading, text, period, stepNumber, week }: StepProp) => {
  return (
    <Container data-testid="step">
      <StepDetails>
        {stepNumber && (
          <TopDetails>
            {stepNumber && <StepNumber stepNumber={stepNumber} />}

            {week && (
              <PillContainer>
                <DentalPill>{week}</DentalPill>
              </PillContainer>
            )}
          </TopDetails>
        )}

        {heading && <StepHeading>{heading}</StepHeading>}
        {text && <Text>{text}</Text>}
      </StepDetails>

      {period && (
        <StepPeriod>
          <DentalPill>{period}</DentalPill>
        </StepPeriod>
      )}
    </Container>
  )
}

export default Step

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0;
  z-index: 7;
  position: relative;
  @media (max-width: 1199px) {
    height: auto;
    padding: 60px 0 80px;
    padding: 140px 0 160px;
  }
`

const StepDetails = styled.div`
  padding: 40px 0;
  width: 100%;
  background: ${color.background.white};

  @media (max-width: 767px) {
    padding: 20px 0;
  }
`
const TopDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  margin: 5px 0 20px;
`
const Week = styled.div`
  background: rgba(8, 37, 72, 0.6);
  color: #fff;
  border-radius: 50px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
`

export const StepHeading = styled.h5``

export const Text = styled.p`
  margin: 15px 0 20px;
  color: ${color.brand.secondary};
  @media (max-width: 1199px) {
    margin-bottom: 0;
  }
`

const StepPeriod = styled.div`
  position: absolute;
  bottom: 0;
  font-size: 14px;
  line-height: 28px;
`
const PillContainer = styled.div`
  margin-top: 20px;
`
const DentalPill = styled(Pill)`
  font-size: 14px;
  background-color: ${color.brand.green};
  color: ${color.brand.blue};
  height: 20px;
`
