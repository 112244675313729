import React from "react"
import { graphql } from "gatsby"
import { get } from "../../util/functions"
import color from "../../style/color"
import styled from "styled-components"
import Button from "../../shared/Button/Button"
import StandardSection from "../../helper/StandardSection"
import SmallHeading from "../../shared/SmallHeading/SmallHeading"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"
import Img from "gatsby-image"
import { P4 } from "../../helper/Paragraph"

interface IRow {
  heading: string
  subHeading?: string
  ctaText?: string
  ctaUrl?: string
  flip?: boolean
  text?: string
  imageDesktop?: string
  imageMobile?: string
  pillText?: string
  altDesktop?: string
  altMobile?: string
}

interface IMainContainer {
  flip?: boolean
}
interface IImage {
  imageDesktop?: string
  imageMobile?: string
}
interface IHalfAndHalf {
  data: Array<IRow>
}
const HalfAndHalf = ({ data }: IHalfAndHalf) => {
  return (
    <>
      {data &&
        data.map((row: any, key: number) => {
          return (
            <Outer greyBackground={row.greyBackground} key={key}>
              <StandardSection noPaddingMob>
                <MainContainer flip={row.flip} data-testid="halfAndHalf">
                  <TextSection flip={row.flip}>
                    <InnerTextContainer>
                      {row.pillText && (
                        <TopText>
                          <SmallHeading heading={row.pillText} leftAlign />
                        </TopText>
                      )}
                      {row.heading && (
                        <div data-testid="heading-half-container">
                          <SectionHeading>{row.heading}</SectionHeading>
                        </div>
                      )}
                      {row.subHeading && (
                        <SubHeading>{row.subHeading}</SubHeading>
                      )}
                      {row.text && <SectionBody>{row.text}</SectionBody>}
                      {row.ctaText && (
                        <ButtonContainer>
                          <Button href={row.ctaUrl} text={row.ctaText} />
                        </ButtonContainer>
                      )}
                    </InnerTextContainer>
                  </TextSection>
                  <HideOverflow>
                    <Controller>
                      <Scene duration="100%">
                        <Timeline wrapper={<div className="parallax" />}>
                          <Tween
                            position="0"
                            from={{
                              scale: 1,
                            }}
                            to={{
                              scale: 1.05,
                            }}
                          >
                            <ImgWrapper>
                              {row.sources && (
                                <Image
                                  fluid={row.sources}
                                  alt={row.altDesktop}
                                  placeholderStyle={{ opacity: 0 }}
                                />
                              )}
                            </ImgWrapper>
                          </Tween>
                        </Timeline>
                      </Scene>
                    </Controller>
                  </HideOverflow>
                </MainContainer>
              </StandardSection>
            </Outer>
          )
        })}
    </>
  )
}

interface IOuter {
  greyBackground?: boolean
}

const Outer = styled.div<IOuter>`
  background: ${props => props.greyBackground && color.background.lightGrey};
  @media (max-width: 767px) {
    background: white;
  }
`

const MainContainer = styled.div<IMainContainer>`
  position: relative;
  display: flex;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  flex-direction: ${props => props.flip && "row-reverse"};
  justify-content: flex-end;
  @media (max-width: 767px) {
    width: 100%;
    flex-direction: column-reverse;
    padding: 0 0 50px;
  }
`
const TextSection = styled.div<IMainContainer>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 7.5rem 0 10.5rem;
  ${props => (props.flip ? "margin-left: 8.3rem;" : "margin-right: 8.3rem;")}
  z-index: 3;
  width: 29vw;
  max-width: 41.4rem;
  backface-visibility: hidden;

  @media (max-width: 1023px) {
    padding: 7rem 0;
    width: 60vw;
  }
  @media (max-width: 767px) {
    position: relative;
    width: 100%;
    max-width: 100%;
    min-height: 50px;
    margin: 0;
    padding: 40px 25px 0;
  }
`
const HideOverflow = styled.div`
  width: 54rem;
  height: 70rem;
  overflow: hidden;
  margin: 10rem 0;
  position: relative;
  & div {
    height: 100%;
  }

  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    margin: 0;
    height: 40rem;
  }
`
const ImgWrapper = styled.div`
  transition: all 0.7s ease;
`

const Image = styled(Img)`
  display: block;
  height: 100%;
  max-height: 67rem;
  width: 100%;
  object-fit: cover;
  backface-visibility: hidden;
  overflow: hidden;
  backface-visibility: hidden;
  will-change: transform;
`

const TopText = styled.div`
  margin-bottom: 3.6rem;
  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
`
const SectionHeading = styled.h2`
  margin-bottom: 2.4rem;
  width: 90%;
  @media (max-width: 767px) {
    margin-bottom: 20px;
    width: 100%;
  }
`

const SubHeading = styled.p`
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 30px;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 10px;
  }
`

const SectionBody = styled.p`
  margin-bottom: 2.2rem;
  @media (max-width: 767px) {
    margin-bottom: 13px;
  }
`

const InnerTextContainer = styled.div`
  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }
`
const ButtonContainer = styled.div`
  width: 100%;
  max-width: 210px;
`
export default HalfAndHalf
