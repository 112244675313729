import React from "react"
import AboutUsHeader from "./AboutUsHeader"
import { graphql } from "gatsby"

const AboutUsHeaderDrupal = ({ node }: any) => {
  const { sectionHeading, alt, mAlt, relationships, breadcrumb } = node || {}
  const { image, mImage } = relationships || {}
  return (
    <AboutUsHeader
      name={sectionHeading}
      image={image.localFile.publicURL}
      mobileImage={mImage.localFile.publicURL}
      imageAlt={alt.alt}
      mobileImageAlt={mAlt.alt}
      breadcrumb={breadcrumb}
    />
  )
}

export default AboutUsHeaderDrupal

export const fragment = graphql`
  fragment AboutUsHeader on paragraph__about_us_header {
    id
    sectionHeading: field_section_heading
    breadcrumb: field_breadcrumb {
      uri
      title
    }
    alt: field_image_desktop {
      alt
    }
    mAlt: field_image_mobile {
      alt
    }
    relationships {
      image: field_image_desktop {
        localFile {
          publicURL
        }
      }
      mImage: field_image_mobile {
        localFile {
          publicURL
        }
      }
    }
  }
`
