import React, { useState, useEffect } from "react"
import Input from "./Input"
import TextArea from "./TextArea"
import Button from "../../shared/Button/Button"
import styled from "styled-components"
import color from "../../style/color"
import { setItem, getItem, getPracticeEmail } from "../../util/functions"
import Checkbox from "./Checkbox"
import Inner from "./Layout/Inner"
import {
  validateOnBlur,
  validatePhoneNumber,
  validateEmail,
  scrollPage,
  trackButtonClick,
} from "../../util/functions"
import { updateGoogleSheet, getAuthToken } from "../../googleapis/api-hooks"
import UploadInput from "./UploadInput"
import Heading from "./Heading"
import TitleButtons from "./TitleButtons"
import Label from "./Label"
import SmileSteps from "./SmileSteps"
import Loading from "./Loading"
import emailjs from "emailjs-com"

interface Option {
  label: string
  value: string
}

const SendASmileForm = () => {
  const [firstName, setFirstName] = useState(getItem("firstName"))
  const [lastName, setLastName] = useState(getItem("lastName"))
  const [firstNameError, togglefirstNameError] = useState(false)
  const [lastNameError, togglelastNameError] = useState(false)
  const [nameError, toggleNameError] = useState(false)
  const [phone, setPhone] = useState(getItem("phone"))
  const [phoneError, togglePhoneError] = useState(false)
  const [email, setEmail] = useState(getItem("email"))
  const [emailError, toggleEmailError] = useState(false)
  const [anythingElse, setAnythingElse] = useState("")
  const [title, setTitle] = useState(getItem("title"))
  const [titleError, toggleTitleError] = useState(false)
  const [practice, setPractice] = useState("")
  const [practiceError, togglePracticeError] = useState(false)
  const [concern, setConcern] = useState("")
  const [concernList, editConcernList] = useState([])

  const [isLoading, toggleLoading] = useState(false)
  const [fileArray, editFileArray] = useState([])

  const [token, setToken] = useState("")

  const updateConcernList = (treatment: string): void => {
    let index: number = concernList.indexOf(treatment)
    if (index === -1) {
      editConcernList([...concernList, treatment])
    } else {
      let list: Array<string> = [...concernList]
      list.splice(index, 1)
      editConcernList(list)
    }
  }

  useEffect(() => {
    if (token) {
      toggleLoading(true)
      document.querySelector("body").style.overflow = "hidden"

      const sendFormData = async () => {
        if (fileArray.length > 0) {
          Promise.all(
            Array.from(fileArray).map(file => {
              let data = new FormData()
              data.append("upload_preset", "dental")
              data.append("file", file)
              data.append("cloud_name", "dnsepqj3v")

              return fetch(
                `https://api.cloudinary.com/v1_1/dnsepqj3v/image/upload`,
                {
                  method: "POST",
                  body: data,
                }
              )
                .then(resp => resp.json())
                .then(data => data.secure_url)
            })
          ).then(images => {
            const imageString = () => {
              console.log(images.join(" <br />"))
              return images.join(" <br />")
            }

            setItem("firstName", firstName)
            setItem("lastName", lastName)
            setItem("concern", concernList.join(", "))

            const params = {
              emailTo: getPracticeEmail(practice),
              message: `
                <p>Form: Invisalign Smile Assessment</p>
                <p>Name: ${title} ${firstName} ${lastName}</p>
                <p>Phone: ${phone}</p>
                <p>Email: ${email}</p>
                <p>Concerns about their smile: ${concernList.join(", ")}</p>
                <p>Images :</p>
                <div>
                ${imageString()}
                </div>
                `,
            }

            emailjs
              .send(
                "gmail",
                process.env.EMAILJS_TEMPLATE,
                params,
                process.env.EMAILJS_ID
              )
              .then(
                result => {
                  console.log(result.text)
                },
                error => {
                  console.log(error.text)
                }
              )
              .then(() => {
                updateGoogleSheet(
                  token,
                  "SendASmile",
                  [
                    firstName + " " + lastName,
                    phone,
                    email,
                    concernList.join(", "),
                    window.location.href,
                    new Date(),
                  ],
                  "smile-form-confirmation"
                )
              })
          })
        } else {
          setItem("firstName", firstName)
          setItem("lastName", lastName)
          setItem("concern", concernList.join(", "))

          const params = {
            emailTo: getPracticeEmail(practice),
            message: `
              <p>Form: Invisalign Smile Assessment</p>
              <p>Name: ${title} ${firstName} ${lastName}</p>
              <p>Phone: ${phone}</p>
              <p>Email: ${email}</p>
              <p>Concerns about their smile: ${concernList.join(", ")}</p>
              `,
          }

          emailjs
            .send(
              "gmail",
              process.env.EMAILJS_TEMPLATE,
              params,
              process.env.EMAILJS_ID
            )
            .then(
              result => {
                console.log(result.text)
              },
              error => {
                console.log(error.text)
              }
            )
            .then(() => {
              updateGoogleSheet(
                token,
                "SendASmile",
                [
                  firstName + " " + lastName,
                  phone,
                  email,
                  concernList.join(", "),
                  window.location.href,
                  new Date(),
                ],
                "smile-form-confirmation"
              )
            })
        }
      }
      sendFormData()
    }
  }, [token])

  const submitForm = () => {
    const goToNextScreen = () => {
      trackButtonClick(`Send A Smile Form Submission`)
      getAuthToken(setToken)
    }

    let idToScroll: string = ""

    let titleError: boolean = false
    let firstNameError: boolean = false
    let lastNameError: boolean = false
    let phoneError: boolean = false
    let emailError: boolean = false
    let practiceError: boolean = false

    if (!title) {
      titleError = true
      toggleTitleError(true)
      if (!idToScroll) {
        idToScroll = "smile-form-title"
      }
    }
    if (firstName.length < 1) {
      firstNameError = true
      togglefirstNameError(true)
      if (!idToScroll) {
        idToScroll = "firstName"
      }
    }
    if (lastName.length < 1) {
      lastNameError = true
      togglelastNameError(true)
      if (!idToScroll) {
        idToScroll = "lastName"
      }
    }

    if (!validatePhoneNumber(phone)) {
      phoneError = true
      togglePhoneError(true)
      if (!idToScroll) {
        idToScroll = "phone"
      }
    }
    if (!validateEmail(email)) {
      emailError = true
      toggleEmailError(true)
      if (!idToScroll) {
        idToScroll = "email"
      }
    }
    if (practice === "Please select") {
      practiceError = true
      togglePracticeError(true)
      if (!idToScroll) {
        idToScroll = "smile-form-dropdown1"
      }
    }

    if (
      !titleError &&
      !firstNameError &&
      !lastNameError &&
      !phoneError &&
      !emailError
    ) {
      goToNextScreen()
    } else {
      if (idToScroll) {
        scrollPage(idToScroll)
      }
    }
  }

  const [fileList, editFileList] = useState([])

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <SmileSteps />
      <Form>
        <SecondSection>
          <Inner>
            <Heading heading="Tell us about yourself" />
            <Label label="Your title*" />

            <TitleButtons
              title={title}
              titleError={titleError}
              toggleTitleError={toggleTitleError}
              setTitle={setTitle}
              id="smile-form-title"
            />

            <Flex>
              <Input
                value={firstName}
                onChange={setFirstName}
                label={"Your first name*"}
                isValidated={firstName.length > 0}
                hasError={firstNameError}
                errorTitle="Please enter your first name"
                onBlur={() =>
                  validateOnBlur(firstName.length > 0, togglefirstNameError)
                }
                id="firstName"
                color={color.brand.primary}
              />
              <Gap />
              <Input
                value={lastName}
                onChange={setLastName}
                label={"Your last name*"}
                isValidated={lastName.length > 0}
                hasError={lastNameError}
                errorTitle="Please enter your last name"
                onBlur={() =>
                  validateOnBlur(lastName.length > 0, togglelastNameError)
                }
                id="lastName"
                color={color.brand.primary}
              />
            </Flex>
            <Flex>
              <Input
                value={phone}
                onChange={phone => phone.length < 20 && setPhone(phone)}
                label={"Your contact number*"}
                isValidated={validatePhoneNumber(phone)}
                hasError={phoneError}
                errorTitle="Please enter a valid telephone number"
                onBlur={() =>
                  validateOnBlur(validatePhoneNumber(phone), togglePhoneError)
                }
                id="phone"
                color={color.brand.primary}
              />
              <Gap />
              <Input
                value={email}
                onChange={setEmail}
                label={"Your email address*"}
                isValidated={validateEmail(email)}
                hasError={emailError}
                errorTitle="Please enter a valid email address"
                onBlur={() => {
                  validateOnBlur(validateEmail(email), toggleEmailError)
                }}
                id="email"
                color={color.brand.primary}
              />
            </Flex>
            <Label label="What are your main concerns about your smile?  (Choose as many as you like)" />
            <CheckboxContainer>
              <Checkbox onClick={updateConcernList} text="Gaps in my teeth" />
              <Checkbox onClick={updateConcernList} text="Crooked teeth" />
              <Checkbox onClick={updateConcernList} text="Sticking out teeth" />
              <Checkbox onClick={updateConcernList} text="Black tooth" />
              <Checkbox onClick={updateConcernList} text="Worn tooth" />
              <Checkbox onClick={updateConcernList} text="Discolored teeth" />
              <Checkbox onClick={updateConcernList} text="Missing teeth" />
              <Checkbox onClick={updateConcernList} text="Gummy smile" />
              <Checkbox onClick={updateConcernList} text="Bleeding gums" />
              <Checkbox onClick={updateConcernList} text="Other" />
            </CheckboxContainer>
            <Center>
              <UploadInput
                id="smile-form-upload1"
                heading="Please upload up to 4 images"
                uploadLimit={4}
                fileList={fileList}
                editFileList={editFileList}
                editFileArray={editFileArray}
                imageList={fileArray}
              />

              <BtnContainer>
                <Button text="submit assessment" onClick={submitForm} primary />
              </BtnContainer>

              <LabelContainer>
                <Label label="Upload your documents as jpg, png, or gif formats." />
              </LabelContainer>
            </Center>
          </Inner>
        </SecondSection>
      </Form>
    </Container>
  )
}

export default SendASmileForm

const LabelContainer = styled.div`
  margin-top: 2rem;
`

const H3 = styled.h3``

const Container = styled.div`
  padding-bottom: 10rem;
  padding-top: 7.7rem;

  @media (max-width: 1023px) {
    padding-top: 0;
  }
  @media (max-width: 767px) {
    padding-bottom: 8rem;
  }
`

const Form = styled.div`
  & label {
    font-weight: 300;
  }
  @media (max-width: 767px) {
  }
`
const FirstSecond = styled.div`
  padding: 7.7rem 0;
  & h3 {
    margin-bottom: 2rem;
    width: 100%;
    max-width: 77rem;
  }

  & p {
    font-weight: 300;
    width: 100%;
    max-width: 83rem;
  }
`

const SecondSection = styled.div`
  padding-top: 6rem;
`

const Flex = styled.div`
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const Gap = styled.div`
  width: 4.3rem;
  @media (max-width: 767px) {
    display: none;
  }
`

const Center = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 7rem 0 9rem;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  background: ${color.background.lightGrey};
  @media (max-width: 767px) {
    width: auto;
    padding: 7rem 25px 5rem;
  }
`
const BtnContainer = styled.div`
  width: 100%;
  margin-top: 6rem;
  display: flex;
  justify-content: center;
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5rem;
  padding-top: 8px;
`
