import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Button from "../../shared/Button/Button"
import { get } from "../../util/functions"
import "./blogPosts.css"
import VerticalPadding from "../../helper/VerticalPadding"
import { scrollTo } from "../../util/functions"
import { AnchorBar } from "../../style/scrollTo"
import styled from "styled-components"

const Press = ({ node }) => {
  const { relationships } = node || {}
  const { press } = relationships || {}

  const desktopLimit = 120
  const mobileLimit = 80
  const headingCharLimit = 72
  const postLimit = 9

  const [charLimit, setCharLimit] = useState(desktopLimit)
  const [headingLimit, setHeadingLimit] = useState(headingCharLimit)

  const checkCharLimit = () => {
    if (typeof window !== "undefined") {
      if (window.innerWidth < 1200 && window.innerWidth >= 768) {
        setCharLimit(mobileLimit)
      } else {
        setCharLimit(desktopLimit)
      }
    }
  }

  useEffect(() => {
    checkCharLimit()

    if (typeof window !== "undefined") {
      window.addEventListener("resize", checkCharLimit)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", checkCharLimit)
      }
    }
  }, [])

  const posts = [...press]

  const formatHeading = text => {
    if (text.length < headingLimit) {
      return text
    } else {
      return text.substr(0, headingLimit) + "..."
    }
  }
  const formatText = text => {
    if (text.length < charLimit) {
      return text
    } else {
      return text.substr(0, charLimit) + "..."
    }
  }
  const [showMore, toggleShowMore] = useState(false)
  const id = "blog-posts" + Math.random()
  return (
    <VerticalPadding>
      <div>
        <BlogContainer>
          <AnchorBar id={id} />

          <PostContainer>
            {posts.map((post, i) => {
              if (i < postLimit || showMore) {
                const imageAlt = get(["field_image_desktop", "alt"], post, "")
                const image = get(
                  [
                    "relationships",
                    "field_image_desktop",
                    "localFile",
                    "publicURL",
                  ],
                  post,
                  ""
                )

                return (
                  <Post key={i} i={i} href={post.field_cta.uri} target="_blank">
                    <PostImageContainer>
                      <img src={image} alt={imageAlt} />
                    </PostImageContainer>
                    <PostTextContainer>
                      <h3>{formatHeading(post.field_section_heading)}</h3>
                      <p>{formatText(post.field_section_body)}</p>
                      <PostCtaContainer>
                        <p href={post.field_cta.uri} target="_blank">
                          {post.field_cta.title}
                        </p>
                      </PostCtaContainer>
                    </PostTextContainer>
                  </Post>
                )
              }
            })}
          </PostContainer>
          {posts.length >= postLimit && (
            <ShowMoreContainer>
              <Button
                type="secondary"
                text={showMore ? "Show less" : "Show more"}
                onClick={() => {
                  toggleShowMore(!showMore)
                  if (showMore) {
                    scrollTo(id)
                  }
                }}
              />
            </ShowMoreContainer>
          )}
        </BlogContainer>
      </div>
    </VerticalPadding>
  )
}

export default Press

const Body = styled.p`
  margin-top: 1.7rem;
`
const BlogContainer = styled.div``

const TextContainer = styled.div`
  margin-bottom: 7.2rem;

  text-align: center;

  @media only screen and (max-width: 767px) {
    margin-bottom: 32px;
  }
`
const PostContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 92%;
  max-width: 1200px;

  @media (max-width: 1023px) {
    width: auto;
    padding: 0 25px;
  }
`
interface IPost {
  i: number
}
const Post = styled.a<IPost>`
  &:link,
  &:visited {
    text-decoration: none;
  }

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: calc(100% / 3 - 20px);
  margin-right: 30px;
  margin-bottom: 30px;
  &:nth-of-type(3n) {
    margin-right: 0;
  }

  // cta container
  &:nth-of-type(7n + 1) > div:nth-child(2) > div {
    max-width: 300px;
    margin-top: 0;
  }
  @media (max-width: 1023px) {
    width: calc(100% / 3 - 10px);
    margin-right: 15px;
    margin-bottom: 15px;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
`
const PostImageContainer = styled.div`
  width: 100%;
  height: 220px;

  & img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  @media (max-width: 1023px) {
    height: 160px;
  }
  @media (max-width: 767px) {
    height: 215px;
  }
`

const PostTextContainer = styled.div`
  background-color: #f8f8f8;
  padding: 33px 30px 40px;
  display: flex;
  flex-direction: column;
  height: 100%;

  & p {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    margin-bottom: 22px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    @media (max-width: 767px) {
      margin-bottom: 13px;
    }
  }

  & h3 {
    font-size: 20px;
    font-family: Raleway;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  @media (max-width: 1023px) {
    padding: 20px 25px 28px;
  }
`
const PostCtaContainer = styled.div`
  width: 100%;
  align-self: flex-start;
  display: flex;

  & p {
    font-size: 16px;
    line-height: 26px;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
    }
  }

  @media (max-width: 767px) {
    max-width: 300px;
  }
`
const ShowMoreContainer = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  margin-top: 30px;

  @media (max-width: 767px) {
    padding: 0 25px;
    margin-top: 10px;
  }
`

export const fragment = graphql`
  fragment Press on paragraph__press_section {
    id
    relationships {
      press: field_press_page {
        field_section_heading
        field_section_body
        field_cta {
          uri
          title
        }
        alt: field_image_desktop {
          alt
        }
        relationships {
          field_image_desktop {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
