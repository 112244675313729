import React, { useEffect } from "react"
import styled from "styled-components"
import SectionHeading from "../../shared/SectionHeading/SectionHeading"
import PaddingTop from "../../helper/VerticalPadding"
import color from "../../style/color"
import SmallHeading from "../../shared/SmallHeading/SmallHeading"

interface IProduct {
  title: string
  pound: number
  pence: number
  perWeek: string
}

interface IStickyHeading {
  title: string
}

interface IStickySubHeading {
  title1?: string
  title2?: string
  title3?: string
}

interface ProductCardProps {
  last?: boolean
  mostPopular?: boolean
  smallHeading?: string
  heading?: string
  stickyHeading?: Array<IStickyHeading>
  stickySubHeading?: Array<IStickySubHeading>
  rows?: Array<any>
}
const ProductTable = ({
  smallHeading,
  heading,
  stickyHeading,
  stickySubHeading,
  rows,
}: ProductCardProps) => {
  useEffect(() => {
    const checkSamsungInternetBrowser = () => {
      if (typeof window !== "undefined" && typeof document !== "undefined") {
        const anchor: HTMLElement | null = document.getElementById("table")
        const isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i)

        if (anchor && isSamsungBrowser) {
          anchor.style.top = "200px"
        }
      }
    }
    checkSamsungInternetBrowser()
  }, [])
  return (
    <PaddingTop background={"white"}>
      <Container multipleColumns={stickyHeading.length > 2}>
        <TopContent>
          {/* <Subheading>{smallHeading}</Subheading> */}
          <SmallHeading heading={smallHeading} marginBottom />
          <SectionHeading heading={heading} />
        </TopContent>
        <Table
          multipleColumns={stickyHeading.length > 2}
          data-testid="table-test"
        >
          <TopRow>
            <InvisalignHeading>
              <div></div>

              {stickyHeading &&
                stickyHeading.map((item: any, key: number) => {
                  return (
                    <TableHeading
                      key={key}
                      multipleColumns={stickyHeading.length > 2}
                    >
                      {item.title}
                    </TableHeading>
                  )
                })}
            </InvisalignHeading>

            {stickySubHeading && (
              <TraditionalHeading>
                <div></div>
                <div>{stickySubHeading[0].title1}</div>
                <div>{stickySubHeading[0].title2}</div>
                <div>{stickySubHeading[0].title3}</div>
                <div>{stickySubHeading[1].title1}</div>
                <div>{stickySubHeading[1].title2}</div>
              </TraditionalHeading>
            )}
          </TopRow>

          {rows &&
            rows.map((row: Array<string>, key: number) => {
              return (
                <Row
                  key={key}
                  multipleColumns={row.length > 3}
                  noGreyBackground={row.length <= 3}
                >
                  {row.map((item, i) => {
                    return <div key={i}>{item}</div>
                  })}
                </Row>
              )
            })}
        </Table>
        <ProductsMobile multipleColumns={stickyHeading.length > 2}>
          <MobileInner multipleColumns={stickyHeading.length > 2}>
            <TableMobile>
              <TopRow multipleColumns={stickyHeading.length > 2}>
                <TopRowSticky>
                  {stickyHeading &&
                    stickyHeading.map((item, key) => {
                      return (
                        <TableHeading
                          multipleColumns={stickyHeading.length > 2}
                          key={key}
                        >
                          {item.title}
                        </TableHeading>
                      )
                    })}
                </TopRowSticky>

                {stickySubHeading && (
                  <MobSubHeading>
                    {stickySubHeading.map((item, key) => {
                      return (
                        <React.Fragment key={key}>
                          <div>{item.title1}</div>
                          <div>{item.title2}</div>
                          {item.title3 && <div>{item.title3}</div>}
                        </React.Fragment>
                      )
                    })}
                  </MobSubHeading>
                )}
              </TopRow>

              {rows &&
                rows.map((row: Array<string>, key: number) => {
                  return (
                    <div key={key}>
                      <MobileHeading>{row[0]}</MobileHeading>
                      <Row
                        multipleColumns={row.length > 5}
                        noGreyBackground={row.length <= 3}
                      >
                        {row.map((item, i) => {
                          return (
                            i > 0 && (
                              <TableCell
                                key={i}
                                multipleColumns={row.length > 5}
                              >
                                <span>{item}</span>
                              </TableCell>
                            )
                          )
                        })}
                      </Row>
                    </div>
                  )
                })}
            </TableMobile>
          </MobileInner>
        </ProductsMobile>
        <Legal>
          Treatment timescales and details vary from patient to patient. This
          guide is for illustration purposes only.
        </Legal>
      </Container>
    </PaddingTop>
  )
}

const MobileHeading = styled.section`
  padding-top: 9px;
  @media (max-width: 767px) {
    font-size: 12px !important;
    line-height: 22px !important;
    font-weight: 300;
  }
`

const TopContent = styled.div`
  padding-bottom: 6rem;
  text-align: center;

  @media (max-width: 1023px) {
    padding-bottom: 66px;
    padding: 0 25px 66px;
  }
  @media (max-width: 767px) {
    padding-bottom: 37px;
  }
`
interface IProductsMobile {
  multipleColumns?: boolean
}
const ProductsMobile = styled.div<IProductsMobile>`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
  }
`
const MobileInner = styled.div<IProductsMobile>`
  ${props => props.multipleColumns && "overflow: auto;"}
`

interface IRow {
  multipleColumns?: boolean
  noGreyBackground?: boolean
}
const Row = styled.div<IRow>`
  display: flex;
  font-size: 1.6rem;
  line-height: 2.6rem;
  font-weight: 300;

  border-bottom: 1px solid ${color.keyline.grey};
  &:hover div {
    background-color: #f6fdff !important;
  }

  & div:nth-of-type(1) {
    align-items: flex-start;
    @media (max-width: 767px) {
      align-items: center;
    }
  }

  & div:nth-of-type(2),
  & div:nth-of-type(3),
  & div:nth-of-type(4) {
    background: ${color.background.tableGreen};
    border-right: 1px solid white;
  }

  & div:nth-of-type(4) {
    border-right: none;
  }

  & p {
  }
  & div {
    width: ${props => (props.multipleColumns ? "20%" : "40%")};
    padding: 42px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    transition: all 0.5s ease-in;
    @media (max-width: 767px) {
      padding: 15px 0;
      width: 50%;
    }
    &:nth-of-type(1) {
      width: 20%;
      border-left: none;
      @media (max-width: 767px) {
        padding-left: 0;
      }
    }

    @media (max-width: 767px) {
      &:nth-of-type(1) {
        background: ${color.background.tableGreen};
        border-right: 1px solid white;
      }
      &:nth-of-type(4) {
        background: white;
      }
      border-top: 1px solid ${color.brand.green};
    }
  }

  @media (max-width: 767px) {
    ${props => props.multipleColumns && "& div { min-width: 15rem;}"}
  }
`
interface ITableCell {
  multipleColumns?: boolean
}
const TableCell = styled.div<ITableCell>`
  @media (max-width: 767px) {
    width: auto;
    flex: 1;
    padding: 5rem 1rem;
    font-size: 12px;
    line-height: 16px;
    overflow: visible;
  }

  @media (max-width: 400px) {
    & span {
      font-size: 11px;
    }
  }
`
const Table = styled.div<ITableCell>`
  @media (max-width: 1199px) {
    margin: 0 25px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`
const TableMobile = styled(Table)`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    min-width: min-content;
    overflow-x: scroll;
    width: 100vw;
    margin: 0 25px;
    padding-right: 25px;
  }
`
const TopRow = styled.div<ITableHeading>`
  position: sticky;
  top: 6rem;
  width: 100%;
  &:before {
    content: "";
    height: 6rem;
    width: 100%;
    background: white;
    display: block;
    position: absolute;
    top: -6rem;
    left: 0;
    z-index: 1;
    @media (max-width: 1023px) {
      height: 66px;
      @media (max-width: 1023px) {
        top: -66px;
      }
    }

    @media (max-width: 767px) {
      height: auto !important;
      top: auto !important;
      position: relative;
    }
  }

  & > div > p {
    margin-bottom: 3px;
    color: #233342;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 15px;
    height: 30%;
  }

  @media (max-width: 1023px) {
    top: 66px;
    ${props => props.multipleColumns && "top: 0;"}
  }

  @media (max-width: 767px) {
    height: auto !important;
    top: auto !important;
  }
`

const TopRowSticky = styled.div`
  width: 100%;
  display: flex;
  background: white;
  font-size: 1.6rem;
  line-height: 2.6rem;
  font-weight: 300;
  &:first-of-type {
    font-size: 2.4rem;
    line-height: 3.4rem;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    @media (max-width: 767px) {
      width: auto;
      flex: 1;
      border-bottom: 1px solid ${color.keyline.grey};
    }

    &:nth-of-type(1) {
      position: relative;
      width: 20%;

      @media (max-width: 767px) {
        width: auto;
        flex: 1;
      }
    }
  }
`
interface ITableHeading {
  multipleColumns?: boolean
}
const TableHeading = styled.div<ITableHeading>`
  padding: 3rem 0;
  text-align: center;
  font-weight: 300;
  &:nth-of-type(2) {
    background: ${color.brand.green};
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;

    padding: 12px 0;

    &:nth-of-type(2) {
      background: white;
      flex: 2 !important;
    }
    &:nth-of-type(1) {
      background: ${color.brand.green};
      flex: 3 !important;
    }
  }
`

const Container = styled.div<ITableHeading>`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: ${props => (props.multipleColumns ? "0 0%" : "0 5%")};
  margin: 0 auto;
  width: 100%;
  background: white;
  @media (max-width: 1199px) {
    padding: 0;
  }
  & > p {
    padding-bottom: 60px;
    background: white;
    font-size: 18px;
    line-height: 34px;
    font-weight: 300;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      text-align: center;
      margin-bottom: 60px;
    }
  }
`

export default ProductTable

const InvisalignHeading = styled(TopRowSticky)`
  & > div:nth-of-type(1) {
    flex: 1;
  }
  & > div:nth-of-type(2) {
    flex: 3;
  }
  & > div:nth-of-type(3) {
    flex: 2;
  }
`

const TraditionalHeading = styled(TopRowSticky)`
  & div {
    flex: 1;
    width: 50%;
    height: 100%;
    display: flex;
    padding: 22px 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 300;
    @media (max-width: 767px) {
      ${props => props.multipleColumns && "min-width: 15rem; "}
    }
  }

  & div:nth-of-type(2),
  & div:nth-of-type(3),
  & div:nth-of-type(4) {
    background: ${color.background.tableGreen};
    border-right: 0.5px solid white;
    border-bottom: 1px solid ${color.keyline.grey};
  }

  & div:nth-of-type(4) {
    border-right: 1px solid white;
  }
  & div:nth-of-type(5),
  & div:nth-of-type(6) {
    border-bottom: 1px solid ${color.keyline.grey};
  }

  border-top: 1px solid ${color.keyline.grey};
`

const MobSubHeading = styled(TopRowSticky)`
  & div {
    padding: 7px 0;
    border-bottom: 1px solid ${color.keyline.grey};

    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3) {
      background: ${color.background.tableGreen};
      border-right: 1px solid white;
    }

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 22px;
    }
  }
`
const Legal = styled.div`
  margin-top: 5rem;
  text-align: center;
  padding: 0 25px;
  @media (max-width: 1023px) {
    text-align: left;
  }
`
