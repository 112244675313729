import React from "react"
import Referral from "./ReferralTabs"
import { get } from "../../util/functions"
import { graphql } from "gatsby"

const ReferralDrupal = ({ node }: any) => {
  const { relationships } = node || {}
  const { field_address_cards } = relationships || {}

  let practices = field_address_cards.relationships.field_post_address_card.map(
    practice => {
      return {
        heading: practice.field_section_heading,
        title: practice.field_practice_name,
        phone: practice.field_phone_number,
        comingSoon: practice.field_pill_text,
        body: practice.field_practice_details.value,
      }
    }
  )
  const { sections } = relationships || {}
  const sectionHeading = node.field_section_heading || {}
  return (
    <Referral
      sections={sections}
      sectionHeading={sectionHeading}
      practices={practices}
    />
  )
}

export default ReferralDrupal

export const fragment = graphql`
  fragment ReferralSection on paragraph__referral_section {
    id
    field_section_heading
    relationships {
      field_address_cards {
        relationships {
          field_post_address_card {
            field_phone_number
            field_practice_name
            field_section_heading
            field_pill_text
            field_practice_details {
              value
            }
          }
        }
      }
      sections: field_referral {
        field_icon {
          alt
        }
        relationships {
          field_icon {
            localFile {
              publicURL
            }
          }
          field_icon_hovered {
            localFile {
              publicURL
            }
          }
        }
        field_referral_tab_content {
          value
        }
        field_value
      }
    }
  }
`
