import React from "react"
import SpecificTreatmentForm from "./SpecificTreatmentForm"
import GeneralEnquiry from "./GeneralEnquiryForm"
import PatientReferral from "./PatientReferralForm"
import FreeConsultation from "./FreeConsultationForm"
import DentistReferral from "./DentistReferralForm"
import SendASmile from "./SendASmileForm"
import Invisalign from "./Invisalign/InvisalignForm"
import EmergencyTreatmentForm from "./EmergencyTreatmentForm"
import PracticesTreatmentForm from "./PracticesTreatmentForm"

import { graphql, useStaticQuery } from "gatsby"

const getPageForm = ({ form }) => {
  const data: any = useStaticQuery(graphql`
    query practicesDropdown {
      nodeWhitesPractices {
        field_practice
      }
    }
  `)

  interface Option {
    label: string
    value: string
  }

  const preferedClinics: Option[] = data.nodeWhitesPractices.field_practice.map(
    practice => {
      return { label: practice, value: practice }
    }
  )

  if (!form) return <></>

  switch (form) {
    case "General Enquiry":
      return <GeneralEnquiry preferedClinics={preferedClinics} />
    case "Practices":
      return <PracticesTreatmentForm preferedClinics={preferedClinics} />
    case "Patient Referral Form":
      return <PatientReferral preferedClinics={preferedClinics} />
    case "Book A Free Consultation":
      return <FreeConsultation preferedClinics={preferedClinics} />
    case "Dentist Referral Form":
      return <DentistReferral preferedClinics={preferedClinics} />
    case "Send A Smile":
      return <SendASmile preferedClinics={preferedClinics} />
    case "Invisalign Assessment":
      return <Invisalign preferedClinics={preferedClinics} />
    case "Children's Treatments":
      return (
        <SpecificTreatmentForm
          treatmentName={"Children's Treatments"}
          preferedClinics={preferedClinics}
        />
      )
    case "Dental Hygiene Form":
      return (
        <SpecificTreatmentForm
          treatmentName={"dental hygiene treatments"}
          pageTitle={
            "Please fill in the form to find out more about dental hygiene treatments at Wytes."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Dental Examination Form":
      return (
        <SpecificTreatmentForm
          treatmentName={"dental examinations"}
          pageTitle={
            "Please fill in the form to find out more about dental examinations at Wytes."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Invisalign":
      return (
        <SpecificTreatmentForm
          treatmentName={"Invisalign®"}
          pageTitle={
            "Please fill in the form to find out more about Invisalign® treatment at Wytes."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Dental Implants":
      return (
        <SpecificTreatmentForm
          treatmentName={"dental implants"}
          pageTitle={
            "Please fill in the form to find out more about dental implants at Wytes."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Root Canal Treatments":
      return (
        <SpecificTreatmentForm
          treatmentName={"root canal treatment"}
          pageTitle={
            "Please fill in the form to find out more about root canal treatment at Wytes."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Emergency Treatment":
      return (
        <EmergencyTreatmentForm
          treatmentName={"Emergency treatments"}
          pageTitle={
            "Please fill in the form to find out more about emergency treatments at Wytes."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Crowns, Bridges, Inlays and Onlays":
      return (
        <SpecificTreatmentForm
          treatmentName={"dental restorations"}
          pageTitle={
            "Please fill in the form to find out more about dental restorations at Wytes."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Amalgam Free Filing":
      return (
        <SpecificTreatmentForm
          treatmentName={"amalgam free fillings"}
          pageTitle={
            "Please fill in the form to find out more about amalgam free fillings at Wytes."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Teeth Whitening":
      return (
        <SpecificTreatmentForm
          treatmentName={"teeth whitening"}
          pageTitle={
            "Please fill in the form to find out more about teeth whitening at Wytes."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Composite Bonding":
      return (
        <SpecificTreatmentForm
          treatmentName={"composite bonding"}
          pageTitle={
            "Please fill in the form to find out more about composite bonding at Wytes."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Porcelain Veneers":
      return (
        <SpecificTreatmentForm
          treatmentName={"porcelain veneers"}
          pageTitle={
            "Please fill in the form to find out more about porcelain veneers at Wytes."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Re-contouring Gumline":
      return (
        <SpecificTreatmentForm
          treatmentName={"gumline re-contouring"}
          pageTitle={
            "Please fill in the form to find out more about gumline re-contouring at Wytes."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Lingual Brace":
      return (
        <SpecificTreatmentForm
          treatmentName={"fixed and lingual braces"}
          pageTitle={
            "Please fill in the form to find out more about fixed and lingual braces at Wytes."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Fixed Brace":
      return (
        <SpecificTreatmentForm
          treatmentName={"Fixed Braces"}
          preferedClinics={preferedClinics}
        />
      )
    case "Botox":
      return (
        <SpecificTreatmentForm
          treatmentName={"wrinkle-relaxing treatments"}
          pageTitle={
            "Please fill in the form to find out more about wrinkle-relaxing treatments at Wytes."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Fillers":
      return (
        <SpecificTreatmentForm
          treatmentName={"dermal filler treatment"}
          pageTitle={
            "Please fill in the form to find out more about dermal filler treatment at Wytes."
          }
          preferedClinics={preferedClinics}
        />
      )
    default:
      return <></>
  }
}

export default getPageForm
