import React from "react"
import styled from "styled-components"
import color from "../../../style/color"

interface ILastStep {
  stepNumber?: number
}
const StepNumber = ({ stepNumber }: ILastStep) => {
  return <> {stepNumber && <Container>STEP {stepNumber}</Container>} </>
}

export default StepNumber

const Container = styled.p``
