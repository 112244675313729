import React, { useEffect } from "react"
import styled from "styled-components"
import color from "../../style/color"
import { isInViewport, throttled } from "../../util/functions"

interface ToothProp {
  heading: string
  text?: string
  rightAlign?: boolean
  id: string
  gumLine?: boolean
  lineIndex: number
  delay: number
}
const ToothDetail = ({
  heading,
  text,
  rightAlign,
  id,
  gumLine,
  lineIndex,
  delay,
}: ToothProp) => {
  let delayValue: boolean = false
  useEffect(() => {
    let last = 0
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const lineStartAnimation = () => {
        let currentScroll =
          document.documentElement.scrollTop || document.body.scrollTop

        if (currentScroll > 0 && last <= currentScroll) {
          last = currentScroll
          delayValue = false
        } else {
          last = currentScroll
          delayValue = true
        }
      }

      const eventListener = throttled(100, lineStartAnimation)

      window.addEventListener("scroll", eventListener)

      return () => {
        window.addEventListener("scroll", eventListener)
      }
    }
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const line: HTMLElement | null = document.getElementById(id)

      const lineGrow = (elem: HTMLElement | null, delay: number) => {
        if (elem) {
          if (isInViewport(elem)) {
            if (!delayValue) {
              elem.style.animation = `animateLine 0.3s ease forwards ${delay}s`
            } else {
              // reverse the delay when scrolling from bottom to the top of the page
              elem.style.animation = `animateLine 0.3s ease forwards ${2 -
                lineIndex}s`
            }
          } else {
            elem.style.animationName = ""
          }
        }
      }

      const eventListener = throttled(100, () => {
        lineGrow(line, delay)
      })

      window.addEventListener("scroll", eventListener)

      return () => {
        window.removeEventListener("scroll", eventListener)
      }
    }
  }, [])

  const descLimit = 81
  if (text !== undefined && text.length > descLimit) {
    text = text.substr(0, descLimit)
  }
  return (
    <DetailsContainer gumLine={gumLine} rightAlign={rightAlign}>
      {!rightAlign ? (
        <Top gumLine={gumLine}>
          {heading && <DetailTitle>{heading}</DetailTitle>}

          <LineContainer>
            <Line id={id} />
            <Circle />
          </LineContainer>
        </Top>
      ) : (
        <Top>
          <LineContainer rightAlign={rightAlign}>
            <Circle />
            <Line id={id} rightAlign />
          </LineContainer>
          {heading && <DetailTitle>{heading}</DetailTitle>}
        </Top>
      )}
      {text && <Text rightAlign={rightAlign}>{text}</Text>}
    </DetailsContainer>
  )
}

export default ToothDetail

interface DetailsProps {
  rightAlign?: boolean
  gumLine?: boolean
}
const DetailsContainer = styled.div<DetailsProps>`
  width: 100%;
  margin-top: ${props => (props.gumLine ? "2rem" : "5rem")};

  display: flex;
  flex-direction: column;
  align-items: ${props => props.rightAlign && "flex-end"};

  @media (max-width: 1023px) {
    margin-top: ${props => (props.gumLine ? "2rem" : "3rem")};
  }
  @media (max-width: 850px) {
    margin-top: ${props => (props.gumLine ? "0" : "3rem")};
  }
  @media (max-width: 600px) {
    margin-top: 4rem;
  }
  @media (max-width: 343px) {
    margin-top: 3rem;
  }
  @media (max-height: 380px) {
    margin-top: 10px;
  }
`
interface GumLineProps {
  gumLine?: boolean
}
const Top = styled.div<GumLineProps>`
  display: flex;
  width: ${props => (props.gumLine ? "80%" : "100%")};
  align-items: center;
`

const DetailTitle = styled.h2`
  font-size: 2rem;
  line-height: 2.8rem;
  white-space: nowrap;
  font-family: Roboto;
  @media (max-width: 1199px) {
    font-size: 1.8rem;
  }
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 24px;
  }
`
interface DetailTextProp {
  rightAlign?: boolean
}

const Text = styled.p<DetailTextProp>`
  width: 60%;
  margin: 1rem 0 1.5rem;
  margin-left: ${props => props.rightAlign && "auto"};
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: ${color.text.secondary};
  overflow: hidden;

  @media (max-width: 1231px) {
    width: 75%;
  }

  @media (max-width: 850px) {
    width: 70%;
  }

  @media (max-width: 767px) {
    margin-bottom: 0px;
    font-size: 13px;
    line-height: 23px;
    width: 56%;
  }
  @media (max-width: 600px) {
    width: 58%;
  }

  @media (max-height: 380px) {
    margin: 8px 0 10px;
  }
`

const LineContainer = styled.div<DetailTextProp>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.rightAlign && "flex-end"};
  width: 100%;
  margin-right: 10px;
  margin-left: 10px;
  z-index: 8;
`
interface LineProp {
  rightAlign?: boolean
}
const Line = styled.div<LineProp>`
  width: 0%;
  height: 1px;
  background: #000;
  transition: all ease-out 0.2s;
`

const Circle = styled.div`
  height: 9px;
  width: 9px;
  border: 1px solid ${color.brand.blue};
  border-radius: 100%;
  background: white;
`
