export const yellowBackground =
  "radial-gradient(circle farthest-side at top left,#c6e4df 20%, rgba(198, 228, 223, 0.5) 30%, rgba(250, 241, 186, 0.5) 50%),radial-gradient(circle farthest-side at bottom left, #faf1ba, rgba(249, 239, 184, 0) 30.71%),radial-gradient(circle farthest-side at bottom right, #cbd4cc 30%, rgba(203, 212, 204, 0.7) 35%,  rgba(203, 212, 204, 0) 45%),radial-gradient(circle farthest-side at top right,  #f6f6f6 30%,  rgba(246,246,246,0.7) 35%),#faf1ba;"

export const lilacBackground =
  "radial-gradient(circle farthest-side at top left, #cdd4c7 20%,rgba(205, 212, 199, 0.5) 30%, rgba(216,206,231, 0.5) 50%), radial-gradient(circle farthest-side at bottom left, #D8CEE7, rgba(216,206,231, 0) 30.71%),radial-gradient(circle farthest-side at bottom right, #c6e4df 30%,rgba(198,228,223, 0.7) 35%, rgba(198,228,223, 0) 45%),radial-gradient(circle farthest-side at top right, #f6f6f6 30%,rgba(246,246,246,0.7) 35%, rgba(246,246,246,0) 50%),#d8cee7;"

export const blueBackground =
  "radial-gradient(circle farthest-side at top left, #cdd4c7 20%,rgba(205, 212, 199, 0.5) 30%, rgba(184,225,243, 0.5) 50%), radial-gradient(circle farthest-side at bottom left, #B8E1F3,  rgba(184,225,243, 0) 30.71%),radial-gradient(circle farthest-side at bottom right, #c6e4df 30%,rgba(198,228,223, 0.7) 35%, rgba(198,228,223, 0) 45%),radial-gradient(circle farthest-side at top right, #f6f6f6 30%,rgba(246,246,246,0.7) 35%, rgba(246,246,246,0) 50%),#B8E1F3;"

export const pinkBackground =
  "radial-gradient(circle farthest-side at top left, #c6e4df 20%,rgba(198, 228, 223, 0.5) 30%, rgba(234,201,217, 0.5) 50%), radial-gradient(circle farthest-side at bottom left, #EAC9D9,  rgba(234,201,217, 0) 30.71%),radial-gradient(circle farthest-side at bottom right, #cbd4cc 30%,rgba(203, 212, 204, 0.7) 35%, rgba(249, 239, 184, 0) 45%),radial-gradient(circle farthest-side at top right, #f6f6f6 30%,rgba(246,246,246,0.7) 35%, rgba(246,246,246,0) 50%),#EAC9D9;"
