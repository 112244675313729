import React, { useState, useEffect } from "react"
import Input from "./Input"
import TextArea from "./TextArea"
import Dropdown from "./CustomDropdown"
import Button from "../../shared/Button/Button"
import styled from "styled-components"
import color from "../../style/color"
import {
  validateOnBlur,
  validatePhoneNumber,
  validateEmail,
  scrollPage,
  getPracticeEmail,
  trackButtonClick,
} from "../../util/functions"
import { setItem, getItem } from "../../util/functions"
import { updateGoogleSheet, getAuthToken } from "../../googleapis/api-hooks"
import emailjs from "emailjs-com"
import Loading from "./Loading"

interface ITreatmentForm {
  treatmentName?: string
  preferedClinics: any
  pageTitle?: string
}
const TreatmentForm = ({
  treatmentName,
  preferedClinics,
  pageTitle,
}: ITreatmentForm) => {
  const [firstName, setFirstName] = useState(getItem("firstName"))
  const [lastName, setLastName] = useState(getItem("lastName"))
  const [firstNameError, togglefirstNameError] = useState(false)
  const [lastNameError, togglelastNameError] = useState(false)
  const [phone, setPhone] = useState(getItem("phone"))
  const [phoneError, togglePhoneError] = useState(false)
  const [email, setEmail] = useState(getItem("email"))
  const [emailError, toggleEmailError] = useState(false)
  const [anythingElse, setAnythingElse] = useState("")
  const [treatmentSelection, setTreatmentSelection] = useState("checkup")
  const [practiceError, togglePracticeError] = useState(false)
  const [token, setToken] = useState("")

  const [isLoading, toggleLoading] = useState(false)
  useEffect(() => {
    if (token) {
      if (typeof window !== "undefined" && typeof document !== "undefined") {
        toggleLoading(true)
        document.querySelector("body").style.overflow = "hidden"

        let practice = document.querySelector("#specific-form-dropdown1")
          .innerText
        console.log(practice)
        setItem("firstName", firstName)
        setItem("lastName", lastName)
        setItem("phone", phone)
        setItem("email", email)
        setItem("practice", practice)
        setItem("treatmentName", treatmentName)

        const params = {
          emailTo: getPracticeEmail(practice),
          message: `
          <p>Form: Treatment Enquiry</p>
          <p>Name: ${firstName} ${lastName}</p>
          <p>Preferred practice: ${practice}</p>
          <p>Phone: ${phone}</p>
          <p>Treatment: ${treatmentName}</p>
          <p>Email: ${email}</p>
          <p>Message: ${anythingElse}</p>
          `,
        }

        emailjs
          .send(
            "gmail",
            process.env.EMAILJS_TEMPLATE,
            params,
            process.env.EMAILJS_ID
          )
          .then(
            result => {
              console.log(result.text)
            },
            error => {
              console.log(error.text)
            }
          )
          .then(() => {
            updateGoogleSheet(
              token,
              "TreatmentForm",
              [
                firstName + " " + lastName,
                phone,
                email,
                practice,
                treatmentName,
                anythingElse,
                window.location.href,
                new Date(),
              ],
              "treatment-form-confirmation"
            )
          })
      }
    }
  }, [token])

  useEffect(() => {
    const practice = getItem("practice")
    if (practice) {
      document.querySelector("#specific-form-dropdown1").innerText = practice
    }
  }, [])

  const submitForm = () => {
    const goToNextScreen = () => {
      trackButtonClick(`${treatmentName} Form Submission`)
      getAuthToken(setToken)
    }
    let practice = document.querySelector("#specific-form-dropdown1").innerText
    let idToScroll: string = ""

    let firstNameError = false
    let lastNameError = false
    let phoneError = false
    let practiceError = false
    let emailError: boolean = false
    if (firstName.length < 1) {
      firstNameError = true
      togglefirstNameError(true)
      if (!idToScroll) {
        idToScroll = "firstName"
      }
    }

    if (lastName.length < 1) {
      lastNameError = true
      togglelastNameError(true)
      if (!idToScroll) {
        idToScroll = "lastName"
      }
    }
    if (!validatePhoneNumber(phone)) {
      phoneError = true
      togglePhoneError(true)
      if (!idToScroll) {
        idToScroll = "phone"
      }
    }
    if (!validateEmail(email)) {
      emailError = true
      toggleEmailError(true)
      if (!idToScroll) {
        idToScroll = "email"
      }
    }
    if (practice === "Please select") {
      practiceError = true
      togglePracticeError(true)
      if (!idToScroll) {
        idToScroll = "specific-form-dropdown1"
      }
    }
    if (
      !firstNameError &&
      !lastNameError &&
      !phoneError &&
      !emailError &&
      !practiceError
    ) {
      goToNextScreen()
    } else {
      if (idToScroll) {
        scrollPage(idToScroll)
      }
    }
  }

  return isLoading ? (
    <Loading />
  ) : (
    <Wrapper>
      <Container>
        <Text>
          <Heading>Want to find out more?</Heading>
          <Subheading>{pageTitle}</Subheading>
        </Text>
        <Form>
          <Flex>
            <Input
              value={firstName}
              onChange={setFirstName}
              label={"Your first name*"}
              isValidated={firstName.length > 0}
              hasError={firstNameError}
              errorTitle="Please enter your first name"
              onBlur={() =>
                validateOnBlur(firstName.length > 0, togglefirstNameError)
              }
              id="firstName"
            />
            <Gap />
            <Input
              value={lastName}
              onChange={setLastName}
              label={"Your last name*"}
              isValidated={lastName.length > 0}
              hasError={lastNameError}
              errorTitle="Please enter your last name"
              onBlur={() =>
                validateOnBlur(lastName.length > 0, togglelastNameError)
              }
              id="lastName"
            />
          </Flex>
          <Flex>
            <Input
              value={phone}
              onChange={phone => phone.length < 20 && setPhone(phone)}
              label={"Contact number*"}
              isValidated={validatePhoneNumber(phone)}
              hasError={phoneError}
              errorTitle="Please enter a valid telephone number"
              onBlur={() =>
                validateOnBlur(validatePhoneNumber(phone), togglePhoneError)
              }
              id="phone"
            />
            <Gap />
            <Input
              value={email}
              onChange={setEmail}
              label={"Email address*"}
              isValidated={validateEmail(email)}
              hasError={emailError}
              errorTitle="Please enter a valid email address"
              onBlur={() => {
                validateOnBlur(validateEmail(email), toggleEmailError)
              }}
              id="email"
            />
          </Flex>

          <Dropdown
            id="specific-form-dropdown1"
            heading="Preferred clinic*"
            placeholder="Please select"
            options={preferedClinics}
            hasError={practiceError}
            errorTitle="Please select preferred clinic"
            toggleDropdownError={togglePracticeError}
          />
          <TextArea
            heading={"Your message"}
            value={anythingElse}
            onChange={setAnythingElse}
          />
          <BtnContainer>
            <Button text="Submit" onClick={submitForm} tertiary />
          </BtnContainer>
        </Form>
      </Container>
    </Wrapper>
  )
}

export default TreatmentForm

const Wrapper = styled.div`
  padding: 12.5rem 0;
  background: ${color.background.blueLight};
  @media (max-width: 767px) {
    padding: 50px 0 77px;
  }
`
const Container = styled.div`
  display: flex;
  width: 92%;
  max-width: 120rem;
  margin: 0 auto;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 25px;
  }
`
const Text = styled.div`
  width: 38rem;
  margin-right: 17rem;
  @media (max-width: 1199px) {
    margin-right: 10rem;
  }
  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 10rem;
    margin-right: 0;
  }
  @media (max-width: 767px) {
    margin-bottom: 38px;
  }
`
const Heading = styled.h2``
const Subheading = styled.p`
  margin-top: 28px;
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 300;
  @media (max-width: 767px) {
    margin-top: 0;
  }
`
const Form = styled.div`
  width: 66rem;
  @media (max-width: 1023px) {
    width: 100%;
  }
`
const Flex = styled.div`
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
const Gap = styled.div`
  width: 2rem;
  @media (max-width: 767px) {
    display: none;
  }
`
const BtnContainer = styled.div`
  width: 50%;
  margin-top: 4rem;
  @media (max-width: 767px) {
    width: 100%;
  }
`
