import React from "react"
import ToothAnimation from "./ToothAnimation"
import ToothAnimationMobile from "./ToothAnimationMobile"

interface Props {
  sectionHeading: string
  subHeading: string
  heading: string
  text: string
  ctaUrl1: string
  ctaUrl2: string
  ctaText1: string
  ctaText2: string
  topTextAnimated: string
  steps: any
  finalStepImg: string
  mobFinalStepImg: string
}

const ToothAnimationContainer = ({
  sectionHeading,
  subHeading,
  heading,
  text,
  ctaUrl1,
  ctaText1,
  topTextAnimated,
  steps,
  finalStepImg,
  mobFinalStepImg,
}: Props) => {
  return (
    <>
      <ToothAnimation
        sectionHeading={sectionHeading}
        subHeading={subHeading}
        imageDesktop={finalStepImg}
        imageMobile={mobFinalStepImg}
        stepNumber={5}
        heading={heading}
        text={text}
        ctaUrl1={ctaUrl1}
        ctaText1={ctaText1}
        steps={steps}
      />
      <ToothAnimationMobile
        sectionHeading={sectionHeading}
        subHeading={subHeading}
        topTextAnimated={topTextAnimated}
        imageDesktop={finalStepImg}
        imageMobile={mobFinalStepImg}
        stepNumber={6}
        heading={heading}
        text={text}
        ctaUrl1={ctaUrl1}
        ctaText1={ctaText1}
        steps={steps}
      />
    </>
  )
}

export default ToothAnimationContainer
