import React from "react"
import StepsToPerfectSmile from "./StepsToPerfectSmile"
import { graphql } from "gatsby"
import { get } from "../../util/functions"

const StepsToPerfectSmileDrupal = ({ node }: any) => {
  const { heading, subheading, relationships } = node
  const { field_step } = relationships

  const data = field_step.map((step: any) => {
    const image: string = get(
      ["relationships", "field_image_desktop", "localFile", "publicURL"],
      step,
      ""
    )
    const alt: string = get(["field_image_desktop", "alt"], step, "")

    return {
      title: step.field_section_heading,
      text: step.field_text?.value || step.field_section_body,
      image,
      alt,
    }
  })
  return (
    <StepsToPerfectSmile
      heading={heading}
      subheading={subheading}
      data={data}
    />
  )
}

export default StepsToPerfectSmileDrupal

export const fragment = graphql`
  fragment StepsToPerfectSmile on paragraph__steps_to_perfect_smile {
    id
    heading: field_section_heading
    subheading: field_subheading
    relationships {
      field_step {
        field_section_heading
        field_text {
          value
        }
        field_image_desktop {
          alt
        }
        relationships {
          field_image_desktop {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
