import React from "react"
import styled from "styled-components"
import color from "../../style/color"
import StandardSection from "../../helper/StandardSection"
import Button from "../../shared/Button/Button"

interface TwoColumnsTextSmallProps {
  data: Array<string>
  sectionHeading: string
  columnsCta?: any
  logo?: string
  logoAlt?: string
}

const TwoColumnsSmall = ({
  data,
  sectionHeading,
  columnsCta,
  logo,
  logoAlt,
}: TwoColumnsTextSmallProps) => {
  return (
    <StandardSection>
      <Grid>
        <Column>
          {sectionHeading && (
            <div data-testid="section-heading-test">
              <SectionHeading>{sectionHeading}</SectionHeading>
            </div>
          )}
          {logo && <Logo src={logo} alt={logoAlt} />}
        </Column>

        <Column>
          <LineRow>
            <Heading>Suitable for</Heading>
            {/* <Line /> */}
          </LineRow>

          <UL>
            {data &&
              data.map((item, key) => {
                return (
                  <Li key={key} data-testid="twoColsSmall-item">
                    {item}
                  </Li>
                )
              })}
          </UL>
          {columnsCta && (
            <BtnContainer>
              <Button
                size="small"
                text={columnsCta.title}
                href={columnsCta.uri}
              />
            </BtnContainer>
          )}
        </Column>
      </Grid>
    </StandardSection>
  )
}

export default TwoColumnsSmall

const BtnContainer = styled.div`
  margin-top: 20px;
  width: 164px;
`

const UL = styled.ul``

const SectionHeading = styled.h3`
  font-size: 3.8rem;
  line-height: 4.8rem;
`

const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10rem 0;
  width: 100%;

  @media (max-width: 1023px) {
    padding: 5rem 0;
  }
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`

const Column = styled.div`
  width: 46%;
  @media (max-width: 1200px) {
    width: 48%;
  }
  @media (max-width: 1023px) {
    width: 100%;
    &:nth-of-type(2) {
      margin-top: 40px;
    }
  }
`

const LineRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`

const Line = styled.div`
  height: 1px;
  background: ${color.brand.green};
  flex: 1;
  margin-left: 4.4rem;
`
const Heading = styled.p`
  text-transform: uppercase;
  font-size: 2rem;
  letter-spacing: 1px;
  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 4px;
  }
`

const Li = styled.li`
  font-size: 22px;
  line-height: 32px;
  font-weight: 300;
  margin-bottom: 8px;
  color: ${color.text.secondary};
  a {
    color: ${color.brand.orange};
    text-decoration: none;
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
  }
`
const Logo = styled.img`
  height: auto;
  max-height: 120px;
  margin-top: 3rem;
  max-width: 188px;
  @media (max-width: 1023px) {
    margin-top: 14px;
  }
`
