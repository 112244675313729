import React from "react"
import CategoryTreatmentList from "./CategoryTreatmentList"
import { graphql } from "gatsby"
import { formatLink } from "../../util/functions"

const TreatmentListDrupal = ({ node }) => {
  const { heading, relationships, subheading } = node || {}
  const { field_c_treatment_card } = relationships || {}
  const cards = field_c_treatment_card.map(card => {
    return {
      heading: card.field_section_heading,
      body: card.field_section_body,
      ctaText: card.field_cta.title,
      ctaUrl: formatLink(card.field_cta.uri),
      category: card.field_category,
      image: card.relationships.field_image_desktop.localFile.publicURL,
      imageAlt: card.field_image_desktop.alt,
      videoConsultation: card.field_video_consultation,
    }
  })
  return (
    <CategoryTreatmentList
      heading={heading}
      cards={cards}
      subheading={subheading}
    />
  )
}

export const fragment = graphql`
  fragment TreatmentListDrupal on paragraph__category_treatment_list {
    id
    heading: field_section_heading
    subheading: field_subheading
    relationships {
      field_c_treatment_card {
        field_category
        field_section_body
        field_section_heading
        field_video_consultation
        field_image_desktop {
          alt
        }
        field_cta {
          title
          uri
        }
        relationships {
          field_image_desktop {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default TreatmentListDrupal
