import React from "react"
import styled from "styled-components"
import color from "../../../style/color"

interface SecondSectionProps {
  children: any
}

const SecondSection = ({ children }: SecondSectionProps) => {
  return <Container>{children}</Container>
}

export default SecondSection

const Container = styled.div`
  padding-top: 7.7rem;
  background: ${color.background.lightGrey};
`
