import React from "react"
import TreatmentPriceTable from "./TreatmentPriceTable"
import { graphql, useStaticQuery } from "gatsby"

interface Price {
  title: string
  value: string
}

const TreatmentPriceTableDrupal = ({ node }: any) => {
  const { heading, field_bottom_link, relationships } = node || {}
  const { href, smallText } = field_bottom_link || {}

  return (
    <TreatmentPriceTable
      smallText={smallText}
      href={href}
      heading={heading}
      array={relationships.field_treatment_pricing_row}
    />
  )
}

export default TreatmentPriceTableDrupal

export const fragment = graphql`
  fragment TreatmentPriceTable on paragraph__treatment_price_table {
    id
    heading: field_section_heading
    field_bottom_link {
      smallText: title
      href: uri
    }
    relationships {
      field_treatment_pricing_row {
        value: field_price
        title: field_treatment_name
      }
    }
  }
`
