import React from "react"
import Carousel from "./ExperienceCarousel2"
import { graphql } from "gatsby"

interface Slide {
  heading: string
  body: string
  imageDesktop: string
  imageDesktopAlt: string
  imageMobile: string
  imageMobileAlt: string
}

const ExperienceCarouselDrupal = ({ node }) => {
  const { heading, relationships } = node
  const { field_slide } = relationships

  const slides = field_slide.map(slide => {
    return {
      heading: slide.field_section_heading,
      body: slide.field_section_body,
      imageDesktop: slide.relationships.field_image_desktop.localFile.publicURL,
      imageDesktopAlt: slide.field_image_desktop.alt,
      imageMobile: slide.relationships.field_image_mobile.localFile.publicURL,
      imageMobileAlt: slide.field_image_mobile.alt,
      sources: [
        {
          ...slide.relationships.field_image_desktop.localFile.childImageSharp
            .fluid,
          media: `(min-width: 767px)`,
        },
        {
          ...slide.relationships.field_image_mobile.localFile.childImageSharp
            .fluid,
          media: `(max-width: 767px)`,
        },
      ],
    }
  })

  return <Carousel slides={slides} heading={heading} />
}

export const fragment = graphql`
  fragment ExperienceCarousel on paragraph__experience_carousel {
    id
    heading: field_section_heading
    relationships {
      field_slide {
        field_section_heading
        field_section_body: field_slide_text
        field_image_desktop {
          alt
        }
        field_image_mobile {
          alt
        }
        relationships {
          field_image_desktop {
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1920, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          field_image_mobile {
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 767, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ExperienceCarouselDrupal
