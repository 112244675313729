import React from "react"
import styled from "styled-components"
import color from "../../style/color"
import Cta from "../../shared/Button/Button"
import StandardSection from "../../helper/StandardSection"
import VerticalPadding from "../../helper/VerticalPadding"
import getOpeningTimes from "./OpeningTimesFunction"
import moment from "moment"
import Pill from "../../shared/Pill/Pill"
import OpeningTimesMobile from "./OpeningTimesMobile"
import { trackButtonClick } from "../../util/functions"

interface Practice {
  heading: string
  title: string
  phone?: string
  comingSoon: boolean
  body: string
  ctaUrl: string
  ctaText: string
}
interface OurPracticesProps {
  practices: Array<Practice>
  heading?: string
}

export const renderOpeningTimes = () => {
  const { isOpen, text } = getOpeningTimes(moment())

  return (
    <OpeningTimes>
      <Pill>{isOpen ? "OPEN NOW" : "CLOSED"}</Pill> <p>{text}</p>
    </OpeningTimes>
  )
}
const OurPractices = ({ heading, practices }: OurPracticesProps) => {
  return (
    <MainContainer>
      <StandardSection>
        <VerticalPadding>
          <Container>
            {heading && (
              <Heading data-testid="location-heading">{heading} </Heading>
            )}

            <ColContainer>
              {practices.map((practice, key) => {
                if (key < 3) {
                  return (
                    <Col key={key} marginRight={(key + 1) % 3 !== 0}>
                      <NameContainer>
                        <Title data-testid="practice-heading">
                          {practice.heading}
                        </Title>
                        <Name data-testid="practice-title">
                          {practice.title}
                        </Name>
                      </NameContainer>
                      {practice.phone && (
                        <Phone
                          href={`tel:${practice.phone}`}
                          data-testid="practice-phone"
                          onClick={() =>
                            trackButtonClick(
                              "Phone Number Click: Our Practices"
                            )
                          }
                        >
                          {practice.phone}
                        </Phone>
                      )}
                      {practice.comingSoon ? (
                        <OpeningTimes data-testid="pill-text-coming-soon">
                          <Pill>COMING SOON</Pill>
                        </OpeningTimes>
                      ) : (
                        renderOpeningTimes()
                      )}
                      <Value
                        data-testid="practice-body"
                        dangerouslySetInnerHTML={{
                          __html: `${practice.body}`,
                        }}
                      />
                      <BtnContainer>
                        <Cta
                          size="small"
                          href={practice.ctaUrl}
                          text={practice.ctaText}
                        />
                      </BtnContainer>
                    </Col>
                  )
                }
              })}
            </ColContainer>
            <OpeningTimesMobile slides={practices} heading={heading} />
            {practices.length > 3 && (
              <SeePracticesBtnContainer>
                <Cta
                  text="See all practices"
                  href={
                    (process.env.BASE === "/" ? "" : process.env.BASE) +
                    "/practices"
                  }
                />
              </SeePracticesBtnContainer>
            )}
          </Container>
        </VerticalPadding>
      </StandardSection>
    </MainContainer>
  )
}

const MainContainer = styled.section`
  width: 100%;
  position: relative;
  overflow: hidden;
  background: ${color.background.blueLight};
  padding: 1.5rem 0 5rem;
  @media (max-width: 1023px) {
    padding: 0;
  }
`
export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1023px) {
    flex-direction: row;
  }
`

const Heading = styled.h3`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
`

export const Phone = styled.a`
  &,
  &:visited,
  &:link {
    font-weight: 400;
    font-size: 3rem;
    line-height: 4rem;
    cursor: pointer;
    margin-bottom: 2.5rem;
    display: inline-block;
    text-decoration: none;

    @media (max-width: 1023px) {
      margin: 13px 0 10px;
    }
  }
`

export const OpeningTimes = styled.div`
  display: flex;
  align-items: flex-end;

  & p {
    margin-left: 7px;
    font-weight: 300;
    font-size: 14px;
  }
`

export default OurPractices

const Container = styled.div`
  width: 100%;
`

const ColContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  max-width: 1100px;
  @media (max-width: 1023px) {
    display: none;
  }
`
interface ICol {
  marginRight?: boolean
}
const Col = styled.div<ICol>`
  width: 30%;
  margin: 3.3rem 5% 0 0;
  margin-right: ${props => (props.marginRight ? "5%" : "0px")};
  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-of-type):after {
      content: "";
      width: 150px;
      height: 1px;
      margin: 0;
      background-color: ${color.brand.green};
      margin-top: 51px;
    }

    &:not(:first-of-type) {
      margin-top: 40px;
    }
  }
`

export const Title = styled.h5``

export const Name = styled(Title)`
  margin-bottom: 15px;
  @media (max-width: 1023px) {
    margin-left: 5px;
    margin-bottom: 0;
  }
`

export const Value = styled.div`
  margin-top: 14px;
  margin-bottom: 20px;
  @media (max-width: 1023px) {
    margin: 23px 0 13px;
  }
  *,
  & * {
    font-weight: 300;
  }
  & a {
    margin-top: 13px;
    display: block;
    @media (max-width: 767px) {
      margin-top: 6px;
    }
  }
`
export const BtnContainer = styled.div`
  width: 100%;
  max-width: 147px;
`
const SeePracticesBtnContainer = styled.div`
  margin-top: 7rem;
  width: 100%;
  display: flex;
  justify-content: center;
`
